import React, { ReactElement, useState, forwardRef, useImperativeHandle } from 'react';
import Scrollbar from 'components/Scrollbar';

// material-ui
import { Table, TableContainer, TablePagination, LinearProgress, useMediaQuery, useTheme, Box, Card } from '@mui/material';

// components
import { DataTableHeaderV3 } from './DataTableHeaderV3';
import { DataTableBodyV3 } from './DataTableBodyV3';
import { DataTableFiltersV3 } from './DataTableFiltersV3';
import { DataListV3 } from './DataListV3';
import { ActionBarV3 } from './ActionBarV3';

// types
import { DataTableProps, DataTableRefProps, Filters, UpdateParams, MyTableModel } from './Types';
import { useIsMounted } from 'hooks/useIsMounted';

const DataTableV3Component = (props: DataTableProps, ref: React.Ref<DataTableRefProps>): ReactElement => {
    const {
        updateParams,
        headers,
        showActionBar,
        showFilters,
        showRefresh,
        showSearch,
        hiddenPagination,
        isLoading,
        onUpdate,
        rowStyles,
        onRowClick,
        onActionAddClick,
        onDownloadClick,
        collapsableComponent,
        mobileComponent,
        vScroll
    } = props;

    const { rows, count, rowsPerPage, page, order, orderBy, searchText, filters, data } = updateParams;
    const isMounted = useIsMounted();

    const [filterOpen, setFilterOpen] = useState(false);
    const [loading, setLoadingState] = useState<boolean>(false);

    const setLoading = (value: boolean) => {
        if (isMounted()) setLoadingState(value);
    };

    const [currentFilters, setCurrentFilters] = useState<Filters | undefined>(filters);
    const [currentSearchtext, setCurrentSearchtext] = useState<string | undefined>(searchText);

    const actionBarEnable = typeof showActionBar === 'undefined' || showActionBar;

    const handleChangePage = (event: unknown, newPage: number) => {
        if (!onUpdate) return;
        onUpdate({ rows, count, rowsPerPage, page: newPage + 1, order, orderBy, searchText, filters: currentFilters, data }, { setLoading });
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!onUpdate) return;
        onUpdate(
            {
                rows,
                count,
                rowsPerPage: Number(event.target.value),
                page: 1,
                order,
                orderBy,
                searchText: currentSearchtext,
                filters: filterOpen ? currentFilters : {},
                data
            },
            { setLoading }
        );
    };

    const handleClickFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const handleClickRefresh = () => {
        if (!onUpdate) return;
        onUpdate(
            {
                rows,
                count,
                rowsPerPage,
                page: 1,
                order,
                orderBy,
                searchText: currentSearchtext,
                filters: filterOpen ? currentFilters : {},
                data
            },
            { setLoading }
        );
    };

    const handleChangeFilter = (newFilters: Filters) => {
        setCurrentFilters(newFilters);
    };

    const handleChangeOrder = (newOrder: 'asc' | 'desc', newOrderBy: string) => {
        if (!onUpdate) return;
        onUpdate(
            {
                rows,
                count,
                rowsPerPage,
                page: 1,
                order: newOrder,
                orderBy: newOrderBy,
                searchText: currentSearchtext,
                filters: filterOpen ? currentFilters : {},
                data
            },
            { setLoading }
        );
    };

    const handleClickActionAdd = () => {
        if (onActionAddClick) {
            onActionAddClick();
        }
    };

    const handleClickDownload = () => {
        if (onDownloadClick) {
            onDownloadClick();
        }
    };

    const handleClickSearch = () => {
        if (!onUpdate) return;
        onUpdate(
            {
                rows,
                count,
                rowsPerPage,
                page: 1,
                order,
                orderBy,
                searchText: currentSearchtext,
                filters: filterOpen ? currentFilters : {},
                data
            },
            { setLoading }
        );
    };

    const handleChangeSearchText = (newSearchText: string) => {
        setCurrentSearchtext(newSearchText);
    };

    const tableHandler = () => ({
        refresh: (newUpdateParams?: UpdateParams<MyTableModel>) => {
            if (onUpdate) {
                onUpdate(newUpdateParams || updateParams, { setLoading });
            }
        }
    });
    useImperativeHandle(ref, tableHandler, [updateParams]);

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const actionBarHeight = actionBarEnable ? 70 : 0;
    const paginationHeight = hiddenPagination ? 0 : 500;
    const filterHeight = filterOpen ? 64 : 0;
    const tableHeightStyle = `calc(100% - ${actionBarHeight + paginationHeight + filterHeight}px)`;

    const renderTableData = () => {
        if (mobileComponent && isSmallScreen) {
            const dataList = <DataListV3 updateParams={updateParams} mobileComponent={mobileComponent} loading={loading} />;
            return (
                <>
                    <DataTableFiltersV3 headers={headers} updateParams={updateParams} filterOpen={filterOpen} onFilterChange={handleChangeFilter} onOrderChange={handleChangeOrder} />
                    <Box sx={{ mt: 1, overflowY: 'auto', transition: 'height .5s, marginTop .5s', height: tableHeightStyle }}>
                        {vScroll && <Scrollbar>{dataList}</Scrollbar>}
                        {!vScroll && dataList}
                    </Box>
                </>
            );
        }

        const table = (
            <Table stickyHeader size="small" aria-label="a dense table">
                <DataTableHeaderV3 headers={headers} updateParams={updateParams} filterOpen={filterOpen} onFilterChange={handleChangeFilter} onOrderChange={handleChangeOrder} />
                <DataTableBodyV3 headers={headers} updateParams={updateParams} rowStyles={rowStyles} onRowClick={onRowClick} collapsableComponent={collapsableComponent} loading={loading} />
            </Table>
        );

        return (
            <>
                <DataTableFiltersV3 headers={headers} updateParams={updateParams} filterOpen={filterOpen} onFilterChange={handleChangeFilter} onOrderChange={handleChangeOrder} />
                <TableContainer sx={{ minWidth: 800, overflowY: 'auto', transition: 'height .5s, marginTop .5s', height: tableHeightStyle }}>
                    {vScroll && <Scrollbar>{table}</Scrollbar>}
                    {!vScroll && table}
                </TableContainer>
            </>
        );
    };

    return (
        <Card sx={{ p: 1 }}>
            {actionBarEnable && (
                <ActionBarV3
                    onFilterClick={isSmallScreen && (typeof showFilters !== 'undefined' && !showFilters) ? undefined : handleClickFilter}
                    onResfreshClick={typeof showRefresh !== 'undefined' && !showRefresh ? undefined : handleClickRefresh}
                    onSearchClick={typeof showSearch !== 'undefined' && !showSearch ? undefined : handleClickSearch}
                    onSearchTextChange={handleChangeSearchText}
                    onActionAddClick={onActionAddClick ? handleClickActionAdd : undefined}
                    onDownloadClick={onDownloadClick ? handleClickDownload : undefined}
                    updateParams={updateParams}
                    headers={headers}
                    loading={isLoading || undefined}
                />
            )}
            {loading && <LinearProgress sx={{ height: '4px', background: '#e0e0e0', zIndex: 9 }} color="primary" variant="indeterminate" />}
            {renderTableData()}
            {!hiddenPagination && (
                <Box>
                    <TablePagination
                        labelDisplayedRows={({ from, to, count }) => `Mostrando ${from}-${to} de ${count}`}
                        labelRowsPerPage="Filas por pagina"
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        page={page - 1}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
            )}
        </Card>
    );
};

export const DataTableV3 = forwardRef(DataTableV3Component);
