import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import { firebaseConfig, PUBLIC_VAPID_KEY } from 'config/app-config';
import { BaseService } from 'services/base/BaseService';
import { CacheBuster } from 'CacheBuster';
import { SessionInfo } from './auth';

export type NotificationAlert = {
    isLatestVersion: boolean;
    newNotifications: number;
};

export const DEFAULT_NOTIFICATION_ALERT: NotificationAlert = {
    newNotifications: 0,
    isLatestVersion: true
};

if (firebase.apps.length === 0) {
    try {
        firebase.initializeApp(firebaseConfig);
    } catch (err) {
        BaseService.log('[firebase] firebase.initializeApp ...', err);
    }
}

let messaging: firebase.messaging.Messaging | null = null;
try {
    messaging = firebase.messaging();
} catch (err) {
    BaseService.log('[firebase] firebase.messaging ...', err);
}

export const registrarDispositivoParaNotificacionesPush = async (): Promise<boolean> => {
    if (hasDeviceBeenRegistered()) {
        return true;
    }

    // Intenta habilitar las notificaciones
    if (!notificationPermissionGranted()) {
        await preguntarPorPermisoNotificaciones();
        if (!notificationPermissionGranted()) return false;
    }

    if (!messaging) return false;
    const token = await messaging.getToken({ vapidKey: PUBLIC_VAPID_KEY }).catch((err: any) => {
        BaseService.log('messaging.getToken...', err);
    });
    if (!token) return false;

    return sendTokenToServer(token);
};

export const removerDispositivoParaNotificacionesPush = async (): Promise<boolean> => {
    const token = getDeviceRegisteredFromLocalStorage();
    if (!token) return true;

    if (!messaging) return false;
    await messaging.deleteToken().catch(() => ({}));
    return removeTokenFromServer(token);
};

const preguntarPorPermisoNotificaciones = async (): Promise<void> => {
    // REQUEST PERMISSION
    let newPermission = 'denied';
    if (window.Notification && Notification.permission !== 'denied') {
        const permission = await Notification.requestPermission();
        newPermission = String(permission);
    }
    setNotificationPermissionToLocalStorage(newPermission);

    // Si la respuesta es negativa, elimina cualquier registro existente
    if (!notificationPermissionGranted()) {
        const tokenToRemove = getDeviceRegisteredFromLocalStorage();
        if (tokenToRemove) await removeTokenFromServer(tokenToRemove);
    }
};

export const notificationPermissionGranted = (): boolean => {
    return `${localStorage.getItem('notificationPermission')}` === 'granted';
};

const setNotificationPermissionToLocalStorage = (permission: string): void => {
    localStorage.setItem('notificationPermission', permission);
};

const removeNotificationPermissionFromLocalStorage = (): void => {
    localStorage.removeItem('notificationPermission');
};

export const hasDeviceBeenRegistered = (): boolean => {
    return localStorage.getItem('deviceRegistered') !== null;
};

const setDeviceRegisteredToLocalStorage = (token: string): void => {
    localStorage.setItem('deviceRegistered', token);
};

const removeDeviceRegisterFromLocalStorage = (): void => {
    localStorage.removeItem('deviceRegistered');
};

const getDeviceRegisteredFromLocalStorage = (): string | null => {
    return localStorage.getItem('deviceRegistered');
};

const sendTokenToServer = async (currentToken: string): Promise<boolean> => {
    if (hasDeviceBeenRegistered()) return true;
    if (!isAuthenticated()) return false;
    return BaseService.request('post', '/notificaciones/registro_dispositivo', { token: currentToken }).then((result) => {
        if (!result.success) return false;
        setDeviceRegisteredToLocalStorage(currentToken);
        return true;
    });
};

const removeTokenFromServer = async (currentToken: string): Promise<boolean> => {
    if (!isAuthenticated()) return false;
    const data = { token: currentToken };
    return BaseService.request('delete', '/notificaciones/registro_dispositivo', data).then((result) => {
        if (!result.success) return false;
        removeDeviceRegisterFromLocalStorage();
        return true;
    });
};

export const enviarNotificacionPushPrueba = async (): Promise<boolean> => {
    if (!isAuthenticated()) return false;
    const data = { token: getDeviceRegisteredFromLocalStorage() };
    return BaseService.request('post', '/notificaciones/registro_dispositivo/mensaje', data).then((result) => {
        if (!result.success) return false;
        return true;
    });
};

export const registerLogin = (sessionInfo: SessionInfo) => {
    setSessionInfoToLocalStorage(sessionInfo);
    setTimeout(() => initBackgroundTasks(), 3000); // Cargado inicial luego de 3 segundos
};

export const registerLogout = () => {
    cleanLocalStorage();
};

export const getSessionInfoFromLocalStorage = (): SessionInfo | null => {
    const item = localStorage.getItem('auth');
    if (item) return JSON.parse(item);
    return null;
};

const setSessionInfoToLocalStorage = (sessionInfo: SessionInfo): void => {
    localStorage.setItem('auth', JSON.stringify(sessionInfo));
};

const removeSessionInfoFromLocalStorage = (): void => {
    localStorage.removeItem('auth');
};

export const isAuthenticated = (): boolean => {
    const item = localStorage.getItem('auth');
    const estaAutenticado = item !== null; //&& String(item).indexOf('id_usuario') !== -1;
    return estaAutenticado;
};

const setNotificationAlertToLocalStorage = (data: NotificationAlert): void => {
    localStorage.setItem('notification_alert', JSON.stringify(data));
};

export const getNotificationAlertFromLocalStorage = (): NotificationAlert | null => {
    const item = localStorage.getItem('notification_alert');
    if (item) return JSON.parse(item);
    return null;
};

const removeNotificationAlertFromLocalStorage = (): void => {
    localStorage.removeItem('notification_alert');
};

export const registrarAlertasNotificacion = async (): Promise<boolean> => {
    if (!isAuthenticated()) return false;
    const isLatestVersion = await CacheBuster.isLatestVersion();
    return BaseService.request<NotificationAlert>('get', `/notificaciones/contar`).then((result) => {
        const newAlert = result.success && result.data ? result.data : DEFAULT_NOTIFICATION_ALERT;
        newAlert.isLatestVersion = isLatestVersion;
        setNotificationAlertToLocalStorage(newAlert);
        return true;
    });
};

export const hasNotificationAlertBeenRegistered = (): boolean => {
    return localStorage.getItem('notification_alert') !== null;
};

const cleanLocalStorage = () => {
    removeNotificationPermissionFromLocalStorage();
    removeDeviceRegisterFromLocalStorage();
    removeNotificationAlertFromLocalStorage();
    removeSessionInfoFromLocalStorage();
    localStorage.removeItem('jsonData');
    localStorage.removeItem('dashboardMenu');
};

// Remueve los registros que se generan en segundo plano
removeNotificationPermissionFromLocalStorage();
removeDeviceRegisterFromLocalStorage();
removeNotificationAlertFromLocalStorage();
localStorage.removeItem('dashboardMenu');

// Inicia procesos en segundo plano
async function initBackgroundTasks() {
    registrarDispositivoParaNotificacionesPush();
    registrarAlertasNotificacion();
}

if (isAuthenticated()) {
    setTimeout(() => initBackgroundTasks(), 3000); // Cargado inicial luego de 3 segundos
    // setInterval(() => initBackgroundTasks(), 300000); // Cada 5 minutos
}

export const auth = firebase.auth();
export const db = firebase.database();
export const storage = firebase.storage();
