/* import React, { useState } from 'react'
import {
    AdvancedMarker,
    APIProvider,
    InfoWindow,
    Map,
    Pin,
    useAdvancedMarkerRef
} from '@vis.gl/react-google-maps';
// config
import { GOOGLE_MAPS_API_KEY } from 'config/app-config';
//@mui
import { Box, Typography } from '@mui/material';
import { MapPosition } from '../FichaModuleService';
import { DEFAULT_ZOOM } from 'constants/enums';

import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';

type GoogleMapFichaProps = {
    mapCenter: MapPosition;
    info?: string;
    mapContainerStyle?: any;
}

const GoogleMapFicha = (props: GoogleMapFichaProps) => {
    const { mapCenter, info, mapContainerStyle } = props;
    const [infowindowOpen, setInfowindowOpen] = useState(true);
    const [markerRef, marker] = useAdvancedMarkerRef();



    const renderGoogleContent = () => {
        return (
            <>
                <AdvancedMarker
                    ref={markerRef}
                    position={mapCenter}
                    title={'AdvancedMarker with customized pin.'}
                    onClick={() => setInfowindowOpen(true)}
                >
                    <Pin
                        background={'#ff5757'}
                        borderColor={'#660000'}
                        glyphColor={'#FFF4F4'}
                        scale={1.4}
                    >
                        <EmojiPeopleIcon />
                    </Pin>
                </AdvancedMarker>
                {
                    infowindowOpen && (
                        <InfoWindow
                            anchor={marker}
                            maxWidth={200}
                            onCloseClick={() => setInfowindowOpen(false)}
                        >
                           <Typography component="span" variant="caption">{info}</Typography>
                        </InfoWindow>
                    )
                }
            </>
        )
    }

    return(
        <Box style={mapContainerStyle}>
            <APIProvider apiKey={GOOGLE_MAPS_API_KEY} libraries={['marker']}>
                <Map
                    mapId={'123456'}
                    zoom={DEFAULT_ZOOM}
                    center={mapCenter}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                >
                    {renderGoogleContent()}
                </Map>
            </APIProvider>
        </Box>
    );
} */
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, Tooltip } from 'react-leaflet';
import { Box, Typography } from '@mui/material';
import L, { LatLngExpression } from 'leaflet';

import 'leaflet/dist/leaflet.css';
import { WEB_CLIENT_URL } from 'config/app-config';

// Tipo para la posición del mapa
type MapPosition = {
    lat: number;
    lng: number;
};

// Propiedades del componente
type OpenStreetMapFichaProps = {
    mapCenter: MapPosition;
    info?: string;
    mapContainerStyle?: React.CSSProperties;
}

// Componente OpenStreetMapFicha
const GoogleMapFicha = (props: OpenStreetMapFichaProps) => {
    const { mapCenter, info, mapContainerStyle } = props;
    const [infowindowOpen, setInfowindowOpen] = useState(true);

    const customIcon = new L.Icon({
        iconUrl: `${WEB_CLIENT_URL}/static/images/pin.png`,
        iconSize: [48, 48], // size of the icon
        iconAnchor: [24, 48], // punto del icono que corresponderá a la ubicación del marcador (centro inferior)
        popupAnchor: [0, -48] // punto desde el cual se abrirá el popup en relación con el iconAnchor
    });

    const RecenterAutomatically = ({lat, lng}: any) => {
        const map = useMap();
         useEffect(() => {
           map.setView([lat,lng]);
         }, [lat,lng]);
         return null;
    }

    return (
        <Box style={mapContainerStyle}>
            <MapContainer
                center={mapCenter as LatLngExpression}
                zoom={17}
                style={{ height: '100%', width: '100%' }}
                scrollWheelZoom={false}
                easeLinearity={0.35}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="<a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a>"
                />
                <Marker
                    position={mapCenter as LatLngExpression}
                    icon={customIcon}                    
                    
                >   
                    <Tooltip direction="top" offset={[0, -48]} opacity={1} permanent>
                        <Box display="flex" alignItems="center">
                            <Typography component="span" variant="caption" style={{ marginRight: '8px' }}>
                                {info}
                            </Typography>
                        </Box>
                    </Tooltip>
                </Marker>
                <RecenterAutomatically lat={mapCenter.lat} lng={mapCenter.lng} />
            </MapContainer>
        </Box>
    );
};


export default GoogleMapFicha
