import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, StatusColumn, ChangeStateColumn } from 'components/core/DataTable';
//@mui
import { Box } from '@mui/material';
//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { SolicitudVacacionModuleService } from '../SolicitudVacacionModuleService';
import { ENUM_ESTADO_JEFE, ENUM_ESTADOS } from 'constants/enums';
import { ESTADO_B } from 'constants/colors';
import { SolicitudVacacionSolicitadaTableItem } from './SolicitudVacacionSolicitadaTableItem';

export type SolicitudVacacionTableModel = {
    id: string;
    gestion       : string;
    nombre?       : string;
    cargo?        : string;
    area?        : string;
    dias          : number;
    tipo          : string;
    estado        : string;
    fecha_salida  : string;
    fecha_retorno : string;
    vacacion_id   : string;

    // para las columnas especiales
    actions: unknown;
};

const statusFilter: HeaderFilter = { type: 'select', options: ENUM_ESTADOS };

export type SolicitudVacacionTableRefProps = {
    refresh: (updateParams?: UpdateParams<SolicitudVacacionTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<SolicitudVacacionTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};


type Props = {
    onAddClick?: () => void;
    onEditClick: (idSolicitudVacacion: string) => Promise<void>;
};

export const SolicitudVacacionSolicitadaTableComponent = (props: Props, ref: React.Ref<SolicitudVacacionTableRefProps>): ReactElement => {
    const { onEditClick } = props;
    const notify = useNotify();
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<SolicitudVacacionTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<SolicitudVacacionTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnActions, width: 120 },
        { id: 'estado', label: 'Revision', render: renderColumnChange },        
        { id: 'nombre', label: 'Nombre', align: 'left', width: 240 }, 
        { id: 'cargo', label: 'Cargo', align: 'left', width: 240 }, 
        { id: 'area', label: 'Area', align: 'left', width: 240 }, 
        { id: 'gestion', label: 'Gestion', align: 'left' }, 
        { id: 'tipo', label: 'Tipo de Solicitud', align: 'left' }, 
        { id: 'dias', label: 'Dias', align: 'left' },
        { id: 'fecha_salida', label: 'Fecha Salida', align: 'left' },
        { id: 'fecha_retorno', label: 'Fecha Retorno', align: 'left' },
        { id: 'estado', label: 'Estado', filter: statusFilter, render: renderColumnStatus }
    ];

    const handleUpdateTable = (params: UpdateParams<SolicitudVacacionTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        SolicitudVacacionModuleService.getTableSolicitudVacacionSolicitada(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<SolicitudVacacionTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    function renderColumnStatus(data: SolicitudVacacionTableModel): ReactElement {
        const color = 'white';
        const background = ESTADO_B[data.estado];
        return <StatusColumn status={data.estado} color={color} background={background} />;
    }
    
    function renderColumnChange(data: SolicitudVacacionTableModel): ReactElement {
        if(data.estado==='SUPERIOR' || data.estado!=='PENDIENTE') return <>{'-'}</>;
        return (
            <ChangeStateColumn
                data={data.estado}
                options={ENUM_ESTADO_JEFE}
                onChange={async (newValue: any) => {
                    return SolicitudVacacionModuleService.setActiveSolicitudVacacion(data.id, newValue).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Estado actualizado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    function renderMobileComponent(data: SolicitudVacacionTableModel) {
        return (
            <SolicitudVacacionSolicitadaTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
                renderColumnChange={renderColumnChange(data)}
            />
        );
    }
   
    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);
        
    function renderColumnActions(data: SolicitudVacacionTableModel): ReactElement {
        return (
            <ActionColumn                
                onEditClick={() => onEditClick(data.id)}
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            <strong>Nombre: </strong> {data.vacacion_id}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return SolicitudVacacionModuleService.destroySolicitudVacacion(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('SolicitudVacacion eliminado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    
    return (
        <>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                showFilters={true}
                vScroll
            />
        </>
    );
};

export const SolicitudVacacionSolicitadaTable = forwardRef(SolicitudVacacionSolicitadaTableComponent);
