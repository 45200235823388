import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page from 'components/Page';
import { PermisoModule } from 'modules/rrhh/permiso';

// ----------------------------------------------------------------------

export default function Permiso() {
    return (
        <Page title="Permiso">
            <Container maxWidth="xl">
                <Typography variant="h4">Permiso</Typography>
                <PermisoModule />
            </Container>
        </Page>
    );
}
