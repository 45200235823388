import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { NotificacionTableModel } from './components/NotificacionTable';
import { NotificacionFormModel } from './components/NotificacionFormDialog';

import { NotificacionTableModel as NotificacionSentTableModel } from './components/NotificacionSentTable';

export type PlanificadorModel = {
    id: string;
    grupo: string;
    nombre: string;
    caption: string;
    fid_usuario: string;
};

const getTableNotificaciones = async (queryParams?: QueryParams): Promise<BaseResponse<NotificacionTableModel>> => {
    return BaseService.findAll('/notificaciones/buzon', queryParams);
};

const getTableNotificacionesEnviadas = async (queryParams?: QueryParams): Promise<BaseResponse<NotificacionSentTableModel>> => {
    return BaseService.findAll('/notificaciones/sent', queryParams);
};

const sendNotification = async (data: NotificacionFormModel): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', '/notificaciones/enviar', data);
};

const markNotificationAsView = async (id_buzon_notificacion: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/notificaciones/marcar_visto`, { id_buzon_notificacion });
};

const destroyNotification = async (id_notificacion: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/notificaciones/sent/${id_notificacion}`);
};


export const NotificacionModuleService = {
    getTableNotificaciones,
    getTableNotificacionesEnviadas,
    sendNotification,
    markNotificationAsView,
    destroyNotification
};
