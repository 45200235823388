import React, { useState, useEffect, useCallback } from 'react';
import { useIsMounted } from 'hooks/useIsMounted';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { NotificacionModule } from 'modules/notificacion';

// ----------------------------------------------------------------------

export default function Notificacion() {
    return (
        <Page title="Notificaciones">
            <Container maxWidth="xl">
                <Typography variant="h4">Notificaciones</Typography>
                <NotificacionModule />
            </Container>
        </Page>
    );
}
