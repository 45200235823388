import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { parse as dateParse, format } from 'date-fns';
import { FormGroup, FormDialog, FormValue, SelectOption } from 'components/core/FormDialog';
import { PermisoModuleService } from '../PermisoModuleService';
import { useNotify } from 'services/notify';
import { OptionsFormModel } from 'modules/Types';
import { useIsMounted } from 'hooks/useIsMounted';
import { ENUM_TIPO_PERMISO, TIME_FORMAT_2 } from 'constants/enums';
import { afterDateTest, dateTest } from 'components/core/FormDialog/yup-tests';

export type PermisoFormModel = {
    id?: number;
    fecha_registro: Date;
    fecha_ini     : Date;
    fecha_fin     : Date;
    hora_ini      : string;
    hora_fin      : string;
    tipo_permiso  : string;
    motivo        : string;
    respaldo      : string;
    lugar         : string;
    estado        : string;
    observacion?  : string;
    usuario_id?   : string;
    jefe_id       : string;
    area_id       : string;

    _createdBy?: string;
    _createdAt?: string;
    _updatedBy?: string;
    _updatedAt?: string;
};

type Props = {
    open: boolean;
    formModel?: PermisoFormModel;
    onComplete: () => void;
};

const FORMAT = 'dd/MM/yyyy';

export const PermisoFormDialog = ({ open, formModel, onComplete }: Props): ReactElement => {
    const notify = useNotify();
    const isMounted = useIsMounted();

    const [usuarios, setUsuarios] = useState<OptionsFormModel[]>([]);
    const usuariosOptions: SelectOption[] = usuarios.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));

    const [areas, setAreas] = useState<OptionsFormModel[]>([]);
    const areasOptions: SelectOption[] = areas.map((item: OptionsFormModel) => ({ value: item.id || '', label: item.nombre }));

    const tipoPermisoOptions: SelectOption[] = ENUM_TIPO_PERMISO;

    const formLayout: FormGroup<PermisoFormModel>[] = [
        {
            title: 'Seleccion Area  y Jefe Inmediato',
            grid: [
                [{ name: 'area_id', label: 'Area', type: 'autocomplete', options: areasOptions }],
                [{ name: 'jefe_id', label: 'Jefe Inmediato', type: 'autocomplete', options: usuariosOptions }],
            ]
        },
        {
            title: 'Descripcion del Permiso',
            grid: [
                [
                    { name: 'tipo_permiso', label: 'Tipo de Permiso', type: 'select', options: tipoPermisoOptions },
                ],
                [
                    { name: 'lugar', label: 'Lugar', type: 'text' },
                ],
                [
                    { name: 'respaldo', label: 'Respaldo', type: 'text' },
                ],
                [
                    { name: 'fecha_ini', label: 'Fecha Inicio', type: 'date', format: FORMAT  },
                    { name: 'fecha_fin', label: 'Fecha Final', type: 'date', format: FORMAT  }
                ],
                [
                    { name: 'hora_ini', label: 'Hora Inicio', type: 'time', format: TIME_FORMAT_2  },
                    { name: 'hora_fin', label: 'Hora Final', type: 'time', format: TIME_FORMAT_2  }
                ],
                [
                    { name: 'motivo', label: 'Motivo', type: 'textarea', rows: 3 },
                ]
            ]
        },
    ];

    const whenHoraInicio = (fechaInicioValue: string, schema: yup.StringSchema) => {
        return schema.test(afterDateTest(fechaInicioValue, TIME_FORMAT_2, 'Debe ser posterior a la Hora de inicio'));
    };

    const whenFechaInicio = (fechaInicioValue: string, schema: yup.StringSchema) => {
        return schema.test(afterDateTest(fechaInicioValue, FORMAT, 'Debe ser posterior a la Fecha de inicio'));
    };

    const validationSchema = yup
        .object({
            fecha_ini   : yup.string().required().test(dateTest(FORMAT)),
            fecha_fin   : yup.string().required().test(dateTest(FORMAT)).when('fecha_ini', whenFechaInicio),
            hora_ini    : yup.string().required().test(dateTest(TIME_FORMAT_2)),
            hora_fin    : yup.string().required().test(dateTest(TIME_FORMAT_2)).when('hora_ini', whenHoraInicio),
            lugar       : yup.string().required(),
            tipo_permiso: yup.string().required(),
            area_id     : yup.string().required(),
            jefe_id     : yup.string().required(),
        })
        .defined();

    const handleSubmit = async (formData: FormValue) => {
        const result = await PermisoModuleService.createOrUpdatePermiso(formData as unknown as PermisoFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success(result.msg);
        return onComplete();
    };

    const handleCancel = () => {
        onComplete();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const resultArea = await PermisoModuleService.getAllArea();
            if (!resultArea || !resultArea.success) return;
            const areas = resultArea.rows || [];

            const resultUsuario = await PermisoModuleService.getAllUsuarios();
            if (!resultUsuario || !resultUsuario.success) return;
            const newUsuarios = resultUsuario.rows || [];

            if (isMounted()) {
                setAreas(areas);
                setUsuarios(newUsuarios);
            };
        };
        if (open) {
            fetchData();
        }
    }, [open, isMounted, formModel]);

    const zeroValues: PermisoFormModel = {
        fecha_registro: new Date(),
        fecha_ini     : new Date(),
        fecha_fin     : new Date(),
        hora_ini      : '',
        hora_fin      : '',
        tipo_permiso  : '',
        motivo        : '',
        respaldo      : '',
        lugar         : '',
        estado        : 'PENDIENTE',
        jefe_id       : '',
        area_id       : '',
    };

    const newFormModel = formModel && {
        id            : formModel.id,
        fecha_registro: formModel.fecha_registro,
        fecha_ini     : formModel.fecha_ini,
        fecha_fin     : formModel.fecha_fin,
        hora_ini      : dateParse(formModel.hora_ini, TIME_FORMAT_2, new Date()),
        hora_fin      : dateParse(formModel.hora_fin, TIME_FORMAT_2, new Date()),
        tipo_permiso  : formModel.tipo_permiso,
        motivo        : formModel.motivo,
        respaldo      : formModel.respaldo,
        lugar         : formModel.lugar,
        estado        : formModel.estado,
        observacion   : formModel.observacion,
        usuario_id    : formModel.usuario_id,
        jefe_id       : formModel.jefe_id,
        area_id       : formModel.area_id,
    };

    return (
        <FormDialog
            addTitle="Agregar permiso"
            editTitle="Editar permiso"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={newFormModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
            isEdit={typeof formModel !== 'undefined'}
        />
    );
};
