import React, { useState, ReactElement, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { ActionColumn, DataTable, DataTableRefProps, OnUpdateOptions, TableHeader, UpdateParams } from 'components/core/DataTable';

import { QueryParams } from 'services/base/Types';
import { NotificacionModuleService } from '../NotificacionModuleService';
import { NotificacionSentTagColumn } from './columns/NotificacionSentTagColumn';
import { useNotify } from 'services/notify';
import { NotificacionSentTableItem } from './NotificacionSentTableItem';
import { useIsMounted } from 'hooks/useIsMounted';

// Este modelo representa a la tabla de notificaciones
export type NotificacionTableModel = {
    id: string; // id_notificacion
    categoria: string;
    asunto: string;
    fecha: string;
    contenido: string;
    adjuntos: string;
    link: string | null;

    // para las columnas especiales
    actions?: unknown;
};

const tableParamsInitialize: UpdateParams<NotificacionTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

export type TableHandlerProps = {
    refresh: () => void;
    search: (searchText: string) => void;
};

type Props = {
    onNotificationClick: (buzon_notificaciones: NotificacionTableModel) => void;
};

const NotificacionTableComponent = (props: Props, ref: React.Ref<TableHandlerProps>): ReactElement => {
    const [tableParams, setTableParams] = useState<UpdateParams<NotificacionTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);
    const isMounted = useIsMounted();
    const notify = useNotify();
    const tableHeaders: TableHeader<NotificacionTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnAction, width: 80 },
        {
            id: 'categoria',
            label: 'Etiqueta',
            align: 'center',
            render: renderColumnTag,
            onCellClick: handleClickCell,
            width: 90
        },
        { id: 'asunto', label: 'Asunto', align: 'left', onCellClick: handleClickCell },
        { id: 'fecha', label: 'Fecha', align: 'center', onCellClick: handleClickCell }
    ];

    const handleUpdateTable = (params: UpdateParams<NotificacionTableModel>, opt: OnUpdateOptions) => {
        if (!isMounted()) return;
        opt.setLoading(true);
        NotificacionModuleService.getTableNotificacionesEnviadas(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<NotificacionTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    function handleClickCell(data: NotificacionTableModel) {
        props.onNotificationClick(data);
    }

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    function renderColumnAction(data: NotificacionTableModel): ReactElement {
        return (
            <ActionColumn
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            <strong>Asunto: </strong> {data.asunto}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return NotificacionModuleService.destroyNotification(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Notificaciones eliminadas exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    function renderColumnTag(data: NotificacionTableModel): ReactElement {
        return <NotificacionSentTagColumn data={data} />;
    }

    function renderMobileComponent(data: NotificacionTableModel) {
        return (
            <NotificacionSentTableItem
                data={data}
                onNotificationClick={() => props.onNotificationClick(data)}
                onDeleteClick={() => {
                    NotificacionModuleService.destroyNotification(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Notificaciones eliminadas exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    const tableHandler = () => ({
        refresh: () => tableRef.current?.refresh(),
        search: (searchText: string) => tableRef.current?.refresh({ ...tableParams, searchText })
    });

    useImperativeHandle(ref, tableHandler, [tableParams]);

    return <DataTable 
                ref={tableRef} 
                headers={tableHeaders} 
                updateParams={tableParams} 
                onUpdate={handleUpdateTable} 
                showActionBar={false} 
                mobileComponent={renderMobileComponent}
                vScroll 
            />;
};

export const NotificacionSentTable = forwardRef(NotificacionTableComponent);
