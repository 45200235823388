import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { SolicitudVacacionTableModel } from './components/SolicitudVacacionSolicitadaTable';
import { SolicitudVacacionFormModel } from './components/SolicitudVacacionFormDialog';

const getTableSolicitudVacacionPropia = async (queryParams?: QueryParams): Promise<BaseResponse<SolicitudVacacionTableModel>> => {
    return BaseService.findAll<SolicitudVacacionTableModel>('/solicitud_vacacion/solicitud_vacacion_table_propia', queryParams);
};

const getTableSolicitudVacacionSolicitada = async (queryParams?: QueryParams): Promise<BaseResponse<SolicitudVacacionTableModel>> => {
    return BaseService.findAll<SolicitudVacacionTableModel>('/solicitud_vacacion/solicitud_vacacion_table_solicitada', queryParams);
};

const createOrUpdateSolicitudVacacion = async (data: SolicitudVacacionFormModel): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/solicitud_vacacion/solicitud_vacacion_form`, data);
};

const getSolicitudVacacionFormData = async (id: string): Promise<BaseResponse<SolicitudVacacionFormModel>> => {
    return BaseService.request('get', `/solicitud_vacacion/solicitud_vacacion_form/${id}`);
};

const destroySolicitudVacacion = async (id: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/solicitud_vacacion/solicitud_vacacion_item/${id}`);
};

const setActiveSolicitudVacacion = async (id_solicitud_vacacion: string, estado: string): Promise<BaseResponse<SolicitudVacacionFormModel>> => {
    const data = { estado };
    return BaseService.request('post', `/solicitud_vacacion/solicitud_vacacion_table/${id_solicitud_vacacion}`, data);
};

const getAllVacacion = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; dias: number}>> => {
    return BaseService.findAll('/vacacion/vacacions', queryParams);
};

export const SolicitudVacacionModuleService = {
    getTableSolicitudVacacionSolicitada,
    getTableSolicitudVacacionPropia,
    createOrUpdateSolicitudVacacion,
    getSolicitudVacacionFormData,
    getAllVacacion,
    setActiveSolicitudVacacion,
    destroySolicitudVacacion
};
