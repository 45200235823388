import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { format } from 'date-fns';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, DataTableV3 } from 'components/core/DataTable';
//@mui
import { Box, Dialog, DialogContent, Grid, Paper, Typography } from '@mui/material';
//services
import { QueryParams } from 'services/base/Types';
import {useParams} from 'react-router-dom';
//model
import { SigapoFormModel } from './SigapoDialog';
import { SigapoModuleService } from '../SigapoModuleService';
import { SigapoTableRefProps } from './SigapoTable';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { SigapoDetalleTableItem } from './SigapoDetalleTableItem';

export type SigapoDetalleTableModel = {
    id             : string;
    hoja_de_ruta   : string;
    nro            : string;
    destino        : string;
    lugar          : string;
    instruccion    : string;
    objeto         : string;
    fecha_recepcion: string;
    fecha_salida   : string;
    permanencia    : string;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<SigapoDetalleTableModel> = {
    rows       : [],
    count      : 0,
    rowsPerPage: 5,
    page       : 1
};

const FORMAT = 'dd/MM/yyyy HH:mm';

const nroFilter: HeaderFilter = { type: 'text' };
const destinoFilter: HeaderFilter = { type: 'text' };
const instruccionFilter: HeaderFilter = { type: 'text' };
const fecha_recepcionFilter: HeaderFilter = { type: 'date' };
const fecha_salidaFilter: HeaderFilter = { type: 'date' };

type Props = {
    data: SigapoFormModel | null;
    onViewClick: (id: any) => Promise<void>;
};

export const SigapoDetalleTableComponent = (props: Props, ref: React.Ref<SigapoTableRefProps>): ReactElement => {
    const { data, onViewClick } = props;
    const params = useParams();
    const isMounted = useIsMounted();

    const ID_SIGAPO_DETALLE = params.id || '';

    const [tableParams, setTableParams] = useState<UpdateParams<SigapoDetalleTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<SigapoDetalleTableModel>[] = [
        { id: 'nro', label: 'Nro', align: 'left', sort: false, filter: nroFilter },
        { id: 'destino', label: 'Destino', align: 'left', sort: false, filter: destinoFilter },
        { id: 'lugar', label: 'Lugar', align: 'left', sort: false, filter: destinoFilter },
        { id: 'instruccion', label: 'Tarea o Instruccion', align: 'left', sort: false, filter: instruccionFilter },
        { id: 'fecha_recepcion', label: 'Fecha Recepcion Destino', align: 'left', sort: false, filter: fecha_recepcionFilter },
        { id: 'fecha_salida', label: 'Fecha Salida Destino', align: 'left', sort: false, filter: fecha_salidaFilter },
        { id: 'permanencia', label: 'Permanencia', align: 'left', sort: false },
    ];

    const handleUpdateTable = (params: UpdateParams<SigapoDetalleTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        const newParams = {
            ...params,
            filters: {
                ...params.filters
                //, sigapoDetalleId: ID_SIGAPO_DETALLE
            },
        };
        SigapoModuleService.getTableSigapoDetalle(newParams as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<SigapoDetalleTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    function renderMobileComponent(data: SigapoDetalleTableModel) {
        return (
            <SigapoDetalleTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
            />
        );
    }

    const data1 = [
        { title: "HOJA DE RUTA:", value: "SDAFP-3505/2024" },
        { title: "Remitente:", value: "Secretaria Departamental de Planificacion" },
        { title: "De:", value: "ABDON HERNAN FLORES QUISBERT" },
        { title: "Asunto/ref:", value: "SOLICITUD CERTIFICACION PRESUPUESTARIA" },
        { title: "Permanencia:", value: "59 dias hasta hoy" }
      ];

    return (
        <Paper>
            <Box
                sx={{
                    backgroundColor: '#FFF7CD',
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                    color: 'black',
                    px: 2,
                    py: 1
                }}
            >
                <Typography variant="body2" component="span" sx={{ color: 'black' }}>
                    {
                        data1.map((item, index) => (
                            <Grid container key={index} sx={{ display: 'flex', alignItems: 'baseline' }}>
                                <Grid item xs={12} sm={2}>
                                <b>{item.title}</b>
                                </Grid>
                                <Grid item xs={12} sm={10}>
                                {item.value}
                                </Grid>
                            </Grid>
                        ))
                    }
                </Typography>
            </Box>
            <DataTableV3
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                hiddenPagination={true}
                vScroll
            />
        </Paper>
    );
};

export const SigapoDetalleTable = forwardRef(SigapoDetalleTableComponent);
