import { auth, registerLogout, registerLogin, db  } from './firebase';
import { BaseService } from 'services/base/BaseService';
import { BaseResponse } from 'services/base/Types';

type Permiso = {
    read?: boolean;
    create?: boolean;
    edit?: boolean;
    remove?: boolean;
    send?: boolean;
    download?: boolean;
    approve?: boolean;
    lock?: boolean;
};

export interface Rutas {
    id: string;
    name: string;
    path: string,
    title: string,
    icon: string,
    color: string,
}

export type SessionInfo = {
    id_usuario : string;
    nombre     : string;
    avatar     : string;
    username   : string;
    genero     : string;
    permisos   : Permiso;
    roles      : string;
    celular    : string;
    modulos    : string[];
};

export type RegisterProps = {
    email: string,
    password: string,
    repassword: string,
    ci: string,
    telefono: string,
};

// Sign Up
export const doCreateUser = async (data: RegisterProps): Promise<BaseResponse<unknown>> => {
    try {        
        const registerResponse = await BaseService.request('post', `/users/register`,data);
        if (!registerResponse.success) throw registerResponse.msg;
        if (!registerResponse.data) throw 'unknown data';
               
        return BaseService.sendSuccess();        
    } catch (err: any) {
        const msgError = err;        
        BaseService.log(msgError);
        return BaseService.sendError({ msg: msgError });
    }
};

// Sign In
export const doSignIn = async (email: string, password: string): Promise<BaseResponse<unknown>> => {
    try {
        const userCredential = await auth.signInWithEmailAndPassword(email, password);
        const token = userCredential.user ? await userCredential.user.getIdToken() : null;
        if (!token) throw new Error('unknown token');
        const UID = userCredential.user ? userCredential.user.uid : null;
        // Get user's current location
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const fecha = new Date(position.timestamp);
            // Store user's location in Firebase Realtime Database
            /* if (UID) db.ref(`users/${UID}/location`).set({
                latitude: latitude,
                longitude: longitude,
                fecha: fDateTime(fecha)
            }); */
            // Perform any necessary actions based on user's location
            // ...
            console.log('localizacion latitude,longitude : ',latitude,longitude);
        }, function (e) {
            console.log(e);
        }, {
            maximumAge:60000, 
            timeout:5000, 
            enableHighAccuracy:true
        });

        const loginResponse = await BaseService.request<SessionInfo>('get', `/users/session-info?register=true`);
        if (!loginResponse.success) throw new Error(loginResponse.msg);
        if (!loginResponse.data) throw new Error('unknown data');
        /* const data = loginResponse.data;
        const phoneNumber = localStorage.getItem('phoneNumber') === data.celular;
        if (!phoneNumber) throw new Error('No existe numero de celular Registrado.'); */
        registerLogin(loginResponse.data);

        // INI - Update dashboard menu
        const modulos: string[] = loginResponse.data?.modulos || [];
        localStorage.setItem('dashboardMenu', JSON.stringify(modulos));
        // END - Update dashboard menu

        return BaseService.sendSuccess();
    } catch (err) {
        doSignOut();
        BaseService.log(err);
        return BaseService.sendError({ msg: 'Usuario o contraseña incorrecto' });
    }
};

// Sign out
export const doSignOut = () => {
    registerLogout();
    return auth.signOut();
};

// Password Reset
export const doPasswordReset = (email: string) => {
    return auth.sendPasswordResetEmail(email);
};

// Password Change
export const doPasswordUpdate = async (newPassword: string, code: string) => {
    await auth.confirmPasswordReset(code, newPassword);
};
