import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, Chip, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { SigapoDetalleTableModel } from './SigapoDetalleTable';

type Props = {
    data: SigapoDetalleTableModel;
    refresh: () => void;
};

export const SigapoDetalleTableItem = ({ data, refresh,  }: Props): ReactElement => {

    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
                <Stack direction="row" spacing={1}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography
                                align="center"
                                variant="body2"
                                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}
                        >
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', fontSize: '14px' }}>
                                {data.nro}
                            </Typography>
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', textDecoration: 'underline' }}>
                                {data.destino}
                            </Typography>
                        </Typography>
                        <Typography
                                align="center"
                                variant="body2"
                                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mb: 1 }}
                        >
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', backgroundColor: 'yellow' }}>
                                {data.objeto}
                            </Typography>
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', fontWeight: 'bold' }}>
                                {data.lugar}
                            </Typography>
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'justify', mb: 1 }}>
                            {data.instruccion}
                        </Typography>
                        <Typography
                                align="center"
                                variant="body2"
                                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}
                        >
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', fontStyle: 'italic' }}>
                                {data.fecha_salida}
                            </Typography>
                            <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', mb: 1 }}>
                                {data.permanencia}
                            </Typography>
                        </Typography>

                    </Box>
                    <Box sx={{ flexShrink: 0 }}>

                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};
