import React, { ReactElement, useState, useRef } from 'react';
// @mui

// components
import { DeclaracionFormDialog, DeclaracionFormModel } from './components/DeclaracionFormDialog';
import { DeclaracionTable, DeclaracionTableRefProps } from './components/DeclaracionTable';
import DeclaracionDialog from './components/DeclaracionDialog';
//services
import { DeclaracionModuleService } from './DeclaracionModuleService';
import { useNotify } from 'services/notify';
import { Box, Paper } from '@mui/material';

export const DeclaracionModule = (): ReactElement => {
    const notify = useNotify();

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openDeclaracionForm, setOpenDeclaracionForm] = useState(false);
    const [formModel, setFormModel] = useState<DeclaracionFormModel>();

    const handleClickView = async (id_actividad: string) => {
        const actividadFormResponse = await DeclaracionModuleService.getDeclaracionFormData(id_actividad);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setFormModel(newFormModel);
        setOpenDeclaracionForm(true);
    };

    const handleClickAdd = async () => {
        setFormModel(undefined);
        setFormOpen(true);
    };

    const handleClickEdit = async (id_actividad: string) => {
        const actividadFormResponse = await DeclaracionModuleService.getDeclaracionFormData(id_actividad);
        if (!actividadFormResponse.success) return notify.error(actividadFormResponse.msg);
        const newFormModel = actividadFormResponse.data;
        setFormModel(newFormModel);
        setFormOpen(true);
    };

    const tableRef = useRef<DeclaracionTableRefProps>(null);

    return (
        <>
            <DeclaracionFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                }}
            />
            <DeclaracionDialog
                open={openDeclaracionForm}
                onComplete={() => {
                    setOpenDeclaracionForm(false);
                }}
                formModel={formModel}
            />            
            <DeclaracionTable 
                ref={tableRef} 
                onViewClick={handleClickView} 
                onAddClick={handleClickAdd} 
                onEditClick={handleClickEdit}
            />
        </>
    );
};
