import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, Chip, IconButton, Typography } from '@mui/material';
import { PermisoTableModel } from './PermisoPropiaTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'components/core/ConfirmDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ESTADO_F } from 'constants/colors';


type Props = {
    data: PermisoTableModel;
    refresh: () => void;
    onViewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
};

export const PermisoPropiaTableItem = ({ data, refresh, onEditClick, onViewClick, onDeleteClick }: Props): ReactElement => {


    const color = 'white';
    const background = ESTADO_F[data.estado];

    const [open, setOpen] = useState<boolean>(false);

    const renderViewButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onViewClick ? onViewClick() : false)}>
                <VisibilityIcon />
            </IconButton>
        );
    };

    const renderEditButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onEditClick ? onEditClick() : false)}>
                <EditIcon />
            </IconButton>
        );
    };

    const renderDeleteButton = () => {
        return (
            <>
                <ConfirmDialog
                    title={'Confirmar'}
                    message={
                        <div>
                            <div>¿Quiere eliminar el registro?</div>
                            <br />
                            <div>
                                <strong>Tipo: </strong> {data.id}
                            </div>
                        </div>
                    }
                    open={open}
                    onAccept={async () => {
                        setOpen(false);
                        if (onDeleteClick) return onDeleteClick();
                    }}
                    onCancel={() => setOpen(false)}
                />
                <IconButton color="primary" component="span" size="small" onClick={() => setOpen(true)}>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };

    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
                <Box>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Tipo de Permiso:&nbsp;</b>{data.tipo_permiso}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Fechas :&nbsp;</b>{data.fechas}
                    </Typography>

                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="caption" display="block" sx={{ lineHeight: 2 }}>
                    <b>Estado:&nbsp;</b>
                    <Box component="span" sx={{ color, background, py: 0.5, px: 0.5, borderRadius: '1.5rem', mr: 1 }}>
                        {data?.estado}
                    </Box>
                </Typography>
                <Box>
                    {renderViewButton()}
                    {data?.estado=='PENDIENTE' && renderEditButton()}
                    {data?.estado=='PENDIENTE' && renderDeleteButton()}
                </Box>
            </CardActions>
        </Card>
    );
};
