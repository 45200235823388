import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'constants/routes';
// @mui
import { styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page2 from '../components/Page2';
import Logo2 from '../components/Logo2';
import { RegisterForm } from 'modules/auth/register';
import { WEB_TITLE_APP } from 'config/app-config';
// sections

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    backgroundImage: `url("/static/images/fondo-xs.jpg")`,
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat  : 'no-repeat',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        backgroundSize: 'cover',
        backgroundImage: `url("/static/images/fondo-lg.jpg")`,
    },
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    position: 'absolute',
    padding: theme.spacing(1),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-end',
        marginTop: theme.spacing(0),
        padding: theme.spacing(3, 4, 0, 3)
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  backgroundColor: '#d20000'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(4, 0),
  margin: theme.spacing(4, 0, 0, 0),
  [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 0),
      margin: 'auto',
  }
}));

// ----------------------------------------------------------------------

export default function Register() {
  const smUp = useResponsive('up', 'sm');

  const mdUp = useResponsive('up', 'md');

  return (
    <Page2 title="Registro">
      <RootStyle>
        <HeaderStyle>
            {!smUp && (<Logo2 disabledLink title={WEB_TITLE_APP} sx={{ width: 96, height: 96 }}/>)}
            {smUp && (
                <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                Ya tienes una cuenta? {''}
                <Link variant="subtitle2" component={RouterLink} to={routes.LOGIN}>
                    Ingresar
                </Link>
                </Typography>
            )}
        </HeaderStyle>

        {mdUp && (
          <SectionStyle>
            <img alt="register" src="/static/images/maskable_icon_x512.png" style={{ width: 256, height: 256 }} />
          </SectionStyle>
        )}

        <Container>
          <ContentStyle>

            <Typography sx={{ color: 'text.primary', mt: 9, mb:1, fontWeight: 'bold' }}>Registre sus datos:</Typography>

            <RegisterForm />

            <Typography variant="body2" align="center" sx={{ color: 'text.secondary', mt: 3,  lineHeight: 1.1 }}>
                Al registrarme, acepto los&nbsp;
              <Link underline="always" color="text.primary" href="#">
              términos mínimos de servicio
              </Link>
              {' '}y{' '}
              <Link underline="always" color="text.primary" href="#">
              Política de privacidad
              </Link>
              .
            </Typography>

            {!smUp && (
              <Typography variant="body2" sx={{ mt: 3, textAlign: 'center' }}>
                Ya tienes una cuenta?{' '}
                <Link variant="subtitle2" to={routes.LOGIN} component={RouterLink} color="error">
                  Ingresar
                </Link>
              </Typography>
            )}
          </ContentStyle>
        </Container>
      </RootStyle>
    </Page2>
  );
}
