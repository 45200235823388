import React, { useState, FormEvent } from 'react';
import * as routes from 'constants/routes';
import { auth } from 'services/firebase';

/* Material UI */
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import { Typography } from '@mui/material';
import { useNotify } from 'services/notify';

type State = {
    email: string;
};

type Props = {
    email?: string;
};

const FormForgotPassword: React.FC<Props> = () => {
    const [values, setValues] = useState<State>({ email: '' });
    const [isDirty, setIsDirty] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const notify = useNotify();
    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const showUserNameError = isDirty && !validEmailRegex.test(values.email);
    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleForgotSubmit = (e: FormEvent<HTMLFormElement>) => {
        setIsDirty(true);
        e.preventDefault();
        setIsSending(true);
        auth.doPasswordReset(values.email)
            .then((a) => {
                // setIsSending(false);
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.log(errorCode);
                if(errorCode) notify.error('No existe el correo ingresado en el registro.')
                setIsSending(false);
            });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleForgotSubmit}>
            <Box p={1} m={1}>
                <Typography variant="h6" gutterBottom>
                    Restablecimiento de contraseña
                </Typography>
                {isSending ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column"
                    >
                        <Typography variant="body2" align="justify" gutterBottom>
                            En este momento te estamos enviando instrucciones a <b>{values.email}</b> para recuperar tu
                            contraseña.
                        </Typography>
                        <FormControl>
                            <Button
                                variant="contained"
                                color="error"
                                fullWidth
                                disableRipple
                                href={routes.HOME}
                            >
                                Aceptar
                            </Button>
                        </FormControl>
                    </Box>
                ) : (
                    <>
                        <Typography variant="body2" align="justify" gutterBottom>Para recuperar su contraseña por favor ingrese su correo electrónico.</Typography>
                        <FormControl variant="outlined" error={showUserNameError}>
                            <InputLabel htmlFor="outlined-adornment-username">
                                Correo electrónico
                                {showUserNameError ? <span> * </span> : ''}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-username"
                                type="text"
                                value={values.email}
                                onChange={handleInputChange('email')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <MailIcon />
                                    </InputAdornment>
                                }
                                sx={{
                                    backgroundColor: 'white'
                                }}
                            />
                            {showUserNameError && <FormHelperText>Formato incorrecto *</FormHelperText>}
                        </FormControl>
                        <FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                color="error"
                                disabled={showUserNameError || isSending}
                                sx={{ ml: 4, mt: 2 }}
                            >
                                Continuar
                            </Button>
                        </FormControl>
                    </>
                )}
            </Box>
        </form>
    );
};

export default FormForgotPassword;
