import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { SolicitudVacacionTableModel } from './SolicitudVacacionSolicitadaTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'components/core/ConfirmDialog';
import { format, parse } from 'date-fns';
import es from 'date-fns/locale/es';
import { ESTADO_B } from 'constants/colors';

const FORMAT = 'EEEE, dd MMMM yyyy hh:mm a';

type Props = {
    data: SolicitudVacacionTableModel;
    refresh: () => void;
    onViewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
};

export const SolicitudVacacionPropiaTableItem = ({ data, refresh, onEditClick, onViewClick, onDeleteClick }: Props): ReactElement => {
    const parseDateSalida = parse(data?.fecha_salida, "dd/MM/yyyy hh:mm a", new Date());
    const parseDateRetorno = parse(data?.fecha_retorno, "dd/MM/yyyy hh:mm a", new Date());
    const fecha_salida = parseDateSalida;
    const fecha_retorno = parseDateRetorno;

    const renderEditButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onEditClick ? onEditClick() : false)}>
                <EditIcon />
            </IconButton>
        );
    };

    const [open, setOpen] = useState<boolean>(false);

    const renderDeleteButton = () => {
        return (
            <>
                <ConfirmDialog
                    title={'Confirmar'}
                    message={
                        <div>
                            <div>¿Quiere eliminar el registro?</div>
                            <br />
                            <div>
                                <strong>Tipo: </strong> {data.tipo}
                            </div>
                        </div>
                    }
                    open={open}
                    onAccept={async () => {
                        setOpen(false);
                        if (onDeleteClick) return onDeleteClick();
                    }}
                    onCancel={() => setOpen(false)}
                />
                <IconButton color="primary" component="span" size="small" onClick={() => setOpen(true)}>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };
    const color = 'white';
    const background = ESTADO_B[data.estado];
    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ py: 2, px: 1, '&:last-child': { pb: 1 } }}>
                <Box>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'justify', mb: 0.5 }}>
                        <b>Gestión:&nbsp;</b>{data.gestion}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'justify', mb: 0.5 }}>
                        <b>Tipo:&nbsp;</b>{data.tipo}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1 }}>
                        <b>Dias:&nbsp;</b>{data.dias}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1.5, mt: 1 }}>
                        <b>Fecha Salida:&nbsp;</b>{fecha_salida ? format(fecha_salida, FORMAT, { locale: es }).toLocaleString() : ''}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1.5 }}>
                        <b>Fecha Retorno:&nbsp;</b>{fecha_retorno ? format(fecha_retorno, FORMAT, { locale: es }).toLocaleString() : ''}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 2 }}>
                        <b>Estado:&nbsp;</b><Box component="span" sx={{ color, background, py: 0.5, px: 0.5, borderRadius: '1.5rem' }}>
                                                {data?.estado}
                                            </Box>
                    </Typography>
                </Box>
            </CardContent>
            {
                !(data.estado==='APROBADO' || data.estado==='RECHAZADO') ? <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                                            {renderEditButton()}
                                                                            {renderDeleteButton()}
                                                                        </CardActions>
                                                                        :<></>
            }
        </Card>
    );
};
