import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, ChangeStateColumn } from 'components/core/DataTable';
//@mui

//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
import { PermisoModuleService } from '../PermisoModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { Box } from '@mui/material';
import { PermisoSolicitadaTableItem } from './PermisoSolicitadaTableItem';
import { ENUM_ESTADO_JEFE } from 'constants/enums';

export type PermisoSolicitadaTableModel = {
    id             : string;
    tipo_permiso   : string;
    fechas         : string;
    estado         : string;

    // para las columnas especiales
    actions: unknown;
};


export type PermisoPropiaTableRefProps = {
    refresh: (updateParams?: UpdateParams<PermisoSolicitadaTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<PermisoSolicitadaTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const numeroFilter: HeaderFilter = { type: 'text' };

type Props = {
    onAddClick: () => void;
    onViewClick: (idPermiso: string) => Promise<void>;
    onEditClick: (idPermiso: string) => Promise<void>;
};

export const PermisoSolicitadaTableComponent = (props: Props, ref: React.Ref<PermisoPropiaTableRefProps>): ReactElement => {
    const { onViewClick, onAddClick, onEditClick } = props;
    const notify = useNotify();
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<PermisoSolicitadaTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<PermisoSolicitadaTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnActions },
        { id: 'tipo_permiso', label: 'Tipo de Permiso', align: 'left', sort: false, width: 150, filter: numeroFilter },
        { id: 'fechas', label: 'Fechas', align: 'left' },
        { id: 'estado', label: 'Aprovado', align: 'left' }
    ];

    const handleUpdateTable = (params: UpdateParams<PermisoSolicitadaTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        PermisoModuleService.getTablePermisoSolicitada(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<PermisoSolicitadaTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderMobileComponent(data: PermisoSolicitadaTableModel) {
        return (
            <PermisoSolicitadaTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
                onViewClick={() => onViewClick(data.id)}
                renderColumnChange={renderColumnChange(data)}
            />
        );
    }

    function renderColumnChange(data: PermisoSolicitadaTableModel): ReactElement {
        if(data.estado==='APROBADO_RRHH' || data.estado!=='PENDIENTE') return <>{'-'}</>;
        return (
            <ChangeStateColumn
                data={data.estado}
                options={ENUM_ESTADO_JEFE}
                onChange={async (newValue: any) => {
                    return PermisoModuleService.setActivePermiso(data.id, newValue).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Estado actualizado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    function renderColumnActions(data: PermisoSolicitadaTableModel): ReactElement {
        return (
            <ActionColumn
                onViewClick={() => onViewClick(data.id)}
                onEditClick={() => onEditClick(data.id)}
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            <strong>Nombre: </strong> {data.id}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return PermisoModuleService.destroyPermiso(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Permiso eliminado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }


    return (
        <>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                onActionAddClick={onAddClick}
                mobileComponent={renderMobileComponent}
                vScroll
            />
        </>
    );
};

export const PermisoSolicitadaTable = forwardRef(PermisoSolicitadaTableComponent);
