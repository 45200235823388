import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTableV3, TableHeader, UpdateParams, HeaderFilter, OnUpdateOptions, DataTableRefProps, StatusColumn } from 'components/core/DataTable';
//@mui
import { Box, IconButton } from '@mui/material';
import ListAltIcon from '@mui/icons-material/ListAlt';

//services
import { QueryParams } from 'services/base/Types';
//model
import { SigapoModuleService } from '../SigapoModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { SigapoTableItem } from './SigapoTableItem';
import { ESTADO_G } from 'constants/colors';

export type SigapoTableModel = {
    id                : string;
    hoja_de_ruta      : string;
    fecha_hora_salida : string;
    procedencia       : string;
    nombre            : string;
    referencia        : string;
    hojas             : string;
    derivado_a        : string;
    derivado_area     : string;
    objeto            : string;
    instruccion       : string;
    permanencia       : string;
    entregado_a       : string;
    // para las columnas especiales
    options?: unknown;
};


export type SigapoTableRefProps = {
    refresh: (updateParams?: UpdateParams<SigapoTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<SigapoTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const hojaderutaFilter: HeaderFilter = { type: 'text' };
const fechaFilter: HeaderFilter = { type: 'date' };

type Props = {
    onDetalleClick  : (id: string) => void;
};

export const SigapoTableComponent = (props: Props, ref: React.Ref<SigapoTableRefProps>): ReactElement => {
    const { onDetalleClick } = props;
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<SigapoTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<SigapoTableModel>[] = [
        { id: 'hoja_de_ruta', label: 'Hoja de Ruta', align: 'left', sort: false, render: renderColumnHojadeRuta, width: 130, filter: hojaderutaFilter },
        { id: 'fecha_hora_salida', label: 'Fecha Salida', align: 'left', width: 150, filter: fechaFilter },
        { id: 'nombre', label: 'Nombre', align: 'left' },
        { id: 'procedencia', label: 'Procedencia', align: 'left' },
        { id: 'referencia', label: 'Referencia', align: 'left' },
        { id: 'hojas', label: 'Nro Hojas', align: 'left' },
        { id: 'derivado_a', label: 'Derivado a', align: 'left' },
        { id: 'derivado_area', label: 'Area de Derivacion', align: 'left' },
        { id: 'objeto', label: 'Objeto', align: 'left', render: renderColumnObjeto },
        { id: 'permanencia', label: 'Permanencia Hasta hoy', align: 'left' },
        { id: 'instruccion', label: 'Instruccion', align: 'left' },
        {
            id: 'options',
            label: 'Detalle',
            sort: false,
            render: renderColumnOptions,
            width: 70,
        },
    ];

    const handleUpdateTable = (params: UpdateParams<SigapoTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        SigapoModuleService.getTableSigapo(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<SigapoTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderMobileComponent(data: SigapoTableModel) {
        return (
            <SigapoTableItem
                data={data}
                onDetalleClick={() => onDetalleClick(data.id)}
                refresh={() => tableRef.current?.refresh()}
            />
        );
    }

    function renderColumnOptions(data: SigapoTableModel): ReactElement {
        return (
            <>
                <IconButton size="small" onClick={() => onDetalleClick(data.id)} color="info">
                    <ListAltIcon />
                </IconButton>
            </>
        );
    }

    function renderColumnHojadeRuta(data: SigapoTableModel): ReactElement {
        return <Box sx={{ fontWeight: 'bold', fontSize: '14px' }}>{data.hoja_de_ruta}</Box>;
    }

    function renderColumnObjeto(data: SigapoTableModel): ReactElement {
        const color = 'white';
        const background = ESTADO_G[data.objeto] || 'black';
        return <StatusColumn status={data.objeto} color={color} background={background} />;
    }

    return (
        <>
            <DataTableV3
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                hiddenPagination={true}
                vScroll
            />
        </>
    );
};

export const SigapoTable = forwardRef(SigapoTableComponent);
