import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import * as routes from 'constants/routes';
// @mui
import { alpha , styled } from '@mui/material/styles';
import { Card, Link, Container, Typography } from '@mui/material';
// hooks
import useResponsive from '../hooks/useResponsive';
// components
import Page2 from '../components/Page2';
import Logo2 from '../components/Logo2';
// sections
import { LoginForm } from '../modules/auth/login';
import { WEB_TITLE_APP } from 'config/app-config';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    backgroundImage: `url("/static/images/fondo-xs.jpg")`,
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat  : 'no-repeat',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        backgroundSize: 'cover',
        backgroundImage: `url("/static/images/fondo-lg.jpg")`,
    }
}));

const HeaderStyle = styled('header')(({ theme }) => ({
    top: 0,
    zIndex: 9,
    lineHeight: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    position: 'absolute',
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
        alignItems: 'flex-end',
        padding: theme.spacing(3, 4, 0, 3)
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function Login() {
    const smUp = useResponsive('up', 'sm');
    return (
        <Page2 title="Acceso">
            <RootStyle>
                <HeaderStyle>
                {smUp && (
                            <Typography variant="body2" sx={{ mt: { md: -2 } }}>
                            ¿No tienes una cuenta?{' '}
                            <Link variant="subtitle2" component={RouterLink} to={routes.REGISTER} color="error">
                            Registrarse
                            </Link>
                            </Typography>
                        )}
                </HeaderStyle>
                <Container maxWidth="sm">
                    <ContentStyle>
                        <Logo2 disabledLink title={WEB_TITLE_APP} sx={{ width: 96, height: 96 }} />

                        <Typography sx={{ color: 'text.secondary', mt: 2, mb: 2 }}>Ingrese sus datos a continuación.</Typography>
                        <LoginForm />
                        {!smUp && (
                            <Typography variant="body2" align="center" sx={{ mt: 3 }}>
                                ¿No tienes una cuenta?{' '}
                                <Link variant="subtitle2" component={RouterLink} to={routes.REGISTER} color="error">
                                Registrarse
                                </Link>
                            </Typography>
                        )}
                    </ContentStyle>
                </Container>
            </RootStyle>
        </Page2>
    );
}
