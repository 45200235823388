import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { SolicitudVacacionModule } from 'modules/rrhh/solicitud_vacacion';

// ----------------------------------------------------------------------

export default function SolicitudVacacion() {
    return (
        <Page title="Solicitud de Vacacion">
            <Container maxWidth="xl">
                <Typography variant="h4">Solicitud de Vacacion</Typography>
                <SolicitudVacacionModule />
            </Container>
        </Page>
    );
}
