import React from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Badge } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import useLocalStorage from 'hooks/localstorage/useLocalStorage';
import { DEFAULT_NOTIFICATION_ALERT, NotificationAlert } from 'services/firebase/firebase';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
    boxShadow: 'none',
    backdropFilter: 'blur(6px)',
    WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up('lg')]: {
        width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
    }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
    minHeight: APPBAR_MOBILE,
    backgroundColor: theme.palette.error.dark,
    [theme.breakpoints.up('lg')]: {
        minHeight: APPBAR_DESKTOP,
        padding: theme.spacing(0, 5)
    }
}));

// ----------------------------------------------------------------------

type Props = {
    onOpenSidebar: () => void;
};

export default function DashboardNavbar({ onOpenSidebar }: Props) {
    const [notificationAlert] = useLocalStorage<NotificationAlert>('notification_alert', DEFAULT_NOTIFICATION_ALERT);
    const hasNotificationAlert = (): boolean => {
        return !notificationAlert.isLatestVersion;
    };
    return (
        <RootStyle>
            <ToolbarStyle>
                <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'common.white', display: { lg: 'none' } }}>
                    <Badge color="error" variant="dot" invisible={!hasNotificationAlert()}>
                        <Iconify icon="eva:menu-2-fill" />
                    </Badge>
                </IconButton>
                <Box sx={{ flexGrow: 1 }} />
                <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
                    <NotificationsPopover />
                    <AccountPopover />
                </Stack>
            </ToolbarStyle>
        </RootStyle>
    );
}
