import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps } from 'components/core/DataTable';
//@mui

//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
import { DeclaracionModuleService } from '../DeclaracionModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { Box } from '@mui/material';
import { DeclaracionTableItem } from './DeclaracionTableItem';

export type DeclaracionTableModel = {
    id: string;
    nombre             : string;
    numero_cite        : string;
    numero_declaracion : number;
    presento           : boolean;
    fecha_declaracion  : string;
    fecha_presentacion : string;
    adjuntos           : string;

    // para las columnas especiales
    actions: unknown;
};


export type DeclaracionTableRefProps = {
    refresh: (updateParams?: UpdateParams<DeclaracionTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<DeclaracionTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const numeroFilter: HeaderFilter = { type: 'text' };

type Props = {
    onAddClick: () => void;
    onViewClick: (idDeclaracion: string) => Promise<void>;
    onEditClick: (idDeclaracion: string) => Promise<void>;
};

export const DeclaracionTableComponent = (props: Props, ref: React.Ref<DeclaracionTableRefProps>): ReactElement => {
    const { onViewClick, onAddClick, onEditClick } = props;
    const notify = useNotify();
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<DeclaracionTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<DeclaracionTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnActions },
        { id: 'nombre', label: 'Nombre Completo', align: 'left', sort: false, width: 150 },
        { id: 'numero_cite', label: 'Numero Cite', align: 'left' },
        { id: 'numero_declaracion', label: 'Numero Declaracion', align: 'left', filter: numeroFilter },        
        { id: 'presento', label: 'Presento', align: 'left', render: renderColumnPresento },
        { id: 'fecha_declaracion', label: 'Fecha Declaracion', align: 'left' },
        { id: 'fecha_presentacion', label: 'Fecha Presentacion', align: 'left' },
        { id: 'adjuntos', label: 'Adjunto', align: 'left', render: renderColumnAdjunto },
    ];

    const handleUpdateTable = (params: UpdateParams<DeclaracionTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        DeclaracionModuleService.getTableDeclaracion(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<DeclaracionTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);
   
    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderMobileComponent(data: DeclaracionTableModel) {
        return (
            <DeclaracionTableItem
                data={data}
                refresh={() => tableRef.current?.refresh()}
                onEditClick={() => onEditClick(data.id)}
                onDeleteClick={() => {
                    DeclaracionModuleService.destroyDeclaracion(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success(result.msg);
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    function renderColumnPresento(tableModel: DeclaracionTableModel): ReactElement {
        const sino = tableModel.presento?'SI':'NO';
        return <Box sx={{ textAlign: 'left' }}>{sino}</Box>;
    }

    function renderColumnAdjunto(tableModel: DeclaracionTableModel): ReactElement {
        const sino = tableModel.adjuntos && (JSON.parse(tableModel.adjuntos).length > 0)?'SI':'NO';
        return <Box sx={{ textAlign: 'left' }}>{sino}</Box>;
    }
        
    function renderColumnActions(data: DeclaracionTableModel): ReactElement {
        return (
            <ActionColumn
                onViewClick={() => onViewClick(data.id)}
                onEditClick={() => onEditClick(data.id)}
                deleteMessage={
                    <div>
                        <div>¿Quiere eliminar el registro?</div>
                        <br />
                        <div>
                            <strong>Nombre: </strong> {data.numero_declaracion}
                        </div>
                    </div>
                }
                onDeleteClick={async () => {
                    return DeclaracionModuleService.destroyDeclaracion(data.id).then((result) => {
                        if (!result.success) return notify.error(result.msg);
                        notify.success('Declaracion eliminado exitosamente');
                        tableRef.current?.refresh();
                    });
                }}
            />
        );
    }

    
    return (
        <>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                onActionAddClick={onAddClick}
                mobileComponent={renderMobileComponent}
                vScroll
            />
        </>
    );
};

export const DeclaracionTable = forwardRef(DeclaracionTableComponent);
