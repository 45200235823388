import React, { ReactElement, useState, useRef } from 'react';
// @mui

// components
import { SolicitudVacacionFormDialog, SolicitudVacacionFormModel } from './components/SolicitudVacacionFormDialog';
import { SolicitudVacacionSolicitadaTable, SolicitudVacacionTableRefProps } from './components/SolicitudVacacionSolicitadaTable';
import { SectionNav } from 'components/core/SectionNav';
import { SolicitudVacacionPropiaTable } from './components/SolicitudVacacionPropiaTable';
//services
import { SolicitudVacacionModuleService } from './SolicitudVacacionModuleService';
import { useNotify } from 'services/notify';
import { useSession } from 'hooks/session';
import { SectionItem, SectionNavRefProps } from 'components/core/SectionNav/SectionNav';

import { Paper } from '@mui/material';


export const SolicitudVacacionModule = (): ReactElement => {
    const notify = useNotify();
    const authUser = useSession();
    const navRef = useRef<SectionNavRefProps>(null);

    const canApprove = authUser.permisos.approve;

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [formModel, setFormModel] = useState<SolicitudVacacionFormModel>();

    const handleClickAdd = async () => {
        setFormModel(undefined);
        setFormOpen(true);
    };

    const handleClickEdit = async (id_solicitud_vacacion: string) => {
        const solicitudVacacionFormResponse = await SolicitudVacacionModuleService.getSolicitudVacacionFormData(id_solicitud_vacacion);
        if (!solicitudVacacionFormResponse.success) return notify.error(solicitudVacacionFormResponse.msg);
        const newFormModel = solicitudVacacionFormResponse.data;
        setFormModel(newFormModel);
        setFormOpen(true);
    };

    const tableRef = useRef<SolicitudVacacionTableRefProps>(null);

    const sections: SectionItem[] = [];
    
    sections.push({
        id: '1',
        label: 'Propias',
        content: <SolicitudVacacionPropiaTable  ref={tableRef}  onAddClick={handleClickAdd} onEditClick={handleClickEdit} />
    });

    canApprove && sections.push({
        id: '2',
        label: 'Solicitadas',
        content: <SolicitudVacacionSolicitadaTable  ref={tableRef}  onAddClick={handleClickAdd} onEditClick={handleClickEdit} />
    });

    return (
        <>
            <SolicitudVacacionFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                }}
            />
            <Paper>                
                <SectionNav ref={navRef} sections={sections} />
            </Paper>
        </>
    );
};
