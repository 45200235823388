import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// theme
import ThemeProvider from 'theme';
// layouts
import DashboardLayout from 'layouts/dashboardLayout/DashboardLayout';

// pages
import Login from 'pages/Login';
import Register from 'pages/Register';
import NotFound from 'pages/Page404';
import DashboardApp from 'pages/DashboardApp';
import Notificacion from 'pages/Notificacion';
import Declaracion from 'pages/Declaracion';
import SolicitudVacacion from 'pages/SolicitudVacacion';
import Forgot from 'pages/Forgot';
import Reset from 'pages/Reset';
//paquetes
import packageInfo from '../package.json';
import { CacheBuster } from 'CacheBuster';
import * as routes from 'constants/routes';
//servicios
import PrivateRoute from 'services/PrivateRoute';
import { NotistackProvider } from 'services/notify';
import { getSessionInfoFromLocalStorage, isAuthenticated } from 'services/firebase/firebase';
import ValeCombustible from 'pages/ValeCombustible';
import Ficha from 'pages/Ficha';
import Proceso from 'pages/Proceso';
import ProcesoDetalle from 'pages/ProcesoDetalle';
import FichaAdmin from 'pages/FichaAdmin';
import Permiso from 'pages/Permiso';
import Sigapo from 'pages/Sigapo';
import SigapoDetalle from 'pages/SigapoDetalle';

const closeLoader = () => {
    const $loader = document.querySelector('.app-loader');
    if (!$loader) return;
    $loader.classList.add('app-loader--hide');
    setTimeout(() => $loader.parentElement?.removeChild($loader), 300);
};

const AppComponent = (): ReactElement => {

    const [location, setLocation] = useState<GeolocationPosition | null>(null);

    const load = async () => {
        const isLatestVersion = await CacheBuster.isLatestVersion();
        if (!isLatestVersion) return CacheBuster.updateToLastVersion();

        const isLogged = isAuthenticated();
        if (!isLogged) return closeLoader();

        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const fecha = new Date(position.timestamp);
            const newCenter = { lat: latitude, lng: longitude };
            //console.log('localizacion latitude,longitude : ',newCenter, fecha);
        }, function (e) {
            console.log(e);
        }, {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true,
        });

        // INI - Update dashboard menu
        const dashboardGridItem = localStorage.getItem('dashboardMenu');
        if (dashboardGridItem) return closeLoader();
        const sessionInfo = getSessionInfoFromLocalStorage();
        const modulos: string[] = sessionInfo?.modulos || [];
        localStorage.setItem('dashboardMenu', JSON.stringify(modulos));
        // END - Update dashboard menu

        return closeLoader();
    };

    const loadCallback = useCallback(load, []);

    useEffect(() => {
        loadCallback();
    }, [loadCallback]);

    return (
        <ThemeProvider>
            <BrowserRouter basename={packageInfo.homepage}>
                <NotistackProvider>
                    <Routes>
                        <Route path={routes.LOGIN} element={<Login />} />
                        <Route path={routes.REGISTER} element={<Register />} />
                        <Route path={routes.FORGOT} element={<Forgot />} />
                        <Route path={routes.RESET} element={<Reset />} />
                        <Route
                            path={routes.HOME}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<DashboardApp />}></Route>
                        </Route>
                        <Route
                            path={routes.DASHBOARD}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<DashboardApp />} />
                            <Route path={routes.DASHBOARD_NOTIFICACION} element={<Notificacion />} />
                        </Route>
                        <Route
                            path={routes.RRHH}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route path={routes.RRHH_DECLARACION} element={<Declaracion />} />
                            <Route path={routes.RRHH_SOLICITUD_VACACION} element={<SolicitudVacacion />} />
                            <Route path={routes.RRHH_FICHA} element={<Ficha />} />
                            <Route path={routes.RRHH_FICHA_ADMIN} element={<FichaAdmin />} />
                            <Route path={routes.RRHH_PERMISO} element={<Permiso />} />
                        </Route>
                        <Route
                            path={routes.GABI}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<DashboardApp />} />
                            <Route path={routes.GABI_SIGAPO} element={<Sigapo />} />
                            <Route path={routes.GABI_SIGAPO_DETALLE} element={<SigapoDetalle />} />
                        </Route>
                        <Route
                            path={routes.CONTRA}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<DashboardApp />} />
                            <Route path={routes.CONTRA_PROCESO} element={<Proceso />} />
                            <Route path={routes.CONTRA_PROCESO_DETALLE} element={<ProcesoDetalle />} />
                        </Route>
                        <Route
                            path={routes.BSSS}
                            element={
                                <PrivateRoute>
                                    <DashboardLayout />
                                </PrivateRoute>
                            }
                        >
                            <Route index element={<DashboardApp />} />
                            <Route path={routes.BSSS_VALE} element={<ValeCombustible />} />
                        </Route>
                        <Route path={routes.NOT_FOUND} element={<NotFound />} />
                    </Routes>
                </NotistackProvider>
            </BrowserRouter>
        </ThemeProvider>
    );
};

export const App = AppComponent;
