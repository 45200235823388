import React, { ReactElement } from 'react';
//@mui
import { Box, Button, Chip, DialogContent, DialogTitle, Divider, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';

import { NOTIFICATION_COLORS } from 'constants/colors';
import { STORAGE_URL } from 'config/app-config';

import { FileItem } from 'components/core/FormDialog';
import { NotificacionTableModel } from './NotificacionTable';
import { NotificacionTableModel as NotificacionSentTableModel } from './NotificacionSentTable';

export type LinkItem = {
    pathname: string;
    title: string;
    search?: string;
    hash?: string;
    state?: { [key: string]: string | number | boolean };
};

type Props = {
    data?: NotificacionTableModel | NotificacionSentTableModel;
    open: boolean;
    handleClose: (data?: NotificacionTableModel | NotificacionSentTableModel) => void;
};

export const NotificacionPreviewDialog = ({ data, open, handleClose }: Props): ReactElement => {
    function getColor(categoria: string): string {
        const colorItem = NOTIFICATION_COLORS.find((item) => item.categoria === categoria);
        return colorItem ? colorItem.value : '';
    }

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const truncateText = (text: string, max: number = 20) => {
        if (text.length <= max) return text;
        const middle = parseInt(`${max / 2}`);
        const a = text.substr(0, middle);
        const b = text.substr(text.length - middle);
        const truncated = `${a} ... ${b}`;
        return truncated;
    };
    const dataLink: LinkItem | null = data && data.link ? JSON.parse(data.link) : null;

    return (
        <div>
            <Dialog onClose={() => handleClose(data)} aria-labelledby="customized-dialog-title" open={open} fullScreen={fullScreen}>
                <DialogTitle sx={{ minWidth: theme.spacing(30), m: 0, p: 2 }} id="customized-dialog-title">
                    <Box display="flex" alignItems="center">
                        {data && <LabelImportantIcon sx={{ color: getColor(data.categoria) }} />}
                        <Box flexGrow={1} sx={{lineHeight: theme.spacing(2.5)}}>
                            {data && data.asunto}<br/><small style={{ fontSize: '12px', color: '#919EAB', fontWeight: 'lighter'}}>{data && data.categoria}</small>
                        </Box>
                        <Box>
                            <IconButton onClick={() => handleClose(data)}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                    </Box>
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom dangerouslySetInnerHTML={{ __html: data ? data.contenido : '' }} />
                    {data && data.adjuntos.length > 0 && data.adjuntos !== '[]' && (
                        <Box display="flex" alignItems="left" flexDirection="column">
                            <span>
                                <AttachFileIcon fontSize="large" />
                                Descargar el adjunto:{' '}
                            </span>
                            <div>
                                {JSON.parse(data.adjuntos).map((item: FileItem) => {
                                    return (
                                        <Chip
                                            key={item.id}
                                            label={truncateText(item.fileName.replace(`${item.id}-`, ''))}
                                            component="a"
                                            onClick={() => {
                                                window.open(`${STORAGE_URL}/${item.fileName}`);
                                            }}
                                            sx={{ maxWidth: 280 }}
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </div>
                        </Box>
                    )}
                    {dataLink && Object.keys(dataLink).length > 0 && (
                        <>
                            <Box p={1} />
                            <Divider />
                            <Box pt={2} display="flex" alignItems="center" justifyContent="center">
                                <Button variant="contained" color="primary">
                                    <SendIcon /> &nbsp; {dataLink.title}
                                </Button>
                            </Box>
                        </>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};
