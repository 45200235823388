import React, { ReactElement, useState, useRef, useEffect } from 'react';
// @mui
import { Box } from '@mui/material';

//services

import { FichaTableGeneral } from './component/FichaTableGeneral';
import { FichaTableRefProps } from './component/FichaTableGeneral';
import { FichaModel, FichaModuleService, MapPosition } from './FichaModuleService';
import ViewMapDialog from './component/ViewMapDialog';
import { DEFAULT_CENTER } from 'constants/enums';
import { useIsMounted } from 'hooks/useIsMounted';


export const FichaAdminModule = (): ReactElement => {
    const isMounted = useIsMounted();

    const [openViewMap, setOpenViewMap] = useState(false);
    const [mapCenter, setMapCenter] = useState(DEFAULT_CENTER);
    const [infoMessage, setInfoMessage] = useState("Marco aqui");

    const handleClickView = async (posicion: MapPosition, info: string) => {
        setMapCenter(posicion);
        setInfoMessage(info);
        setOpenViewMap(true);
    };

    const tableRef = useRef<FichaTableRefProps>(null);
    const [ficha, setFicha] = useState<FichaModel[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const resultFicha = await FichaModuleService.getFichas();
            if (!resultFicha || !resultFicha.success) return;
            const fichaResult = resultFicha.data.rows || [];
            if (isMounted()) {
                setFicha(fichaResult);
            }
        };
        fetchData();
    }, []);

    return (
        <Box>
            <ViewMapDialog
                open={openViewMap}
                onComplete={() => {
                    setOpenViewMap(false);
                }}
                posicion={mapCenter}
                info={infoMessage}
            />
            <FichaTableGeneral  ref={tableRef} ficha={ficha} onViewClick={handleClickView} />
        </Box>
    );
};
