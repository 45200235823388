import { useState, useRef } from 'react';

import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
// @mui
import { Box, Badge, Button, Divider, Typography, IconButton } from '@mui/material';
// components
import Iconify from '../../components/Iconify';
import MenuPopover from '../../components/MenuPopover';
import useLocalStorage from 'hooks/localstorage/useLocalStorage';

import { useNotify } from 'services/notify';

import { enviarNotificacionPushPrueba, registrarDispositivoParaNotificacionesPush } from 'services/firebase/firebase';

import { DEFAULT_NOTIFICATION_ALERT, NotificationAlert } from 'services/firebase/firebase';
import { DASHBOARD_NOTIFICACION } from 'constants/routes';

export default function NotificationsPopover() {
    const [open, setOpen] = useState(null);
    const anchorRef = useRef(null);
    const [loading, setLoading] = useState<boolean>(false);

    const notify = useNotify();

    const [notificationPermission] = useLocalStorage('notificationPermission', 'denied');
    const [deviceRegistered] = useLocalStorage('deviceRegistered', 'DESCONOCIDO');
    const [notificationAlert] = useLocalStorage<NotificationAlert>('notification_alert', DEFAULT_NOTIFICATION_ALERT);

    const getPushNotificationStatus = () => {
        return notificationPermission !== 'granted' ? 'BLOQUEADO' : deviceRegistered !== 'DESCONOCIDO' ? 'ACTIVADO' : 'DESACTIVADO';
    };

    const getNewNotificationsCount = (): string => {
        return notificationAlert.newNotifications > 9 ? '9+' : String(notificationAlert.newNotifications);
    };

    const hasNotificationAlert = (): boolean => {
        return !notificationAlert.isLatestVersion;
    };

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    const handleActivarNotificaciones = async () => {
        if (loading) return;
        setLoading(true);
        const dispositivoRegistrado = await registrarDispositivoParaNotificacionesPush();
        if (!dispositivoRegistrado) {
            setLoading(false);
            return notify.error('Notificaciones push: BLOQUEADO\n\nHabilite las notificaciones desde el navegador e inténtelo nuevamente.');
        }
        setLoading(false);
        notify.success('Notificaciones push: ACTIVADO');
        await enviarNotificacionPushPrueba();
    };

    return notificationPermission !== 'denied' ? (
        <>
            <IconButton ref={anchorRef} color={open ? 'warning' : 'inherit'} onClick={handleOpen} sx={{ width: 40, height: 40 }}>
                <Badge color="success" badgeContent={getNewNotificationsCount()} invisible={notificationAlert.newNotifications === 0}>
                    <Iconify icon="eva:bell-fill" width={20} height={20} />
                </Badge>
            </IconButton>

            <MenuPopover open={Boolean(open)} anchorEl={open} onClose={handleClose} sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="subtitle1" sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            Notificaciones: {getPushNotificationStatus()}
                            {deviceRegistered == 'DESCONOCIDO' && (
                                <Button variant="contained" size="small" color="success" onClick={handleActivarNotificaciones}>
                                    Activar
                                </Button>
                            )}
                        </Typography>

                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            Tienes {getNewNotificationsCount()} mensajes sin leer.
                        </Typography>
                    </Box>
                </Box>
                <Divider sx={{ borderStyle: 'dashed' }} />
                <Box sx={{ p: 1 }}>
                    <Button fullWidth disableRipple href={DASHBOARD_NOTIFICACION}>
                        Ver Todo
                    </Button>
                </Box>
            </MenuPopover>
        </>
    ) : (
        <></>
    );
}
