import React, { ReactElement, useState, useEffect } from 'react';
import { format, parse } from 'date-fns';
import es from 'date-fns/locale/es';
// @mui
import AddCommentIcon from '@mui/icons-material/AddComment';
// components
//services
import { useNotify } from 'services/notify';
import {useParams} from 'react-router-dom';
import { useIsMounted } from 'hooks/useIsMounted';

import { ProcesoModuleService } from '../proceso';
import { Box, Grid, Paper, Skeleton, Stack, Typography } from '@mui/material';

import Scrollbar from 'components/Scrollbar';
import { ENUM_IS_SUPERADMINISTRADOR } from 'constants/enums';
import ProcesoTimeline from 'modules/contra/procesodetalle/components/ProcesoTimeline';
import { ESTADO_C, ESTADO_D } from 'constants/colors';

import { useSession } from 'hooks/session';
import Iconify from 'components/Iconify';
import { ProcesoFormModel } from '../proceso/ProcesoModuleService';
import { getAvatarURL } from 'utils';

export type procesoDetalleProps = {
    id?: string;
    titulo                  : string;
    subtitulo               : string;
    descripcion             : string;
    tiempo                  : string;
    notificacion            : boolean;
    notificacion_solicitante: boolean;
    observacion             : string;
    fecha                   : string;
    type                    : string;
    fecha_envio             : string;
    imagen                  : string;
    estado                  : string;

    usuario_id  : string;
    proceso_id  : string;
}

type newItemProps = {
    props:{
        title: string;
        description: string;
        image: string;
        place: string;
        color: boolean;
        notificacion: boolean;
    }
};

const FORMAT = 'dd/MM/yyyy HH:mm';

export const ProcesoDetalleModule = (): ReactElement => {
    const notify = useNotify();
    const params = useParams();
    const authUser = useSession();
    const isMounted = useIsMounted();

    const es_super_administrador = authUser.roles === ENUM_IS_SUPERADMINISTRADOR
    const ID_USUARIO = authUser.id_usuario;

    const ID_PROCESO = params.id || '';

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [formOpen2, setFormOpen2] = useState<boolean>(false);
    const [actividadId, setActividadId] = useState<string>('');

    const [procesoData, setProcesoData] = useState<ProcesoFormModel>();
    const [procesoDetalleData, setProcesoDetalleData] = useState<procesoDetalleProps | null>();

    const [tableParams, setTableParams] = useState<procesoDetalleProps[]>();

    const [isloading, setIsloading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            setIsloading(true);

            const procesoFormResponse = await ProcesoModuleService.getProcesoFormData(ID_PROCESO);
            if (!procesoFormResponse.success) return notify.error(procesoFormResponse.msg);

            const newFormModel: ProcesoFormModel | null = procesoFormResponse.data || null;
            ProcesoModuleService.getTableProcesoDetalle(ID_PROCESO).then((result: any) => {
                setIsloading(false);
                if (!result || !result.success) return;
                const actividadRow: procesoDetalleProps[] = result.rows;
                const actividad = actividadRow && actividadRow.find((r) => r.notificacion) || null;
                setProcesoDetalleData(actividad);
                setActividadId(actividad?.id || '');
                setTableParams(result.rows || []);
            });

            if (isMounted()) {
                if (newFormModel !== null)
                    setProcesoData(newFormModel);
            }
        };
        if(ID_PROCESO) fetchData();
    }, [ID_PROCESO, formOpen2]);

    function NewsItem({ props }: newItemProps) {
        const { image, title, description, place, color, notificacion } = props;

        return (
            <Paper
                variant='elevation'
                elevation={4}
                sx={{ px: 2, py: 1, background: color?'#FFF3E0':'white', display: 'flex', justifyContent: 'space-between' }}
            >
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{background: 'primary'}}
                >
                    <Box
                        component="img"
                        alt={title}
                        src={getAvatarURL(image)}
                        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
                    />

                    <Box sx={{ minWidth: 240, flexGrow: 1 }}>
                        <Typography color="inherit" variant="subtitle2">
                            {title}
                        </Typography>
                        <Typography variant="body2" sx={{ color: 'inherit', fontSize: '11px' }}>
                            {description}
                        </Typography>
                        <Typography variant="caption" sx={{ color: 'text.secondary', fontSize: '10px' }}>
                            {place}
                        </Typography>
                    </Box>
                </Stack>
                {
                    notificacion && <Box
                                        sx={{
                                            color: "#E65100"
                                        }}
                                    >
                                        <Iconify icon={"carbon:notification-new"} width={24} height={24} />
                                    </Box>
                }
            </Paper>
        );
    }

    return (
        <>
            <Box mt={4}>
                <Paper elevation={3} sx={{backgroundColor: "#F4F6F8"}}>
                    <Grid container >
                        <Grid item xs={12} sm={6} md={6}>
                            <Box
                                sx={{
                                    minWidth: 240,
                                    minHeight: 90,
                                    flexGrow: 1,
                                    background: '#303F9F',
                                    color: 'white',
                                    p: 2,
                                    borderRadius: '4px' ,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderTopRightRadius: 0,
                                }}
                            >
                                <Typography color="inherit" variant="subtitle1" noWrap>
                                    {procesoData?.objeto_contratacion || ""}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'inherit', fontSize: '11px' }}>
                                    {format ( new Date(procesoData?.fecha_registro || new Date() ), FORMAT)}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'inherit', fontSize: '11px' }}>
                                    {procesoData?.cuce || ""}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <Box
                                sx={{
                                    minWidth: 240,
                                    minHeight: 90,
                                    flexGrow: 1,
                                    background: '#1976D2',
                                    color: 'white',
                                    p: 2,
                                    borderRadius: '4px' ,
                                    borderBottomLeftRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderTopLeftRadius: 0,
                                }}
                            >
                                <Typography color="inherit" variant="subtitle1">
                                    {procesoData?.modalidad_descripcion || ""}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'inherit', fontSize: '11px' }}>
                                    {procesoData?.codigo_interno_entidad || ""}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'inherit', fontSize: '11px' }}>
                                    {procesoData?.gestion || ""}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* PANEL CENTRAL */}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={7} md={7}>
                            <Box m={2}>
                                {isloading ? (
                                        <>
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                        </>
                                    ) : (
                                        tableParams && (
                                            <ProcesoTimeline
                                                title="Linea temporal del proceso"
                                                list={tableParams.map((item, index) => ({
                                                    id         : index,
                                                    title      : item.titulo,
                                                    subtitle   : item.subtitulo,
                                                    type       : ESTADO_D[item.estado],
                                                    color      : ESTADO_C[item.estado],
                                                    time       : item.fecha,
                                                    fecha_envio: item.fecha_envio,
                                                    state      : item.estado,
                                                    label      : 'fecha limite: '
                                                }))}
                                            />
                                        )
                                )}
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={5} md={5}>
                            <Box m={2}>
                                {
                                    isloading ? (
                                        <>
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                            <Skeleton sx={{ my: 3 }} />
                                        </>
                                    ) : (
                                            <Paper elevation={2} sx={{backgroundColor: "#F9FAFB"}}>
                                                <Box m={1}>
                                                    <Box p={2} pt={3} pb={0} display="flex" justifyContent="space-between">
                                                        <Typography variant="h6">Observaciones al proceso</Typography>
                                                    </Box>
                                                    <Box sx={{
                                                            overflowY: 'auto',
                                                            transition: 'height .5s, marginTop .5s',
                                                            height: 800,
                                                            pr: 2,
                                                            pb: 3
                                                         }}
                                                    >
                                                        <Scrollbar>
                                                            <Stack
                                                                spacing={2}
                                                                sx={{ p: 2 }}
                                                            >
                                                                {
                                                                    tableParams && tableParams.map((item: procesoDetalleProps) => (
                                                                        <NewsItem
                                                                            key={item.id}
                                                                            props={{
                                                                                title: item.titulo,
                                                                                description: item.observacion,
                                                                                image: item.imagen,
                                                                                place: item.tiempo,
                                                                                color: item.notificacion,
                                                                                notificacion: item.notificacion_solicitante
                                                                            }}
                                                                        />
                                                                    ))
                                                                }
                                                            </Stack>
                                                        </Scrollbar>
                                                    </Box>
                                                </Box>
                                            </Paper>
                                        )
                                }
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Box>
        </>
    );
};
