import React, { ReactElement, useState, useRef, useEffect } from 'react';
// @mui
import { Box, Button, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// components
import { NotificacionFormDialog, NotificacionFormModel } from './components/NotificacionFormDialog';
import { NotificacionModuleService } from './NotificacionModuleService';
import { 
            NotificacionSentTable, 
            TableHandlerProps as SentTableHandlerProps, 
            NotificacionTableModel as NotificacionSentTableModel 
        } from './components/NotificacionSentTable';
import { NotificacionPreviewDialog } from './components/NotificacionPreviewDialog';
import { NotificacionTableModel, NotificacionTable, TableHandlerProps } from './components/NotificacionTable';
import { useSession } from 'hooks/session';
import { SectionNav } from 'components/core/SectionNav';
import { SectionItem, SectionNavRefProps } from 'components/core/SectionNav/SectionNav';
import { DataSearch } from 'components/core/DataSearch';
import { ENUM_IS_SUPERADMINISTRADOR } from 'constants/enums';

export const NotificacionModule = (): ReactElement => {
    const authUser = useSession();
   
    const esAdministrador = authUser.roles === ENUM_IS_SUPERADMINISTRADOR;
    const canSendNotifications = authUser.permisos.send;

    const puedeEnviarNotificaciones = authUser && (esAdministrador || canSendNotifications);

    const [previewOpen, setPreviewOpen] = useState<boolean>(false);
    const [notificacionSelected, setNotificacionSelected] = useState<NotificacionTableModel | NotificacionSentTableModel>();

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [formModel] = useState<NotificacionFormModel>();
        
    const navRef = useRef<SectionNavRefProps>(null);

    function handleClickRedactar() {
        setFormOpen(true);
    }

    function handleClickNotification(item: NotificacionTableModel) {
        setNotificacionSelected(item);
        setPreviewOpen(true);
        const id = item?.id || '0';
        const itemBuzon: any = item as NotificacionTableModel;
        const ES_BUZON_NOTIFICACION: boolean = itemBuzon && itemBuzon.remitente;
        if (ES_BUZON_NOTIFICACION && !item.visto) {
            NotificacionModuleService.markNotificationAsView(id).then(() => {
                if (tableRef && tableRef.current) tableRef.current.refresh();
            });
        }
    }

    function handleClickNotificationSent(data: NotificacionSentTableModel) {
        setNotificacionSelected(data);
        setPreviewOpen(true);
    }

    const tableRef = useRef<TableHandlerProps>(null);
    const sentTableRef = useRef<SentTableHandlerProps>(null);

    const BuzonNotificaciones = <NotificacionTable onNotificationClick={handleClickNotification} ref={tableRef} />;
    const BuzonSalida = <NotificacionSentTable onNotificationClick={handleClickNotificationSent} ref={sentTableRef} />;

    const hanldePreviewClose = (item?: NotificacionTableModel | NotificacionSentTableModel) => {
        setPreviewOpen(false);
    };

    const handleClickSearch = (searchtext: string) => {
        if (!puedeEnviarNotificaciones && tableRef && tableRef.current) tableRef.current.search(searchtext);
        const tabSelected = String(navRef.current?.getTabSelected());
        if (tabSelected === '1') tableRef.current?.search(searchtext);
        if (tabSelected === '2') sentTableRef.current?.search(searchtext);
    };

    const sections: SectionItem[] = [];
    if (puedeEnviarNotificaciones) {
        sections.push({
            id: '1',
            label: 'Recibidas',
            content: BuzonNotificaciones
        });
        sections.push({
            id: '2',
            label: 'Enviadas',
            content: BuzonSalida
        });
    }

    
    return (
        <>
            <NotificacionPreviewDialog data={notificacionSelected} open={previewOpen} handleClose={hanldePreviewClose} />
            <NotificacionFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                    sentTableRef.current?.refresh();
                }}
            />
            <Paper >
                <Box display="flex" alignItems="center" justifyContent="flex-end">
                    <Box sx={{ p: 1, display: 'contents' }}>
                        {puedeEnviarNotificaciones && (
                            <Button 
                                variant="contained" 
                                disableElevation 
                                onClick={handleClickRedactar} 
                                color="secondary" 
                                startIcon={<AddIcon fontSize="small" />}
                                sx={{ mr: 2 }}
                            >
                                Redactar
                            </Button>
                        )}
                        <DataSearch onSearchClick={handleClickSearch} />
                    </Box>
                </Box>
                {puedeEnviarNotificaciones && <SectionNav ref={navRef} sections={sections} />}
                {!puedeEnviarNotificaciones && BuzonNotificaciones}
            </Paper>
        </>
    );
};
