import * as React from 'react';
import { useSearchParams } from 'react-router-dom'

/* mui */
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import * as routes from 'constants/routes';

import Container from '@mui/material/Container';
/* modules / Components */
import FormResetPassword from '../modules/forgot/components/FormResetPassword';
import { Box, Paper, Typography } from '@mui/material';

export default function Reset() {
    const [searchParams, setSearchParams] = useSearchParams()
    const ooCode = searchParams.get('oobCode');
    return (
        <Container component="main" maxWidth={false}>
            <CssBaseline />
            <Paper elevation={2} sx={{ m: 2}}>
                <Grid container display='flex' justifyContent='center'>
                    <Grid item xs={12} sm={12} md={6}>
                        <Box sx={{mt: 3, p:3}}>
                            <Typography variant="h5" align="center" gutterBottom>
                                Gobierno Autónomo Departamental de Oruro::GADOR
                            </Typography>
                            <Typography variant="body1" align="center" gutterBottom>
                                Te damos la bienvenida a nuestro espacio de encuentro virtual para fortalecer la experiencia de trabajo del Gobierno Autónomo Departamental de Oruro.
                            </Typography>
                            <Box
                                display="flex"
                                justifyContent="space-evenly"
                                alignItems="center"
                                flexDirection="row"
                                pt={3}
                            >
                                <Link href={routes.HOME}>
                                    <img src={'/logo512.png'} alt="Gador" width="120" />
                                </Link>
                                <Link href="https://oruro.gob.bo">
                                    <img src={'/static/images/maskable_icon_x512.png'} alt="GADOR - Oruro" width="120" />
                                </Link>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
                <FormResetPassword params={ooCode}/>
            </Paper>
        </Container>
    );
};
