import React, { ReactElement } from 'react';

import { useTheme, FormControl, TextField } from '@mui/material';

type Props = {
    name: string;
    label: string;
    value?: string;
    onChange: (name: string, value: string) => void;
};

export const TextFilter = ({ name, label, value, onChange }: Props): ReactElement => {
    const theme = useTheme();
    return (
        <FormControl
            sx={{
                width: '100%',
                margin: theme.spacing(0, 1),
                bgcolor: theme.palette.common.white
                //overflow: 'hidden'
            }}
        >
            <TextField
                name={name}
                label={label}
                value={value || ''}
                variant="outlined"
                size="small"
                sx={{
                    '& .MuiInputLabel-root': { color: '#919EAB' }
                }}
                onChange={(evt) => onChange(evt.target.name as string, evt.target.value as string)}
            />
        </FormControl>
    );
};
