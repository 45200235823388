import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { SigapoTableModel } from './components/SigapoTable';
import { SigapoFormModel } from './components/SigapoDialog';
import { SigapoDetalleTableModel } from './components/SigapoDetalleTable';

const getTableSigapo = async (queryParams?: QueryParams): Promise<BaseResponse<SigapoTableModel>> => {
    return BaseService.findAll<SigapoTableModel>('/setting/sigapo_table', queryParams);
};

const getTableSigapoDetalle = async (queryParams?: QueryParams): Promise<BaseResponse<SigapoDetalleTableModel>> => {
    return BaseService.findAll<SigapoDetalleTableModel>('/setting/sigapo_detalle_table', queryParams);
};

const getTableSigapoDetalleV2 = async (queryParams?: QueryParams): Promise<BaseResponse<SigapoDetalleTableModel>> => {
    console.log("🚀 ~ getTableSigapoDetalleV2 ~ queryParams:", queryParams)
    return BaseService.findAll<SigapoDetalleTableModel>('/setting/sigapo_detalle_table2', queryParams);
};

const getSigapoFormData = async (id: string): Promise<BaseResponse<SigapoFormModel>> => {
    return BaseService.request('get', `/setting/sigapo_form/${id}`);
};

export const SigapoModuleService = {
    getTableSigapo,
    getSigapoFormData,
    getTableSigapoDetalle,
    getTableSigapoDetalleV2,
};
