import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { firebase, auth } from 'services/firebase';
import { useNotify } from './notify';
import { isAuthenticated } from './firebase/firebase';
import { SessionProvider } from 'hooks/session';
import * as routes from 'constants/routes';

const PrivateRoute = ({ children }: any) => {
    const navigate = useNavigate();
    const notify = useNotify();

    useEffect(() => {
        const unsubscribe = firebase.auth.onAuthStateChanged((authUser: any) => {
            if (!authUser && isAuthenticated()) {
                notify.error('La sesión ha expirado.');
                auth.doSignOut().then(() => navigate('/'));
            }
        });
        return () => unsubscribe();
    }, [navigate, notify]);

    return firebase.isAuthenticated() ? <SessionProvider>{children}</SessionProvider> : <Navigate to={routes.LOGIN} replace />;
};

export default PrivateRoute;
