import React, { Ref, forwardRef } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

interface Props {
    children: React.ReactNode;
    title?: string;
    meta?: React.ReactNode;
}

const Page = ({ children, title = '', meta, ...other }: Props, ref: Ref<HTMLInputElement>) => (
    <>
        <Helmet>
            <title>{`${title} | .:: GADOR ::.`}</title>
            {meta}
        </Helmet>
        <Box 
            ref={ref} 
            {...other}
            sx={{
                backgroundImage: `url("/static/images/fondo3.png")`,
                backgroundPosition: 'bottom -15px right',
                backgroundSize: 'auto',
                backgroundRepeat  : 'no-repeat',
                overflow: 'hidden', 
                height: 1,
            }}
        >
            {children}
        </Box>
    </>
);

export default forwardRef(Page);
