import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { PermisoSolicitadaTableModel } from './PermisoSolicitadaTable';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ESTADO_F } from 'constants/colors';


type Props = {
    data: PermisoSolicitadaTableModel;
    refresh: () => void;
    onViewClick?: () => void;
    renderColumnChange: React.ReactNode;
};

export const PermisoSolicitadaTableItem = ({ data, refresh, onViewClick, renderColumnChange }: Props): ReactElement => {


    const color = 'white';
    const background = ESTADO_F[data.estado];

    const renderViewButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onViewClick ? onViewClick() : false)}>
                <VisibilityIcon />
            </IconButton>
        );
    };

    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
                <Box>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Tipo de Permiso:&nbsp;</b>{data.tipo_permiso}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Fechas :&nbsp;</b>{data.fechas}
                    </Typography>
                    <Typography variant="caption" display="inline" sx={{ lineHeight: 2 }}>
                        <b>Estado:&nbsp;</b>
                        <Box component="span" sx={{ color, background, py: 0.5, px: 0.5, borderRadius: '1.5rem', mr: 1 }}>
                            {data?.estado}
                        </Box>
                    </Typography>
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {renderColumnChange}
                {renderViewButton()}
            </CardActions>
        </Card>
    );
};
