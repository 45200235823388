import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { DeclaracionTableModel } from './components/DeclaracionTable';
import { DeclaracionFormModel } from './components/DeclaracionFormDialog';

const getTableDeclaracion = async (queryParams?: QueryParams): Promise<BaseResponse<DeclaracionTableModel>> => {
    return BaseService.findAll<DeclaracionTableModel>('/declaracion/declaracion_table', queryParams);
};

const createOrUpdateDeclaracion = async (data: DeclaracionFormModel): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/declaracion/declaracion_form`, data);
};

const getDeclaracionFormData = async (id: string): Promise<BaseResponse<DeclaracionFormModel>> => {
    return BaseService.request('get', `/declaracion/declaracion_form/${id}`);
};

const destroyDeclaracion = async (id: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/declaracion/declaracion_item/${id}`);
};

const setActiveDeclaracion = async (id_declaracion: string, activo: boolean): Promise<BaseResponse<DeclaracionFormModel>> => {
    const data = { activo };
    return BaseService.request('post', `/declaracion/declaracion_table/${id_declaracion}`, data);
};

const getAllPersonal = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/personal/personals', queryParams);
};

export const DeclaracionModuleService = {
    getTableDeclaracion,
    createOrUpdateDeclaracion,
    getDeclaracionFormData,
    setActiveDeclaracion,
    getAllPersonal,
    destroyDeclaracion
};
