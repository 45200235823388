import React, { ReactElement, useState } from 'react';
// @mui
import { Box, Stack, Link, Card, Button, Divider, CardContent, IconButton, Typography, CardHeader, Tooltip, Dialog, DialogContent } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { FichaDetalleTableModel } from './FichaTableGeneral';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ESTADO_C } from 'constants/colors';
import { getAvatarURL } from 'utils';
type Props = {
    data: FichaDetalleTableModel;
    onViewClick?: () => void;
    refresh: () => void;
};

export const FichaTableItem = ({ data, onViewClick }: Props): ReactElement => {

    const renderViewButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onViewClick ? onViewClick() : false)}>
                <VisibilityIcon />
            </IconButton>
        );
    };

    const renderViewState = () => {
        const background = ESTADO_C[data.estado];
        return (
            <Typography
                align="center"
                variant="body2"
                sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'right' }}
            >
                <IconButton size="small">
                    <CheckCircleIcon style={{ fontSize: '14px', color: background }} />
                </IconButton>
            </Typography>
        );
    };

    const [open, setOpen] = useState(false);
    type RenderViewDialogProps = {
        open: boolean;
        onClose: () => void;
        imageUrl: string;
    };
    const RenderViewDialog: React.FC<RenderViewDialogProps> = ({ open, onClose, imageUrl }) => {
        return (
          <Dialog open={open} onClose={onClose}>
            <DialogContent>
              <Box
                component="img"
                alt="Imagen ampliada"
                src={imageUrl}
                sx={{
                  width: '100%', // Ajusta el tamaño según tus necesidades
                  height: 'auto',
                  borderRadius: 1.5,
                }}
                onClick={onClose}
              />
            </DialogContent>
          </Dialog>
        );
    };

    const handleImageClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Card variant="outlined" sx={{ mb: 1}}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 1 } }}>
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Box
                        component="img"
                        alt={data.nombre}
                        src={getAvatarURL(data.avatar)}
                        sx={{ width: 48, height: 48, borderRadius: 1.5, flexShrink: 0 }}
                        onClick={handleImageClick}
                    />
                    {RenderViewDialog({ open, onClose: handleClose, imageUrl: getAvatarURL(data.avatar) })}
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="caption" display="block" sx={{ color: 'text.tertiary', width: 160 }} noWrap>
                            {data.nombre}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ color: 'text.tertiary' }}>
                            <b>Item / CI:&nbsp;</b>{data.num_item +' / '+data.ci}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ color: 'text.secondary', fontSize: '10px' }}>
                        {data.lugar}{' :: '}{data.fecha_ficha}
                        </Typography>
                    </Box>
                    <Box sx={{ flexShrink: 0 }}>
                        {renderViewState()}
                        {renderViewButton()}
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};
