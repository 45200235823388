import * as React from 'react';
import * as routes from 'constants/routes';
/* mui */
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
/* modules / Components */
import FormForgotPassword from '../modules/forgot/components/FormForgotPassword';
import Page2 from 'components/Page2';

const RootStyle = styled('div')(({ theme }) => ({
    backgroundImage: `url("/static/images/fondo-xs.jpg")`,
    backgroundPosition: 'top center',
    backgroundSize: 'contain',
    backgroundRepeat  : 'no-repeat',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        backgroundSize: 'cover',
        backgroundImage: `url("/static/images/fondo-lg.jpg")`,
    }
}));

export default function Forgot() {
    return (
            <Page2 title="Olvido">
                <RootStyle>
                    <Grid container spacing={2} >
                        <Grid item xs={12} sm={12} md={12}>
                            <Box sx={{mt: 12, p:3}}>
                                <Typography variant="h5" align="center" gutterBottom sx={{ lineHeight: 1.1 }}>
                                    Gobierno Autónomo Departamental de Oruro::GADOR
                                </Typography>
                                <Typography variant="body2" align="justify" gutterBottom>
                                    Te damos la bienvenida a nuestro espacio de encuentro virtual para fortalecer la experiencia de trabajo del Gobierno Autónomo Departamental de Oruro.
                                </Typography>
                                <Box
                                    display="flex"
                                    justifyContent="space-evenly"
                                    alignItems="center"
                                    flexDirection="row"
                                    pt={1}
                                >
                                    <Link href={routes.HOME}>
                                        <img src={'/logo512.png'} alt="GADOR" width="120" />
                                    </Link>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <FormForgotPassword />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <Box display="flex" justifyContent="center" alignItems="center">
                                <Button
                                    variant="contained"
                                    color="error"
                                    sx={{ my: 2 }}
                                    href={routes.LOGIN}
                                >
                                    Volver a Inicio de Sesion
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </RootStyle>
            </Page2>
    );
};
