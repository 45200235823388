import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { FichaDetalleTableModel } from './component/FichaTableGeneral';
import { FichaFormDataResponse } from './component/FichaClient';
import { v4 as uuid } from 'uuid';

export type FichaModel = {
    id    : string;
    codigo: string;
    nombre: string;
    estado: boolean;
};

export type MapPosition = {
    lat: number;
    lng: number;
};

type MapItem = {
    position: MapPosition;
};

export type DataFicha = {
    position: MapPosition;
    avatar: string;
};

export type MapData = {
    markers: MapItem[];
};

export type SetAvatarResponse = {
    avatarUrl: string;
};

const getTableFicha = async (queryParams?: QueryParams): Promise<BaseResponse<FichaDetalleTableModel>> => {
    return BaseService.findAll<FichaDetalleTableModel>('/ficha_detalle/ficha_detalle_table', queryParams);
};

const getMapOptions = async (): Promise<BaseResponse<any>> => {
    return BaseService.request('get', '/ficha/mapa');
};

const getFichas = async (): Promise<BaseResponse<any>> => {
    return BaseService.request('get', '/ficha/fichas');
};

const getFichaCliente = async (): Promise<BaseResponse<FichaFormDataResponse>> => {
    return BaseService.request('get', '/ficha/fichas_cliente');
};

const setMarcado = async (posicion: MapPosition, avatar: string): Promise<BaseResponse<unknown>> => {
    const data = {posicion: posicion, avatar: avatar };
    return BaseService.request('post', `/ficha_detalle/ficha_detalle_form`, data);
};

const setPosicion = async (data: MapPosition): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/ficha_detalle/ficha_detalle_form`, data);
};

const setAvatarFicha = async (fileItem: File): Promise<BaseResponse<SetAvatarResponse>> => {
    // Upload image
    const FILE_ID = uuid();
    const fileName = `${FILE_ID}-${fileItem.name}`;
    const formdata = new FormData();
    formdata.append('fileItem', fileItem, fileName);
    formdata.append('fileName', fileName);
    const uploadResult = await BaseService.uploadFile(fileItem, fileName);
    if (!uploadResult.success) {
        return BaseService.sendError({ msg: uploadResult.msg });
    }
    localStorage.setItem('fileName', String(uploadResult.data?.name));

    // Build result
    const result = {
        msg: 'Foto subida correctamente',
        data: { avatarUrl: String(uploadResult.data?.file) },
    };
    return BaseService.sendSuccess(result);
};

export const FichaModuleService = {
    setMarcado,
    setPosicion,
    getFichas,
    getFichaCliente,
    getMapOptions,
    setAvatarFicha,
    getTableFicha
};

