import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { DeclaracionModule } from 'modules/rrhh/declaracion';

// ----------------------------------------------------------------------

export default function Declaracion() {
    return (
        <Page title="Declaracion">
            <Container maxWidth="xl">
                <Typography variant="h4">Declaracion</Typography>
                <DeclaracionModule />
            </Container>
        </Page>
    );
}
