import React, { ReactElement } from 'react';

import LabelImportantIcon from '@mui/icons-material/LabelImportant';

import { NOTIFICATION_COLORS } from 'constants/colors';
import { NotificacionTableModel } from '../NotificacionSentTable';

type Props = {
    data: NotificacionTableModel;
};

export const NotificacionSentTagColumn = ({ data }: Props): ReactElement => {
    return (
        <div>
            <LabelImportantIcon sx={{ color: '#74B220' }} />
        </div>
    );
};
