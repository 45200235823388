import React, { ReactElement, useState, useRef, useEffect } from 'react';
import {useParams} from 'react-router-dom';
// @mui

// components

//services
import { DEFAULT_CENTER } from 'constants/enums';
import { useIsMounted } from 'hooks/useIsMounted';
import { useNotify } from 'services/notify';
import { SigapoTableRefProps } from './components/SigapoTable';
import { SigapoDetalleTable } from './components/SigapoDetalleTable';
import { SigapoFormModel } from './components/SigapoDialog';

export const SigapoDetalleModule = (): ReactElement => {
    const params = useParams();
    const notify = useNotify();
    const isMounted = useIsMounted();

    const [fichaData, setFichaData] = useState<SigapoFormModel>();

    const tableRef = useRef<SigapoTableRefProps>(null);

    const ID_SIGAPO = params.id || '';

    const handleClickView = async (id: any) => {

    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
           /*  const fichaDetalleFormResponse = await FichaModuleService.getFichaFormData(ID_SIGAPO);
            if (!fichaDetalleFormResponse.success) return notify.error(fichaDetalleFormResponse.msg);
            const newFormModel: FichaFormModel | null = fichaDetalleFormResponse.data || null;
            if (isMounted()) {
                if (newFormModel !== null)
                    setFichaData({
                                        nombre: newFormModel.nombre || '',
                                        codigo: newFormModel.codigo,
                                        inicio: newFormModel.inicio,
                                        final: newFormModel.final,
                                    });

            } */
        };
        if(ID_SIGAPO) fetchData();
    }, [ID_SIGAPO]);

    return (
        <>
            <SigapoDetalleTable
                ref={tableRef}
                onViewClick={handleClickView}
                data={fichaData || null}
            />
        </>
    );
};
