import React, { FormEvent, useState } from 'react';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import * as routes from 'constants/routes';
// material
import { Link, Stack, Checkbox, TextField, IconButton, InputAdornment, FormControlLabel } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { auth } from 'services/firebase';
import { useNotify } from 'services/notify';

// ----------------------------------------------------------------------

export default function LoginForm() {
    const navigate = useNavigate();
    const notify = useNotify();

    const [showPassword, setShowPassword] = useState(false);

    const LoginSchema = Yup.object().shape({
        email: Yup.string().email('El correo electrónico debe ser una dirección válida').required('Correo electronico es requerido'),
        password: Yup.string().required('Se requiere contraseña')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: (values) => {
            auth.doSignIn(values.email.trim(), values.password.trim()).then((result) => {
                if (!result.success) {
                    setSubmitting(false);
                    return notify.error(result.msg);
                }
                // Redirect to HomePage
                navigate(routes.DASHBOARD);
            });
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, setSubmitting, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="email"
                        label="Dirección de correo electrónico"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        variant="filled"
                        sx={{
                            backgroundColor: 'white'
                        }}
                    />
                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Contraseña"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                        variant="filled"
                        sx={{
                            backgroundColor: 'white'
                        }}
                    />
                </Stack>
                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                    <FormControlLabel control={<Checkbox {...getFieldProps('remember')} checked={values.remember} color='error'/>} label="Recordarme" />
                    <Link component={RouterLink} variant="subtitle2" to={routes.FORGOT} underline="hover" color="error">
                        ¿Se te olvidó tu contraseña?
                    </Link>
                </Stack>
                <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} color="error">
                    Ingresar
                </LoadingButton>
            </Form>
        </FormikProvider>
    );
}
