export const ENUM_CATEGORIA = [
    { value: 'circular', label: 'Circular' },
    { value: 'comunicado', label: 'Comunicado' },
    { value: 'evento', label: 'Evento' },
    { value: 'reunion', label: 'Reunion' }
];

export const ENUM_GENERO = [
    { value: 'MASCULINO', label: 'Masculino' },
    { value: 'FEMENINO', label: 'Femenino' }
];

export const ENUM_AFP = [
    { value: 'FUTURO', label: 'Futuro' },
    { value: 'PREVISION', label: 'Prevision' },
    { value: 'GESTORA PUBLICA', label: 'Gestora Publica' }
];

export const ENUM_TIPO = [
    { value: 'REGULAR', label: 'Regular' },
    { value: 'EVENTUAL', label: 'Eventual' },
];

export const ENUM_SINO = [
    { value: 1, label: 'si' },
    { value: 0, label: 'no' },
];

export const ENUM_ESTADO_CIVIL = [
    { value: 'CASADO(A)', label: 'CASADO(A)' },
    { value: 'SOLTERO(A)', label: 'SOLTERO(A)' },
    { value: 'DIVORCIADO(A)', label: 'DIVORCIADO(A)' },
    { value: 'VIUDO(A)', label: 'VIUDO(A)' },
];

export const ENUM_CIUDAD = [
    { value: 'LA PAZ', label: 'La Paz' },
    { value: 'CHUQUISACA', label: 'Chuquisaca' },
    { value: 'COCHABAMBA', label: 'Cochabamba' },
    { value: 'ORURO', label: 'Oruro' },
    { value: 'POTOSI', label: 'Potosí' },
    { value: 'TARIJA', label: 'Tarija' },
    { value: 'SANTA CRUZ', label: 'Santa Cruz' },
    { value: 'PANDO', label: 'Pando' },
    { value: 'BENI', label: 'Beni' }
]

export const ENUM_GESTION = [
    { value: '2020', label: '2020' },
    { value: '2021', label: '2021' },
    { value: '2022', label: '2022' },
    { value: '2023', label: '2023' },
];

export const ENUM_GESTION_PERIODO = [
    { value: '2020', label: '2020', caption: '2019 - 2020' },
    { value: '2021', label: '2021', caption: '2020 - 2021' },
    { value: '2022', label: '2022', caption: '2021 - 2022' },
    { value: '2023', label: '2023', caption: '2022 - 2023' },
    { value: '2024', label: '2024', caption: '2023 - 2024' },
    { value: '2025', label: '2025', caption: '2024 - 2025' },
    { value: '2026', label: '2026', caption: '2025 - 2026' },
    { value: '2027', label: '2027', caption: '2026 - 2027' },
    { value: '2028', label: '2028', caption: '2027 - 2028' },
    { value: '2029', label: '2029', caption: '2028 - 2029' },
    { value: '2030', label: '2030', caption: '2029 - 2030' },
];

export const ENUM_ESTADOS = [
    { value: 'PENDIENTE', label: 'PENDIENTE' },
    { value: 'SUPERIOR', label: 'SUPERIOR' },
    { value: 'APROBADO', label: 'APROBADO' },
    { value: 'RECHAZADO', label: 'RECHAZADO' }
];

export const ENUM_ESTADO = [
    'PENDIENTE',
    'SUPERIOR',
    'RECHAZADO',
    'APROBADO'
];

export const ENUM_ESTADO_JEFE = [
    'PENDIENTE',
    'APROBADO_JEFE',
    'RECHAZADO',
];

export const ENUM_PERMISOS = [
    { value: 'create', label: 'Crear' },
    { value: 'read', label: 'Leer' },
    { value: 'edit', label: 'Editar' },
    { value: 'remove', label: 'Borrar' },
    { value: 'send', label: 'Enviar' },
    { value: 'download', label: 'Descarga' },
    { value: 'approve', label: 'Aprobar' },
    { value: 'lock', label: 'Reset' },
];

export const ENUM_TIPO_SOLICITUD = [
    { value: 'PROGRAMADA', label: 'Programada' },
    { value: 'SOLICITADA', label: 'Solicitada' }
];

export const ENUM_TIPO_PERMISO = [
    { value: 'PARTICULAR', label: 'Particular' },
    { value: 'LICENCIA', label: 'Licencia' },
    { value: 'COMISION', label: 'Comision' },
    //{ value: 'OLVIDO', label: 'Olvido' }
];

export const ENUM_IS_SUPERADMINISTRADOR = 'superadministrador';
export const ENUM_IS_JEFE = 'jefe';

export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_2 = 'HH:mm:ss';

export const STORAGE_LOCAL = '/static/mock-images/avatars';

export const DEFAULT_CENTER = { lat: -17.969624502296277, lng: -67.11449045679467 };
export const DEFAULT_ZOOM = 17;
export const COMPRESION_IMAGEN_PERFIL = 0.85;
