import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, Chip, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { SigapoTableModel } from './SigapoTable';

import VisibilityIcon from '@mui/icons-material/Visibility';

type Props = {
    data: SigapoTableModel;
    refresh: () => void;
    onDetalleClick?: (id: string) => void;
};

export const SigapoTableItem = ({ data, refresh, onDetalleClick }: Props): ReactElement => {

    return (
        <Card variant="outlined" sx={{ mb: 1 }} onClick={() => (onDetalleClick ? onDetalleClick(data.id) : false)}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
                <Stack direction="row" spacing={1}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'right', mb: 0.5 }}>
                            <span style={{fontSize: '18px', backgroundColor: 'yellow'}}>{data.hoja_de_ruta}</span>
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'left', mb: 0.5, mt: 0.5 }}>
                            <b>FECHA SALIDA:&nbsp;</b>{data.fecha_hora_salida}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, mt: 0.5 }}>
                            <b>PROCEDENCIA:&nbsp;</b>{data.procedencia}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, mt: 0.5, mb: 0.5 }}>
                            <b>DERIVADO A:&nbsp;</b>{data.derivado_a}
                        </Typography>
                        <Typography variant="caption" display="block" sx={{ lineHeight: 1, textAlign: 'justify', mb: 0.5 }}>
                            <b>INSTRUCCION:&nbsp;</b>{data.instruccion}
                        </Typography>
                    </Box>
                    <Box sx={{ flexShrink: 0 }}>

                    </Box>
                </Stack>
            </CardContent>
        </Card>
    );
};
