import React, { useState, FormEvent } from 'react';

import * as routes from 'constants/routes';
import { auth } from "services/firebase";
/* mui */
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';


type State = {
    password: string;
    passwordConfirm: string;
    showPassword: boolean;
    showPasswordConfirm: boolean;
};
type Props = {
    params: any;
}
const FormResetPassword: React.FC<Props> = ({ params }: any) => {
    
    const [values, setValues] = React.useState<State>({
        password: '',
        passwordConfirm: '',
        showPassword: false,
        showPasswordConfirm: false,
    });
    const [isDirty, setIsDirty] = useState(false);
    const [error, setError] = useState<any>('');
    const [isSending, setIsSending] = useState(false);
    const showPasswordError = isDirty && values.password === '';
    const showPasswordConfirmError =
        isDirty && (values.passwordConfirm === '' || values.password !== values.passwordConfirm);
    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
        setIsDirty(true);
    };

    const handleShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleShowPasswordConfirm = () => {
        setValues({ ...values, showPasswordConfirm: !values.showPasswordConfirm });
    };

    const handleResetSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();        
        const ooCode = params;
        auth
            .doPasswordUpdate(values.password, ooCode || '')
            .then((response: any) => {
                setIsSending(true);
            })
            .catch((error: any) => {
                setError(error);
            });
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleResetSubmit}>
            <Box p={3} m={1} display="flex"
                        justifyContent="center"
                        alignItems="center"
                        flexDirection="column">
                <Typography variant="h5">Restablecimiento de contraseña</Typography>
                {isSending ? (
                    <>
                        <p>Su contraseña fue cambiada con exito, puede volver a la pantalla principal.</p>
                        <FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                href={routes.LOGIN}
                            >
                                Inicio
                            </Button>
                        </FormControl>
                    </>
                ) : (
                    <>
                        <p>Crear nueva contraseña.</p>
                        <FormControl variant="filled" error={showPasswordError}>
                            <InputLabel htmlFor="outlined-adornment-password">
                                Contraseña
                                {showPasswordError ? <span>&nbsp;&nbsp;*</span> : ''}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={values.password}
                                onChange={handleInputChange('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}                                           
                                            edge="end"
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                sx={{ my: 2}}
                            />
                            {showPasswordError ? (
                                <FormHelperText id="component-error-text">El campo es obligatorio *</FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <FormControl variant="filled" error={showPasswordConfirmError}>
                            <InputLabel htmlFor="outlined-adornment-password-confirm">
                                Confirmar Contraseña
                                {showPasswordConfirmError ? <span>&nbsp;&nbsp;*</span> : ''}
                            </InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password-confirm"
                                type={values.showPasswordConfirm ? 'text' : 'password'}
                                value={values.passwordConfirm}
                                onChange={handleInputChange('passwordConfirm')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle passwordConfirm visibility"
                                            onClick={handleShowPasswordConfirm}
                                            edge="end"
                                        >
                                            {values.showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {showPasswordConfirmError ? (
                                <FormHelperText id="component-error-text-password">
                                    El campo no coincide y es obligatorio *
                                </FormHelperText>
                            ) : (
                                ''
                            )}
                        </FormControl>
                        <Box mt={1}>
                            {error && (
                                <Typography variant="caption">
                                    {error.message}
                                </Typography>
                            )}
                        </Box>
                        <FormControl>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                disabled={showPasswordError || showPasswordConfirmError || values.password === ''}
                            >
                                Guardar
                            </Button>
                        </FormControl>
                    </>
                )}
            </Box>
        </form>
    );
};

export default FormResetPassword;
