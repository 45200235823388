import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
import { DataTable, TableHeader, UpdateParams, OnUpdateOptions, DataTableRefProps } from 'components/core/DataTable';
import { NotificacionTagColumn } from './columns/NotificacionTagColumn';

import { QueryParams } from 'services/base/Types';
import { NotificacionModuleService } from 'modules/notificacion/NotificacionModuleService';
import { NotificacionTableItem } from './NotificacionTableItem';
import { useIsMounted } from 'hooks/useIsMounted';

// Este modelo representa al buzon de notificaciones
export type NotificacionTableModel = {
    id: string; // id_buzon_notificaciones
    categoria: string;
    remitente: string;
    asunto: string;
    fecha: string;
    visto: boolean;

    // datos adicionales para mostrar en el formulario
    contenido: string;
    adjuntos: string;
    link: string | null;

    // para las columnas especiales
    actions: unknown;
};

export type TableHandlerProps = {
    refresh: () => void;
    search: (searchText: string) => void;
};

const tableParamsInitialize: UpdateParams<NotificacionTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const DEFAULT_NO_VISTO_COLOR = '#d6f3c9';

type Props = {
    onNotificationClick: (buzon_notificaciones: NotificacionTableModel) => void;
};

export const NotificacionTableComponent = (props: Props, ref: React.Ref<TableHandlerProps>): ReactElement => {
    const isMounted = useIsMounted();
    const [tableParams, setTableParams] = useState<UpdateParams<NotificacionTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    const tableHeaders: TableHeader<NotificacionTableModel>[] = [
        {
            id: 'categoria',
            label: 'Etiqueta',
            align: 'left',
            render: renderColumnTag,
            onCellClick: handleClickCell,
            width: 80
        },
        { id: 'remitente', label: 'Remitente', align: 'left', onCellClick: handleClickCell },
        { id: 'asunto', label: 'Asunto', align: 'left', onCellClick: handleClickCell },
        { id: 'fecha', label: 'Fecha', align: 'center', onCellClick: handleClickCell }
    ];

    const handleUpdateTable = (params: UpdateParams<NotificacionTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        NotificacionModuleService.getTableNotificaciones(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<NotificacionTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    function handleClickCell(data: NotificacionTableModel) {
        props.onNotificationClick(data);
    }

    useEffect(() => {
        tableRef.current?.refresh();
    }, [tableRef]);

    function renderColumnTag(data: NotificacionTableModel): ReactElement {
        return <NotificacionTagColumn data={data} />;
    }

    function renderMobileComponent(data: NotificacionTableModel) {
        return <NotificacionTableItem data={data} onNotificationClick={() => props.onNotificationClick(data)} />;
    }

    const tableRefHandler = () => ({
        refresh: () => tableRef.current?.refresh(),
        search: (searchText: string) => tableRef.current?.refresh({ ...tableParams, searchText })
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    return (
        <DataTable
            ref={tableRef}
            headers={tableHeaders}
            updateParams={tableParams}
            onUpdate={handleUpdateTable}
            showActionBar={false}
            rowStyles={(row: NotificacionTableModel): any => {
                return { background: !row.visto ? DEFAULT_NO_VISTO_COLOR : '' };
            }}
            mobileComponent={renderMobileComponent}
            vScroll
        />
    );
};

export const NotificacionTable = forwardRef(NotificacionTableComponent);
