import React, { ReactElement, useState } from 'react';
import { Box, Card, CardContent, IconButton, Typography } from '@mui/material';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';
import { NotificacionTableModel } from './NotificacionSentTable';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'components/core/ConfirmDialog';
import { NOTIFICATION_COLORS } from 'constants/colors';

type Props = {
    data: NotificacionTableModel;
    onDeleteClick: () => void;
    onNotificationClick: (data: NotificacionTableModel) => void;
};

export const NotificacionSentTableItem = ({ data, onDeleteClick, onNotificationClick }: Props): ReactElement => {
    function getColor(categoria: string): string {
        return NOTIFICATION_COLORS.filter((item) => item.categoria === categoria)[0].value;
    }

    function renderCategoria(data: NotificacionTableModel): ReactElement {
        return <LabelImportantIcon sx={{ color: getColor(data.categoria) }} />;
    }

    const [open2, setOpen2] = useState<boolean>(false);
    const renderDeleteButton = () => {
        return (
            <>
                <ConfirmDialog
                    title={'Confirmar'}
                    message={
                        <div>
                            <div>¿Quiere eliminar la notificación?</div>
                            <br />
                            <div>
                                <strong>Asunto: </strong> {data.asunto}
                                <br />
                                <strong>Contenido: </strong> {data.contenido}
                            </div>
                        </div>
                    }
                    open={open2}
                    onAccept={async () => {
                        setOpen2(false);
                        if (onDeleteClick) return onDeleteClick();
                    }}
                    onCancel={() => setOpen2(false)}
                />
                <IconButton color="primary" component="span" size="small" onClick={() => setOpen2(true)}>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };

    return (
        <Card elevation={3}>
            <CardContent>
                <Box onClick={() => onNotificationClick(data)}>
                    <Box display="flex">
                        {renderCategoria(data)}
                        <Box p={0.5} />
                        <Typography variant="body1" display="block">
                            <strong>{data.asunto}</strong>
                        </Typography>
                    </Box>
                    <Box py={1} px={0.5}>
                        <Typography variant="caption" display="block">
                            <strong>Enviado el: </strong> {data.fecha}
                        </Typography>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Box />
                    <Box display="flex">{renderDeleteButton()}</Box>
                </Box>
            </CardContent>
        </Card>
    );
};
