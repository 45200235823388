import React, { ReactElement, useEffect, useRef, useState } from 'react';

/* Material UI */
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

/* component */
import { useNotify } from 'services/notify';
import { FichaModuleService } from '../FichaModuleService';
import { useSession } from 'hooks/session';
//@mui
import { LoadingButton } from '@mui/lab';

type DialogProps = {
    openDialog: boolean;
    handleDialogClose: () => void;
};

const FichaUploadDialog = ({ openDialog, handleDialogClose }: DialogProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isLoadingPhoto, setIsLoadingPhoto] = useState<boolean>(false);
    const [isLoadingAceptar, setIsLoadingAceptar] = useState<boolean>(true);

    const notify = useNotify();
    const authUser = useSession();
    const AUTH_ID = authUser.id_usuario;

    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [stream, setStream] = useState<MediaStream | null>(null);
    const [imageData, setImageData] = useState<string | null>(null);

    useEffect(() => {
        activateCamera();

        return () => {
          if (stream) {
            stream.getTracks().forEach(track => track.stop());
          }
        };
      }, []);

    const activateCamera = async () => {
        try {
          const userMedia = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } });
          setStream(userMedia);
          if (videoRef.current) {
            videoRef.current.srcObject = userMedia;
          }
        } catch (error) {
            console.error('Error accessing camera:', error);
            notify.error(String(error));
            setIsLoadingAceptar(false);
        }
      };

    const captureImage = () => {
        if (videoRef.current) {
            const canvas = document.createElement('canvas');
            canvas.width = videoRef.current.videoWidth;
            canvas.height = videoRef.current.videoHeight;
            const context = canvas.getContext('2d');

            if (context) {
            context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
            canvas.toBlob((blob) => {
                if (blob) {
                    const fileItem = new File([blob], `-${AUTH_ID}.jpg`, { type: 'image/jpg' });
                    setIsLoading(true);
                    FichaModuleService.setAvatarFicha(fileItem).then((result: any)=> {
                        setIsLoading(false);
                        if (!result.success) return notify.error(result.msg);
                        const newAvatarURL = String(result.data?.avatarUrl);
                        setImageData(newAvatarURL);
                        setIsLoadingPhoto(true);
                        setIsLoadingAceptar(false);
                        return notify.success(result.msg);
                    });
                }
              }, 'image/jpg');
            }
        }
    };

    return (
        <div>
            <form noValidate autoComplete="off" encType="multipart/form-data">
                <Dialog open={openDialog} aria-labelledby="form-dialog-title">
                    <DialogContent dividers sx={{ p: 0 }}>
                        <div style={{ maxWidth: '100%', position: 'relative', paddingTop: '80.00%' }}>
                            <video ref={videoRef} autoPlay playsInline style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }} />
                        </div>
                        {imageData && <img src={imageData} alt="Captured" />}
                    </DialogContent>
                    <DialogActions sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-around'
                                    }}
                    >
                        <LoadingButton
                            size="small"
                            color="primary"
                            onClick={captureImage}
                            loading={isLoading}
                            loadingPosition="start"
                            startIcon={<PhotoCameraIcon />}
                            variant="contained"
                            disabled={isLoadingPhoto}
                        >
                            <span>Tomar Foto</span>
                        </LoadingButton>
                        <Button size="small" variant='contained' onClick={() => handleDialogClose()} disabled={!!isLoadingAceptar}>
                            Aceptar
                        </Button>
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
};


export default FichaUploadDialog;
