import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page2 from '../components/Page2';
import { FichaAdminModule } from 'modules/rrhh/ficha/FichaAdminModule';
// ----------------------------------------------------------------------

export default function FichaAdmin() {
    return (
        <Page2 title="Fichas">
            <Container maxWidth="xl">
                <Typography variant="h4">Fichas Admin</Typography>
                <FichaAdminModule />
            </Container>
        </Page2>
    );
}
