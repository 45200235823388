import * as React from 'react';
import { SessionInfo } from 'services/firebase/auth';
import { getSessionInfoFromLocalStorage } from 'services/firebase/firebase';

const initialValue: SessionInfo = {
    id_usuario: '',
    genero    : '',
    avatar    : '',
    nombre    : '',
    permisos  : {},
    roles     : '',
    celular   : '',
    modulos   : [],
    username  : ''
};

export const SessionContext = React.createContext<SessionInfo>(initialValue);

type Props = {
    children: React.ReactNode;
};

export const SessionProvider = ({ children }: Props): React.ReactElement => {
    const sessionInfo: SessionInfo = getSessionInfoFromLocalStorage() || initialValue;
    return <SessionContext.Provider value={sessionInfo}>{children}</SessionContext.Provider>;
};
