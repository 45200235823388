import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { FormGroup, FormDialog, FormValue } from 'components/core/FormDialog';
import { DeclaracionModuleService } from '../DeclaracionModuleService';
import { useNotify } from 'services/notify';

export type DeclaracionFormModel = {
    id?: number;
    nombre?            : string;
    numero_cite        : string;
    numero_declaracion : number;
    presento           : boolean;
    fecha_declaracion  : string;
    fecha_presentacion : string;
    adjuntos?          : string;
    personal_id        : string;

    _createdBy?: string;
    _createdAt?: string;
    _updatedBy?: string;
    _updatedAt?: string;
};

type Props = {
    open: boolean;
    formModel?: DeclaracionFormModel;
    onComplete: () => void;
};

export type PersonalOptionsFormModel  = {
    id: string;
    nombre: string;
}

export const DeclaracionFormDialog = ({ open, formModel, onComplete }: Props): ReactElement => {
    const notify = useNotify();
    const initialDate =  new Date().toString();
    const formLayout: FormGroup<DeclaracionFormModel>[] = [        
        {
            title: '',
            grid: [
                [
                    { name: 'numero_cite', label: 'Numero Cite', type: 'text' },
                    { name: 'numero_declaracion', label: 'Numero Declaracion', type: 'text' }
                ],
                [{ name: 'fecha_declaracion', label: 'Fecha Declaracion', type: 'datetime' }],
                [{ name: 'fecha_presentacion', label: 'Fecha Presentacion', type: 'datetime' }],                
                [{ name: 'adjuntos', label: 'Adjunto', type: 'dropzone' }]
            ]
        },
    ];

    const validationSchema = yup
        .object({
            numero_cite: yup.string().required(),
            numero_declaracion: yup.number().required(),
            fecha_declaracion: yup.string(),
            fecha_presentacion: yup.string(),
            adjuntos: yup.string(),
        })
        .defined();

    const handleSubmit = async (formData: FormValue) => {
        const result = await DeclaracionModuleService.createOrUpdateDeclaracion(formData as unknown as DeclaracionFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success(result.msg);
        return onComplete();
    };

    const handleCancel = () => {
        onComplete();
    };

    const zeroValues: DeclaracionFormModel = {
        numero_cite        : '',
        numero_declaracion : 0,
        presento           : false,
        fecha_declaracion  : initialDate,
        fecha_presentacion : initialDate,
        adjuntos           : '[]',
        personal_id        : '',
    };

    const newFormModel = formModel && {
        id: formModel.id,
        numero_cite        : formModel.numero_cite,
        numero_declaracion : formModel.numero_declaracion,
        presento           : formModel.presento,
        fecha_declaracion  : formModel.fecha_declaracion,
        fecha_presentacion : formModel.fecha_presentacion,
        adjuntos           : formModel?.adjuntos || '[]',
        personal_id        : formModel.personal_id,
    };

    return (
        <FormDialog
            addTitle="Agregar declaracion"
            editTitle="Editar declaracion"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={newFormModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
            isEdit={typeof formModel !== 'undefined'}
        />
    );
};
