import * as Yup from 'yup';
import { useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as routes from 'constants/routes';
// material
import { Stack, TextField, IconButton, InputAdornment } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { isNumberValidTest, isPhoneNumberValidTest } from 'components/core/FormDialog/yup-tests';
//service
import { auth } from 'services/firebase';
import { useNotify } from 'services/notify';
import { RegisterProps } from 'services/firebase/auth';

// ----------------------------------------------------------------------
const DATE_FORMAT = 'dd/MM/yyyy';

export default function RegisterForm() {
  const navigate = useNavigate();
  const notify = useNotify();


  const [showPassword, setShowPassword] = useState(false);
  const [showRepassword, setShowRepassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
        email: Yup.string().email('El correo electrónico debe ser una dirección válida').required('Correo electronico es requerido'),
        password: Yup.string().required('Se requiere contraseña'),
        repassword: Yup.string().required('Se requiere contraseña').oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
        ci: Yup.string().required().test(isNumberValidTest()),
        telefono: Yup.string().required().test(isPhoneNumberValidTest()),
  });

  const formik = useFormik({
    initialValues: {
        email: '',
        password: '',
        repassword: '',
        ci: '',
        telefono: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
        const data: RegisterProps = {
            email: values.email.trim(),
            password: values.password.trim(),
            repassword: values.repassword.trim(),
            ci: values.ci.trim(),
            telefono: values.telefono.trim(),
        }
        auth.doCreateUser(data).then((result) => {
            if (!result.success) {
                setSubmitting(false);
                return notify.error(result.msg);
            }
            //localStorage.setItem('phoneNumber', values.telefono.trim());
            // Redirect to login
            navigate(routes.LOGIN, { replace: true });
            return notify.success(result.msg+"\n"+"Ya puedes iniciar sesion en la aplicacion.");
        });
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, setSubmitting, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={1}>
          <Stack direction={{ xs: 'column', sm: 'column' }} spacing={1}>
            <TextField
                fullWidth
                type="email"
                label="Correo electronico"
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                variant="filled"
                sx={{
                    backgroundColor: 'white'
                }}
            />
            <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Contraseña"
                {...getFieldProps('password')}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                    </InputAdornment>
                ),
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
                variant="filled"
                sx={{
                    backgroundColor: 'white'
                }}
            />
            <TextField
                fullWidth
                type={showRepassword ? 'text' : 'password'}
                label="Reingresar Contraseña"
                {...getFieldProps('repassword')}
                InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowRepassword((prev) => !prev)}>
                        <Iconify icon={showRepassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                    </InputAdornment>
                ),
                }}
                error={Boolean(touched.repassword && errors.repassword)}
                helperText={touched.repassword && errors.repassword}
                variant="filled"
                sx={{
                backgroundColor: 'white',
                }}
            />
            <TextField
                fullWidth
                label="CI"
                {...getFieldProps('ci')}
                error={Boolean(touched.ci && errors.ci)}
                helperText={touched.ci && errors.ci}
                variant="filled"
                sx={{
                    backgroundColor: 'white'
                }}
            />
            <TextField
                fullWidth
                label="Telefono"
                {...getFieldProps('telefono')}
                error={Boolean(touched.telefono && errors.telefono)}
                helperText={touched.telefono && errors.telefono}
                variant="filled"
                sx={{
                    backgroundColor: 'white'
                }}
            />
          </Stack>
          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting} color="error">
            Registrar
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
