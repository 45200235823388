import React, { ReactElement } from 'react';

// @mui
import { Button, Box, useTheme, useMediaQuery, CircularProgress } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import RefreshIcon from '@mui/icons-material/Refresh';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import AddIcon from '@mui/icons-material/Add';

// components
import { ActionBarProps, TableHeader, MyTableModel } from './Types';
import { DataSearchV3 } from '../DataSearch/DataSearchV3';

export const ActionBarV3 = ({
    updateParams,
    headers,
    loading,
    onFilterClick,
    onResfreshClick,
    onSearchClick,
    onSearchTextChange,
    onActionAddClick,
    onDownloadClick
}: ActionBarProps): ReactElement => {

    const hasHeaderFilters = headers.filter((column: TableHeader<MyTableModel>) => column.filter).length > 0;
    //const theme = useTheme();
    //const direccion = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'flex-end',
                my: 1
            }}
        >
            <Box component="span" display='flex'>
                {onActionAddClick && (
                    <Button variant="contained" color="secondary" disableElevation onClick={onActionAddClick} size="small" sx={{ mx: 0.5 }}>
                        <AddIcon fontSize='small'/>
                        Agregar
                    </Button>
                )}
                {onFilterClick && (
                    <Button variant="contained" color="warning" onClick={onFilterClick} disabled={!hasHeaderFilters} disableElevation size="small" sx={{ mx: 0.5 }}>
                        <FilterListIcon fontSize='small'/>
                    </Button>
                )}
                {onResfreshClick && (
                    <Button variant="contained" color="warning" disableElevation onClick={onResfreshClick} size="small" sx={{ mx: 0.5 }}>
                        <RefreshIcon fontSize='small'/>
                    </Button>
                )}
            </Box>
            <Box
                display='flex'
                justifyContent='space-between'
            >
                {onDownloadClick && (
                    <Button disabled={loading} variant="contained" onClick={onDownloadClick} sx={{ mx: 1, alignSelf: 'center' }} size="small" color="error">
                        {loading ? <CircularProgress size={24} style={{ color: 'white' }} />:<PdfIcon fontSize='small'/>}
                    </Button>
                )}
                {onSearchClick && <DataSearchV3 updateParams={updateParams} onSearchClick={onSearchClick} onSearchTextChange={onSearchTextChange} />}
            </Box>
        </Box>
    );
};
