import React, { ReactElement, useEffect, useState } from 'react';
import * as yup from 'yup';
import { SelectOption, FormGroup, FormDialog, FormValue } from 'components/core/FormDialog';
import { SolicitudVacacionModuleService } from '../SolicitudVacacionModuleService';
import { useNotify } from 'services/notify';
import { ENUM_TIPO_SOLICITUD } from 'constants/enums';
import { useIsMounted } from 'hooks/useIsMounted';

export type SolicitudVacacionFormModel = {
    id?: number;
    dias          : string;
    tipo          : string;
    fecha_salida  : string;
    fecha_retorno : string;
    vacacion_id   : string;

    _createdBy?: string;
    _createdAt?: string;
    _updatedBy?: string;
    _updatedAt?: string;
};

type Props = {
    open: boolean;
    formModel?: SolicitudVacacionFormModel;
    onComplete: () => void;
};

export type SolicitudOptionsFormModel  = {
    id    : string;
    nombre: string;
    dias  : number;
}

export const SolicitudVacacionFormDialog = ({ open, formModel, onComplete }: Props): ReactElement => {
    
    const notify = useNotify();
    const isMounted = useIsMounted(); 
    const [diaMax, setDiaMax] = useState<number>(0);
    const solicitudOptions: SelectOption[] = ENUM_TIPO_SOLICITUD;
    const initialDate =  new Date().toString();

    const [vacaciones, setVacacion] = useState<SolicitudOptionsFormModel[]>([]);
    const vacacionesOptions: SelectOption[] = vacaciones.map((item: SolicitudOptionsFormModel) => ({ value: item.id || '', label: item.nombre }));

    const formLayout: FormGroup<SolicitudVacacionFormModel>[] = [
        {
            title: '',
            grid: [
                [
                    { 
                        name: 'vacacion_id', 
                        label: 'Vacacion por Gestion', 
                        type: 'autocomplete', 
                        options: vacacionesOptions,
                        onChange: (value, formik) => {
                            formik.setFieldValue('vacacion_id', value);
                            const dia: number = vacaciones.find((v) => v.id===value)?.dias || 0;
                            setDiaMax(dia);
                        }, 
                    },
                    { name: 'dias', label: 'Dias', type: 'text' }
                ],
                [{ name: 'tipo', label: 'Tipo de Solicitud', type: 'select', options: solicitudOptions }],
            ]
        },
        {
            title: '',
            grid: [
                [{ name: 'fecha_salida', label: 'Fecha Salida', type: 'datetime' }],
                [{ name: 'fecha_retorno', label: 'Fecha Retorno', type: 'datetime' }]
            ]
        }
    ];

    const whenVacacion = (vacacion_id: string, schema: yup.NumberSchema) => {
        return schema.test({
            name: 'is-max-valid',
            message: `Solo de queda ${diaMax} dias pendiendes`,
            test: (value) => {
                return Number(value) >= 1 && Number(value) <= diaMax;
            },
        });
    };

    const validationSchema = yup
        .object({
            dias         : yup.number().required().when('vacacion_id', whenVacacion),
            tipo         : yup.string().required(),
            fecha_salida : yup.date().required(),
            fecha_retorno: yup.date().min(yup.ref('fecha_salida'), 'La fecha retorno no puede ser anterior a la fecha salida'),
            vacacion_id  : yup.string().required(),
        })
        .defined();

    const handleSubmit = async (formData: FormValue) => {
        const result = await SolicitudVacacionModuleService.createOrUpdateSolicitudVacacion(formData as unknown as SolicitudVacacionFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success(result.msg);
        return onComplete();
    };

    const handleCancel = () => {
        onComplete();
    };

    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            const resultVacacion = await SolicitudVacacionModuleService.getAllVacacion();
            if (!resultVacacion || !resultVacacion.success) return;
            const newVacacions = resultVacacion.rows || [];
            
            if (isMounted()) {
                setVacacion(newVacacions);
                const dia: number = vacaciones.reduce((total, item) => total + item.dias, 0) || 0;
                setDiaMax(dia);
            };            
        };
        if (open) {
            fetchData();
        }
    }, [open, isMounted, formModel]);
    
    const zeroValues: SolicitudVacacionFormModel = {
        dias          : '',
        tipo          : '',
        fecha_salida  : initialDate,
        fecha_retorno : initialDate,
        vacacion_id   : '',
    };

    const newFormModel = formModel && {
        id: formModel.id,
        dias          : formModel.dias,
        tipo          : formModel.tipo,
        fecha_salida  : formModel.fecha_salida,
        fecha_retorno : formModel.fecha_retorno,
        vacacion_id   : formModel.vacacion_id,
    };

    return (
        <FormDialog
            addTitle="Agregar Solicitud Vacacion"
            editTitle="Editar Solicitud Vacacion"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={newFormModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
            isEdit={typeof formModel !== 'undefined'}
        />
    );
};


