import React, { ReactElement, useState } from 'react';
/* Material UI */
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import StreetviewIcon from '@mui/icons-material/Streetview';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography/Typography';
import{ useTheme }from '@mui/material';

import { MapPosition } from '../FichaModuleService';
import GoogleMapFicha from './GoogleMapFicha';

type Props = {
    open: boolean;
    onComplete: () => void;
    posicion: MapPosition;
    info?: string;
};

const ViewMapDialog = ({ open, onComplete, posicion, info="Marco aqui" }: Props) => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const clientHeight = matches ? window.outerHeight : window.innerHeight;

    const mapContainerStyle = {
        height: `${clientHeight-100}px`,
        width: `100%`,
        margin: 'auto'
    };

    const renderDialogTitle = (): ReactElement => {
        return (
            <DialogTitle id="form-dialog-title">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box alignItems="center" display="flex" overflow="hidden">
                        <StreetviewIcon color="info" sx={{ mr: 2 }} />
                        <Typography noWrap>Detalle de Marcado</Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={onComplete}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </Box>
            </DialogTitle>
        );
    };

    const renderDialogContent = () => {
        return (
            <DialogContent dividers style={{ padding: 0 }}>
                <Box>
                    <GoogleMapFicha mapCenter={{ lat: posicion.lat, lng: posicion.lng }} info={info} mapContainerStyle={mapContainerStyle}/>
                </Box>
            </DialogContent>
        );
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onComplete} aria-labelledby="form-dialog-title" fullScreen={true}>
                {renderDialogTitle()}
                {renderDialogContent()}
            </Dialog>
        </React.Fragment>
    );
};

export default ViewMapDialog;
