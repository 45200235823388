import React, { ReactElement } from 'react';
import * as yup from 'yup';
import { FormGroup, FormDialog, FormValue } from 'components/core/FormDialog';
import { NotificacionModuleService } from '../NotificacionModuleService';
import { useNotify } from 'services/notify';

export type NotificacionFormModel = {
    id_notificacion?: string;
    asunto: string;
    categoria: string;
    contenido: string;
    adjuntos?: string;
};

type Props = {
    open: boolean;
    formModel?: NotificacionFormModel;
    onComplete: () => void;
};

export const NotificacionFormDialog = ({
    open,
    formModel,
    onComplete,
}: Props): ReactElement => {
    const notify = useNotify();
   
    const formLayout: FormGroup<NotificacionFormModel>[] = [
        {
            title: '',
            grid: [
                [{ name: 'asunto', label: 'Asunto', type: 'text' }],
                [{ name: 'categoria', label: 'Categoria', type: 'text' }],
                [{ name: 'contenido', label: 'Mensaje', type: 'editor' }],                
                [{ name: 'adjuntos', label: 'Adjuntos', type: 'dropzone' }],                
            ]
        }
    ];

    const validationSchema = yup.object({
            asunto: yup.string().required(),
            categoria: yup.string().required(),          
            contenido: yup.string().required(),
            adjuntos: yup.string(),
        }).defined();

    const handleSubmit = async (formData: FormValue) => {
        const result = await NotificacionModuleService.sendNotification(formData as NotificacionFormModel);
        if (!result.success) return notify.error(result.msg);
        notify.success('Notificación exitosa');
        return onComplete();
    };

    const handleCancel = () => {
        onComplete();
    };

    const zeroValues: NotificacionFormModel = {
        asunto: '',
        categoria: '',
        contenido: '',
        adjuntos: '[]',
    };

    

    return (
        <FormDialog
            addTitle="Enviar notificación"
            open={open}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            initialValues={formModel || zeroValues}
            formLayout={formLayout}
            validationSchema={validationSchema}
        />
    );
};
