import React, { ReactElement, useEffect, useState } from 'react';
import { parse as dateParse } from 'date-fns';
import { es } from 'date-fns/locale';
import { useTheme, FormControl, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

type Props = {
    name: string;
    label: string;
    value?: string;
    onChange: (name: string, value: string) => void;
};

const DEFAULT_FORMAT = 'dd/MM/yyyy';
const DEFAULT_DISABLE_PAST = false;

export const DateFilter = ({ name, label, value, onChange }: Props): ReactElement => {
    const [innerValue, setInnerValue] = useState<Date | null>(value ? dateParse(value, DEFAULT_FORMAT, new Date()) : null);
    const theme = useTheme();
    useEffect(() => {
        setInnerValue(value ? dateParse(value, DEFAULT_FORMAT, new Date()) : null);
    }, [value, DEFAULT_FORMAT]);

    return (
        <FormControl
            sx={{
                width: '100%',
                margin: theme.spacing(0, 1),
                bgcolor: theme.palette.common.white
                //overflow: 'hidden'
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es}>
                <DatePicker
                    label={label}
                    disabled={false}
                    disablePast={DEFAULT_DISABLE_PAST}
                    inputFormat={DEFAULT_FORMAT}
                    value={value? value : innerValue}
                    onChange={(newValue) => onChange(name, newValue as string)}
                    renderInput={(params) => <TextField  variant="outlined"  size="small" {...params} />}
                />                
            </LocalizationProvider>
        </FormControl>
    );
};
