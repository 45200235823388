import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, IconButton, Typography } from '@mui/material';
import { SolicitudVacacionTableModel } from './SolicitudVacacionSolicitadaTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'components/core/ConfirmDialog';
import { format, parse } from 'date-fns';
import es from 'date-fns/locale/es';
import { ESTADO_B } from 'constants/colors';

const FORMAT = 'EEEE, dd MMMM yyyy hh:mm a';

type Props = {
    data: SolicitudVacacionTableModel;
    refresh: () => void;
    renderColumnChange: React.ReactNode;
};

export const SolicitudVacacionSolicitadaTableItem = ({ data, renderColumnChange }: Props): ReactElement => {
    const parseDateSalida = parse(data?.fecha_salida, "dd/MM/yyyy hh:mm a", new Date());
    const parseDateRetorno = parse(data?.fecha_retorno, "dd/MM/yyyy hh:mm a", new Date());
    const fecha_salida = parseDateSalida;
    const fecha_retorno = parseDateRetorno;
           
    const color = 'white';
    const background = ESTADO_B[data.estado];
    
    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ py: 2, px: 1, '&:last-child': { pb: 1 } }}>
                <Box>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Nombre:&nbsp;</b>{data.nombre}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Cargo:&nbsp;</b>{data.cargo}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Area:&nbsp;</b>{data.area}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Gestión:&nbsp;</b>{data.gestion}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mb: 0.5 }}>
                        <b>Tipo:&nbsp;</b>{data.tipo}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1 }}>
                        <b>Dias:&nbsp;</b>{data.dias}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1.5, mt: 1 }}>
                        <b>Fecha Salida:&nbsp;</b>{fecha_salida ? format(fecha_salida, FORMAT, { locale: es }).toLocaleString() : ''}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1.5 }}>
                        <b>Fecha Retorno:&nbsp;</b>{fecha_retorno ? format(fecha_retorno, FORMAT, { locale: es }).toLocaleString() : ''}
                    </Typography>
                    <Typography variant="caption" display="inline" sx={{ lineHeight: 2 }}>
                        <b>Estado:&nbsp;</b>
                        <Box component="span" sx={{ color, background, py: 0.5, px: 0.5, borderRadius: '1.5rem', mr: 1 }}>
                            {data?.estado}                            
                        </Box>
                        {renderColumnChange}
                    </Typography>
                </Box>
            </CardContent>
        </Card>
    );
};
