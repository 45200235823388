import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { format } from 'date-fns';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, DataTableV3 } from 'components/core/DataTable';

//@mui
import { Box, Button, Dialog, DialogContent, FormControl, Grid, IconButton, InputLabel, Paper, Typography } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Search from '@mui/icons-material/Search';
//services
import { QueryParams } from 'services/base/Types';
//model
import { SigapoModuleService } from '../SigapoModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';

export type SigapoTableV2Model = {
    id: string;
    destino: string;
    hoja_de_ruta: string;
    nro: string;
    lugar: string;
    instruccion: string;
    objeto: string;
    fecha_recepcion: string;
    fecha_salida: string;
    permanencia: string;
    //
    destino_general?: unknown;
    tarea_instruccion?: unknown;
};

export type SigapoTableRefPropsV2 = {
    refresh: () => void;
};

const tableParamsInitialize: UpdateParams<SigapoTableV2Model> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};

const hojaderutaFilter: HeaderFilter = { type: 'text' };

type State = {
    texto: string;
};

type Props = {
    onViewClick?: (id: any) => Promise<void>;
};

export const SigapoTableV2Component = (props: Props, ref: React.Ref<SigapoTableRefPropsV2>): ReactElement => {
    const { onViewClick } = props;
    const isMounted = useIsMounted();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [values, setValues] = useState<State>({ texto: '' });

    const [tableParams, setTableParams] = useState<UpdateParams<SigapoTableV2Model>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<SigapoTableV2Model>[] = [
        { id: 'nro', label: 'Nro', align: 'left', sort: false },
        { id: 'destino', label: 'Destino', align: 'left', sort: false },
        { id: 'hoja_de_ruta', label: 'Hoja de Ruta', align: 'left', sort: false, filter: hojaderutaFilter },
        { id: 'lugar', label: 'Lugar', align: 'left', sort: false },
        { id: 'instruccion', label: 'Tarea o Instruccion', align: 'left', sort: false },
        { id: 'objeto', label: 'Objecto', align: 'left', sort: false },
        { id: 'fecha_recepcion', label: 'Fecha Recepcion Destino', align: 'left', sort: false },
        { id: 'fecha_salida', label: 'Fecha Salida Destino', align: 'left', sort: false },
        { id: 'permanencia', label: 'Permanencia', align: 'left', sort: false },
    ];

    const handleUpdateTable = async (params: UpdateParams<SigapoTableV2Model>) => {
        Object.assign(params,{ filters: { hoja_de_ruta: values.texto } });
        setIsLoading(true);
        if (!isMounted()) return;
        SigapoModuleService.getTableSigapoDetalleV2(params as QueryParams).then((result) => {
            setIsLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<SigapoTableV2Model> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) setTableParams(newTableParams);
        });
    };

    const tableRefHandler = () => ({
        refresh: () => handleUpdateTable(tableParams),
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    const handleUpdateTableCallback = useCallback(handleUpdateTable, []);

    useEffect(() => {
        async function init() {
            if (!isMounted()) return;
            if (isMounted()) handleUpdateTableCallback(tableParamsInitialize);
        }
        init();
    }, [handleUpdateTableCallback, isMounted]);

    const handleInputChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickSearch = () => {
        const newParams = {
            ...tableParams,
            filters: {
                ...tableParams.filters,
                hoja_de_ruta: values.texto
            },
        };
        setTableParams(newParams);
        tableRef.current?.refresh();
    };

    function renderMobileComponent(data: SigapoTableV2Model) {
        return (<></>);
    }

    return (
        <Paper>
            <FormControl sx={{ m: 1, width: 150 }} size="small">
                <InputLabel id="demo-select-small">Gestion:</InputLabel>
                <OutlinedInput
                    id="demo-select-small"
                    type="text"
                    value={values.texto}
                    onChange={handleInputChange('texto')}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle visibility"
                                onClick={handleClickSearch}
                                edge="end"
                            >
                                <Search />
                            </IconButton>
                        </InputAdornment>
                    }
                />
            </FormControl>
            <DataTableV3
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                hiddenPagination={true}
                showActionBar={false}
                vScroll
            />
        </Paper>
    );
};

export const SigapoTableV2 = forwardRef(SigapoTableV2Component);
