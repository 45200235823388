import React, { ReactElement, useState, useRef } from 'react';
// @mui

// components
import { PermisoFormDialog, PermisoFormModel } from './components/PermisoFormDialog';
import { PermisoPropiaTable, PermisoPropiaTableRefProps } from './components/PermisoPropiaTable';
import PermisoDialog from './components/PermisoDialog';
//services
import { PermisoModuleService } from './PermisoModuleService';
import { useNotify } from 'services/notify';
import { useSession } from 'hooks/session';

import { Paper } from '@mui/material';
import { SectionItem, SectionNav, SectionNavRefProps } from 'components/core/SectionNav/SectionNav';
import { PermisoSolicitadaTable } from './components/PermisoSolicitadaTable';

export const PermisoModule = (): ReactElement => {
    const notify = useNotify();
    const authUser = useSession();
    const navRef = useRef<SectionNavRefProps>(null);

    const canApprove = authUser.permisos.approve;

    const [formOpen, setFormOpen] = useState<boolean>(false);
    const [openPermisoForm, setOpenPermisoForm] = useState(false);
    const [formModel, setFormModel] = useState<PermisoFormModel>();

    const handleClickView = async (id_permiso: string) => {
        const permisoFormResponse = await PermisoModuleService.getPermisoFormData(id_permiso);
        if (!permisoFormResponse.success) return notify.error(permisoFormResponse.msg);
        const newFormModel = permisoFormResponse.data;
        setFormModel(newFormModel);
        setOpenPermisoForm(true);
    };

    const handleClickAdd = async () => {
        setFormModel(undefined);
        setFormOpen(true);
    };

    const handleClickEdit = async (id_permiso: string) => {
        const permisoFormResponse = await PermisoModuleService.getPermisoFormData(id_permiso);
        if (!permisoFormResponse.success) return notify.error(permisoFormResponse.msg);
        const newFormModel = permisoFormResponse.data;
        setFormModel(newFormModel);
        setFormOpen(true);
    };

    const tableRef = useRef<PermisoPropiaTableRefProps>(null);

    const sections: SectionItem[] = [];

    sections.push({
        id: '1',
        label: 'Propias',
        content: <PermisoPropiaTable  ref={tableRef}  onViewClick={handleClickView} onAddClick={handleClickAdd} onEditClick={handleClickEdit} />
    });

    canApprove && sections.push({
        id: '2',
        label: 'Solicitadas',
        content: <PermisoSolicitadaTable  ref={tableRef}  onViewClick={handleClickView} onAddClick={handleClickAdd} onEditClick={handleClickEdit} />
    });

    return (
        <>
            <PermisoFormDialog
                open={formOpen}
                formModel={formModel}
                onComplete={() => {
                    setFormOpen(false);
                    tableRef.current?.refresh();
                }}
            />
            <PermisoDialog
                open={openPermisoForm}
                onComplete={() => {
                    setOpenPermisoForm(false);
                }}
                formModel={formModel}
            />
            <Paper>
                <SectionNav ref={navRef} sections={sections} />
            </Paper>
        </>
    );
};
