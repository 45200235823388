import React, { ReactElement, useRef } from 'react';
// @mui

// components
import { ProcesoTable, ProcesoTableRefProps } from './components/ProcesoTable';
//services
import { useNavigate } from 'react-router-dom';
import { RUTAS } from 'constants/routes';

export const ProcesoModule = (): ReactElement => {
    const navigate = useNavigate();
   
    const tableRef = useRef<ProcesoTableRefProps>(null);

    return (
        <>            
            <ProcesoTable 
                ref={tableRef}                 
                onDetalleClick={(idProceso) => navigate({
                    pathname: RUTAS.proceso_detalle.getPath({ id: idProceso})
                })}
            />
        </>
    );
};
