import React, { ReactElement,  useEffect,  useState } from 'react'
import { useNotify } from 'services/notify';
import { useIsMounted } from 'hooks/useIsMounted';
//@mui
import { Badge, Box, CircularProgress, IconButton, Typography, useTheme } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';
import PlaceIcon from '@mui/icons-material/Place';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { LoadingButton } from '@mui/lab';
import useMediaQuery from '@mui/material/useMediaQuery';

import GoogleMapFicha from './GoogleMapFicha';
import { DEFAULT_CENTER } from 'constants/enums';

import { FichaModel, FichaModuleService, MapPosition } from '../FichaModuleService';
import FichaUploadDialog from './FichaUploadDialog';

export type FichaDetalleTableModel = {
    id    : string;
    nombre: string;
    codigo: string;
    inicio: Date;
    final : Date;
};

export type FichaFormDataResponse = {
    estado       : boolean;
    ficha: FichaModel[];
};


const FichaClient = () : ReactElement =>  {
    const notify = useNotify();
    const isMounted = useIsMounted();

    const [mapCenter, setMapCenter] = useState(DEFAULT_CENTER);

    const [takePhoto, setTakePhoto] = useState<boolean>(false);
    const [fichaDetalle, setFichaDetalle] = useState<FichaFormDataResponse>();

    const IS_EXIST_FICHA = fichaDetalle && (fichaDetalle?.ficha.length == 0 );

    const [loading, setLoading] = useState<boolean>(false);
    const [loadingVerificar, setLoadingVerificar] = useState(false);
    const [loadingPosicion, setLoadingPosicion] = useState(false);

    const [verificarDisabled, setVerificarDisabled] = useState(true);
    const [posicionDisabled, setPosicionDisabled] = useState(true);

    const [openDialogFichaUpload, setOpenDialogFichaUpload] = useState(false);

    const [info, setInfo] = useState("Posicion Inicial");

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    const clientHeight = matches ? window.outerHeight : window.innerHeight;

    const mapContainerStyle = {
        height: `${clientHeight/2+50}px`,
        width: `100%`,
        margin: 'auto'
    };



    const handleCenterPosicion = async () => {
        setLoadingVerificar(true);
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude  = position.coords.latitude;
            const longitude = position.coords.longitude;
            //const fecha     = new Date(position.timestamp);
            const newCenter = { lat: latitude, lng: longitude };
            setMapCenter(newCenter);
            notify.info("Verificacion de posicion correcta.");
            setLoadingVerificar(false);
            setVerificarDisabled(true);
            setTakePhoto(true);
            notify.warning('Saque se la foto con el icono azul');
            setInfo("Tu estas aqui");
        }, function (e) {
            console.log(e);
        }, {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true,
        });
    };

    const handleCenterPosicionInicial = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const fecha = new Date(position.timestamp);
            const newCenter = { lat: latitude, lng: longitude };
            setMapCenter(newCenter);
        }, function (e) {
            console.log(e);
        }, {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true,
        });
    };

    const handleMarcarPosicion = async () => {
        setLoadingPosicion(true);
        const avatar = localStorage.getItem("fileName") || "";
        const result: any = await FichaModuleService.setMarcado(mapCenter as MapPosition, avatar);
        if (!result.success) return notify.error(result.msg);
        if (result.data) {
            const fecha = result.data.fecha || "";
            setInfo("Tu marcaste exitosamente aqui, revise sus notificaciones.");
        };
        setLoadingPosicion(false);
        setVerificarDisabled(true);
        setPosicionDisabled(true);
        notify.success('Marcado exitosamente');
    };



    useEffect(() => {
        const fetchData = async () => {
            if (!isMounted()) return;
            setLoading(true);
            const resultFicha = await FichaModuleService.getFichaCliente();
            if (!resultFicha || !resultFicha.success) return;
            const fichaResult: FichaFormDataResponse | null = resultFicha.data || null;
            setLoading(false);
            if (isMounted() && fichaResult) {
                setFichaDetalle(fichaResult);
                setVerificarDisabled(!fichaResult.estado);
                setInfo(fichaResult.estado || IS_EXIST_FICHA?"Posicion Inicial":"Tu marcaste exitosamente aqui.");
            }
        };
        if (isMounted()) handleCenterPosicionInicial();
        fetchData();
    }, [loadingPosicion, IS_EXIST_FICHA]);

    const handleTakePhoto = () => {
        setTakePhoto(false);
        setOpenDialogFichaUpload(true);
    };

    const handleUploadClose = () => {
        setOpenDialogFichaUpload(!openDialogFichaUpload);
        setPosicionDisabled(false);
    };


    return (
        <Box>
            {openDialogFichaUpload && (
                <FichaUploadDialog
                    openDialog={openDialogFichaUpload}
                    handleDialogClose={handleUploadClose}
                />
            )}
            <Box display="flex">
                {loading && <CircularProgress size={18} color="inherit" />}
                {
                    !loading && fichaDetalle?.ficha.map((data: FichaModel, index) =>  {
                        const color = data.estado;
                        return (
                            <Typography key={index} variant="body2" style={{fontSize: '10px', display: 'flex', alignItems: 'center'}} color="text.primary" noWrap>
                                <FlagIcon color={color?'success':'disabled'} />
                                {data.nombre}
                            </Typography>
                        )
                    })
                }
                {  IS_EXIST_FICHA && 'No existe fichas a la fecha.' }
            </Box>
            <GoogleMapFicha mapCenter={mapCenter} info={info} mapContainerStyle={mapContainerStyle} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <Badge
                    badgeContent={1}
                    invisible={verificarDisabled}
                    color="error"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <LoadingButton
                        size="small"
                        color="primary"
                        onClick={handleCenterPosicion}
                        loading={loadingVerificar}
                        loadingPosition="start"
                        startIcon={<PlaceIcon />}
                        variant="contained"
                        sx={{ m: 1, lineHeight: 1.15, pr: 2 }}
                        disabled={verificarDisabled}
                    >
                        <span>Verificar<br /> Posicion</span>
                    </LoadingButton>
                </Badge>
                <Badge
                    badgeContent={2}
                    invisible={!takePhoto}
                    color="error"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    <IconButton size="large" onClick={handleTakePhoto} disabled={!takePhoto} sx={{ p: 0 , pb: 0.5}}>
                        <AddAPhotoIcon color={takePhoto?'info':'disabled' } sx={{fontSize: '48px'}} />
                    </IconButton>
                </Badge>
                <Badge
                    badgeContent={3}
                    invisible={posicionDisabled}
                    color="error"
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <LoadingButton
                        size="small"
                        color="warning"
                        onClick={handleMarcarPosicion}
                        loading={loadingPosicion}
                        loadingPosition="start"
                        startIcon={<PlaceIcon fontSize='large'/>}
                        variant="contained"
                        sx={{ m: 1, lineHeight: 1.15 }}
                        disabled={posicionDisabled}
                    >
                        <span>Marcar<br /> Asistencia</span>
                    </LoadingButton>
                </Badge>
            </Box>
            <Box mt={1}>
                <Typography variant="body2" style={{fontSize: '10px'}} color="text.tertiary">
                    <b>Nota: </b>Debe completar los tres (3) pasos para que su asistencia sea comtemplada en el registro.
                </Typography>
                <Typography variant="body2" style={{fontSize: '10px'}}>
                    <FlagIcon color='success' fontSize='small' /> Marcado con exito.
                    <FlagIcon color='disabled' fontSize='small' /> Falta marcar.
                </Typography>
            </Box>
        </Box>
    )
}

export default FichaClient
