import React, { ReactElement, useEffect, useState } from 'react';

// material-ui
import { useTheme, Box } from '@mui/material';

// types
import { DataTableHeaderProps, Filters } from './Types';
import { SelectFilter } from './filters/SelectFilter';
import { TextFilter } from './filters/TextFilter';
import { DateFilter } from './filters/DateFilter';

export const DataTableFiltersV3 = (props: DataTableHeaderProps): ReactElement => {
    const { headers, updateParams, filterOpen, onFilterChange } = props;
    const theme = useTheme();
    const [stateFilters, setStateFilters] = useState<Filters>(updateParams.filters || {});

    const handleChangeFilterSelect = (name: string, value: string | number) => {
        const newFilters = { ...stateFilters, [name]: value };
        Object.keys(newFilters).forEach((key) => newFilters[key] === '' && delete newFilters[key]);
        setStateFilters(newFilters);
        onFilterChange(newFilters);
    };

    useEffect(() => {
        setStateFilters(updateParams.filters || {});
    }, [updateParams.filters]);

    const renderFilterRow = (): ReactElement => {
        return (
            <Box
                sx={[
                    {
                        display: 'flex',
                        justifyContent: 'left',
                        height: theme.spacing(0),
                        alignItems: 'center',
                        overflow: 'hidden',
                        transition: 'height .5s, marginTop .5s',
                        borderRadius: theme.spacing(1.2),
                        background: '#eee'
                    },
                    filterOpen && {
                        overflowX: 'auto',
                        height: theme.spacing(7)
                    }
                ]}
            >
                {headers.map((column, columnIndex) => {
                    if (column.filter && column.filter.type === 'text') {
                        return (
                            <Box key={columnIndex} sx={{ minWidth: '120px', margin: theme.spacing(0, 1) }}>
                                <TextFilter
                                    name={column.id as string}
                                    label={column.label}
                                    onChange={handleChangeFilterSelect}
                                    value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                                />
                            </Box>
                        );
                    }
                    if (column.filter && column.filter.type === 'select') {
                        return (
                            <Box key={columnIndex} sx={{ minWidth: '120px', margin: theme.spacing(0, 2) }}>
                                <SelectFilter
                                    name={column.id as string}
                                    label={column.label}
                                    options={column.filter.options || []}
                                    onChange={handleChangeFilterSelect}
                                    value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                                />
                            </Box>
                        );
                    }
                    if (column.filter && column.filter.type === 'date') {
                        return (
                            <Box key={columnIndex} sx={{ minWidth: '120px', margin: theme.spacing(0, 1) }}>
                                <DateFilter
                                    name={column.id as string}
                                    label={column.label}
                                    onChange={handleChangeFilterSelect}
                                    value={stateFilters ? (stateFilters[String(column.id)] as string) : ''}
                                />
                            </Box>
                        );
                    }
                    return <Box key={columnIndex}></Box>;
                })}
            </Box>
        );
    };

    return renderFilterRow();
};
