import React, { ReactElement, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Paper } from '@mui/material';
// components
import { SigapoTable, SigapoTableRefProps } from './components/SigapoTable';
import { RUTAS } from 'constants/routes';
//services
import { useSession } from 'hooks/session';
import { SectionItem, SectionNav, SectionNavRefProps } from 'components/core/SectionNav/SectionNav';
import { ENUM_IS_JEFE } from 'constants/enums';
import { SigapoTableRefPropsV2, SigapoTableV2 } from './components/SigapoTableV2';


export const SigapoModule = (): ReactElement => {
    const authUser = useSession();
    const navRef = useRef<SectionNavRefProps>(null);

    const navigate = useNavigate();

    const es_super_administrador = authUser.roles === ENUM_IS_JEFE;

    const tableRef = useRef<SigapoTableRefProps>(null);
    const tableRefV2 = useRef<SigapoTableRefPropsV2>(null);

    const sections: SectionItem[] = [];

    sections.push({
        id: '1',
        label: 'General',
        content: <SigapoTableV2  ref={tableRefV2} />
    });

    es_super_administrador && sections.push({
        id: '2',
        label: 'Propias',
        content: <SigapoTable
                        ref={tableRef}
                        onDetalleClick={(idSigapoDetalle) => navigate({
                            pathname: RUTAS.sigapo_detalle.getPath({ id: idSigapoDetalle})
                        })}
                    />
    });

    return (
        <>

            <Paper>
                <SectionNav ref={navRef} sections={sections} />
            </Paper>
        </>
    );
};
