import React, { ReactElement, useState } from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import { NotificacionTableModel } from './NotificacionTable';
import { useNotify } from 'services/notify';
import { DEFAULT_NO_VISTO_COLOR, NOTIFICATION_COLORS } from 'constants/colors';
import LabelImportantIcon from '@mui/icons-material/LabelImportant';



type Props = {
    data: NotificacionTableModel;
    onNotificationClick: (data: NotificacionTableModel) => void;
};

export const NotificacionTableItem = ({ data, onNotificationClick }: Props): ReactElement => {
    const notify = useNotify();

    function getColor(categoria: string): string {
        const colorItem = NOTIFICATION_COLORS.find((item) => item.categoria === categoria);
        return colorItem ? colorItem.value : '';
    }

    function renderCategoria(data: NotificacionTableModel): ReactElement {
        return <LabelImportantIcon sx={{ color: getColor(data.categoria) }} />;
    }

    return (
        <Card elevation={3} sx={{ background: !data.visto ? DEFAULT_NO_VISTO_COLOR : 'unset', mb: 1 }}>
            <CardContent>
                <Box onClick={() => onNotificationClick(data)}>
                    <Box display="flex">
                        {renderCategoria(data)}
                        <Typography variant="body1" display="block">
                            <strong>{data.asunto}</strong>
                        </Typography>
                    </Box>
                    <Box px={0.5}>
                        <Typography variant="caption" display="block">
                            <strong>Remitente: </strong> {data.remitente}
                        </Typography>
                        <Typography variant="caption" display="block">
                            <strong>Enviado el: </strong> {data.fecha}
                        </Typography>
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};
