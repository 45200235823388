import React, { ReactElement, useRef, useState } from 'react';
// @mui
import { Paper, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
// components
import FichaClient from './component/FichaClient';

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 0)
}));

export const FichaModule = (): ReactElement => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isMobile2 = /Mobi|Android/i.test(navigator.userAgent);

    if(!isMobile && !isMobile2) return (
           <Container>
                <ContentStyle sx={{ textAlign: 'center', alignItems: 'center' }}>
                    <Typography variant="h3" paragraph>
                    Lo sentimos, página no puede ser visualizada en computadora.
                    </Typography>

                    <Typography sx={{ color: 'text.secondary' }}>Lo sentimos, asegúrese de usar el modulo FICHA en un dispositivo movil o tabled.</Typography>

                    <Box component="img" src="/static/images/maskable_icon_x512.png" sx={{ height: 260, mx: 'auto', my: { xs: 2, sm: 5 } }} />

                </ContentStyle>
            </Container>
    );
    return (<FichaClient />);
};
