// component
import Iconify from 'components/Iconify';
// ----------------------------------------------------------------------
export type NavConfigType = {
    id      : number;
    title   : string;
    path    : string;
    icon    : JSX.Element;
    children: React.ReactNode[];
    info    : string;
};

//Las opciones deben ser asignadas manualmente hasta encontrar un mecanismo directo en la que se guarde la informacion
interface Options {
    dashboard: string;
    rrhh     : string;
    contra   : string;
    bienes   : string;
    gabi     : string;
}

const options: Options = {
    dashboard: 'Principal',
    rrhh     : 'Recursos Humanos',
    contra   : 'Contrataciones',
    bienes   : 'Bienes y Servicios',
    gabi     : 'Correspondencia'
};

export type menuType = {
    [key: string]: string;
};

const getIcon = (name: string) => <Iconify icon={name} width={22} height={22} />;

const navConfig = (menu: any): NavConfigType[] => {
    const sections = Array.from(new Set(menu.map((route: any) => route.path.split('/')[1])));

    const subRoutes = (routes: any, section: any) => routes
                                                    .filter((route: any) => route.path.split('/')[1] === section)
                                                    .map((n: any) => ({
                                                        ...n,
                                                        icon: getIcon(n.icon)
                                                    }));

    const menu_aux = sections.map((item: any, index) => {
                                const sub_menu = subRoutes(menu, item);
                                const i: keyof Options = item;
                                const title = options[i];
                                const filteredChildren = sub_menu.filter((m: any) => m.is_client === true || m.is_client == null);

                                if (filteredChildren.length > 0) {
                                    return {
                                        id      : index,
                                        title   : title,
                                        path    : '',
                                        icon    : getIcon('la:braille'),
                                        info    : item,
                                        children: filteredChildren
                                    };
                                }

                                return null;
                            }).filter((item): item is NavConfigType => item !== null);

    return menu_aux.sort((a:any, b: any) => a.id > b.id ? 1 : -1);
};

export default navConfig;
