import React, { ReactElement } from 'react';

import { FormControl, useTheme, InputLabel, Select, MenuItem, Divider } from '@mui/material';

import { FilterOption } from '../Types';

type Props = {
    name: string;
    label: string;
    value?: string;
    options: FilterOption[];
    onChange: (name: string, value: string | number) => void;
};

export const SelectFilter = ({ name, label, value, options, onChange }: Props): ReactElement => {
    const theme = useTheme();
    return (
        <FormControl
            sx={{
                width: '100%',
                margin: theme.spacing(0, 1),
                bgcolor: theme.palette.common.white
                //overflow: 'hidden'
            }}
        >
            <InputLabel id={`${name}Label`}>{label}</InputLabel>
            <Select labelId={`${name}Label`} name={name} value={value || ''} size="small" onChange={(evt) => onChange(evt.target.name as string, evt.target.value as string | number)}>
                <MenuItem key="0" value="" sx={{ fontSize: '0.725rem' }}>
                    TODOS
                </MenuItem>
                <Divider />
                {options.map((opt, optIndex) => {
                    return (
                        <MenuItem key={optIndex} value={opt.value} sx={{ fontSize: '0.725rem' }} dense>
                            {opt.label}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
