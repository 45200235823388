import React, { ReactElement } from 'react';
// @mui
import { alpha, PaletteColor, styled } from '@mui/material/styles';
import { Button, Card, Link, Typography } from '@mui/material';
// utils
import { fShortenNumber } from 'utils/formatNumber';
// components
import Iconify from 'components/Iconify';
import { SxProps, Theme } from '@mui/material/styles';
// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
    margin: 'auto',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    justifyContent: 'center',
    marginBottom: theme.spacing(1.5)
}));

// ----------------------------------------------------------------------
type Props = {
    color?: string;
    icon?: string;
    title: string;
    link?: string;
    onClick?: () => void;
    sx?: SxProps<Theme>;
};

const renderViewButton = (props: Props): ReactElement => {
    const { title, link, icon, onClick, color = 'primary', sx, ...other } = props;
    return (
            <Card
                sx={{
                    py: 4,
                    px: 2,
                    textAlign: 'center',
                    height: 150,
                    width: 150,
                    color: (theme: any) => theme.palette[color].darker,
                    bgcolor: (theme: any) => theme.palette[color].lighter,
                    ...sx
                }}
                onClick={onClick}
                {...other}
            >
                <IconWrapperStyle
                    sx={{
                        color: (theme: any) => theme.palette[color].dark || theme.palette['grey'][900],
                        backgroundImage: (theme: any) => `linear-gradient(135deg, ${alpha(theme.palette[color].dark || theme.palette['grey'][900], 0)} 0%, ${alpha(theme.palette[color].dark || theme.palette['grey'][900], 0.24)} 100%)`
                    }}
                >
                    <Iconify icon={icon} width={24} height={24} />
                </IconWrapperStyle>
                <Typography variant="subtitle2" sx={{ opacity: 0.72 }}>
                    {title}
                </Typography>
            </Card>
        );
    };



export default function ButtonLink(props: Props): ReactElement {
    return (
        props.link?<Link href={props.link} underline="none">
                { renderViewButton(props) }
            </Link>
            : renderViewButton(props)
    );
}
