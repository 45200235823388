import React, { useState, ReactElement, useEffect, useImperativeHandle, forwardRef, useRef } from 'react';
//components
import { DataTable, TableHeader, UpdateParams, HeaderFilter, ActionColumn, OnUpdateOptions, DataTableRefProps, StatusColumn } from 'components/core/DataTable';
//@mui

//services
import { QueryParams } from 'services/base/Types';
import { useNotify } from 'services/notify';
//model
import { FichaModel, FichaModuleService, MapPosition } from '../FichaModuleService';
//hooks
import { useIsMounted } from 'hooks/useIsMounted';
import { FichaTableItem } from './FichaTableItem';
import { ENUM_ESTADOS } from 'constants/enums';
import { ESTADO_B } from 'constants/colors';
import { Box, IconButton, Typography } from '@mui/material';
import FlagIcon from '@mui/icons-material/Flag';


export type FichaDetalleTableModel = {
    id         : string;
    nombre     : string;
    posicion   : string;
    lugar      : string;
    num_item   : string;
    ci         : string;
    num_ficha  : string;
    fecha_ficha: string;
    estado     : string;
    avatar     : string;
    // para las columnas especiales
    actions: unknown;
};

export type FichaTableRefProps = {
    refresh: (updateParams?: UpdateParams<FichaDetalleTableModel>) => void;
    getQueryParams: () => QueryParams;
};

export type ReportFilters = {
    [key: string]: string | number | boolean | (string | number | boolean)[];
};

const tableParamsInitialize: UpdateParams<FichaDetalleTableModel> = {
    rows: [],
    count: 0,
    rowsPerPage: 5,
    page: 1
};


const statusFilter: HeaderFilter = { type: 'select', options: ENUM_ESTADOS };
const ciFilter: HeaderFilter = { type: 'text' };
const itemFilter: HeaderFilter = { type: 'text' };

type Props = {
    onViewClick: (posicion: MapPosition, info: string) => Promise<void>;
    ficha: FichaModel[];
};

export const FichaTableGeneralComponent = (props: Props, ref: React.Ref<FichaTableRefProps>): ReactElement => {
    const { onViewClick, ficha } = props;
    const isMounted = useIsMounted();
    const [indexClick, setIndexClick] = useState(-1);

    const [tableParams, setTableParams] = useState<UpdateParams<FichaDetalleTableModel>>(tableParamsInitialize);
    const tableRef = useRef<DataTableRefProps>(null);

    let tableHeaders: TableHeader<FichaDetalleTableModel>[] = [
        { id: 'actions', label: 'Acciones', sort: false, render: renderColumnActions },
        { id: 'nombre', label: 'Nombre Completo', align: 'left', sort: false },
        { id: 'num_item', label: 'Nro Item', align: 'left', filter: itemFilter },
        { id: 'ci', label: 'CI', align: 'left', filter: ciFilter },
        { id: 'num_ficha', label: 'Num Ficha', align: 'left'},
        { id: 'fecha_ficha', label: 'Fecha Ficha', align: 'left' },
        { id: 'estado', label: 'Estado', filter: statusFilter, render: renderColumnStatus }
    ];

    const handleUpdateTable = (params: UpdateParams<FichaDetalleTableModel>, opt: OnUpdateOptions) => {
        opt.setLoading(true);
        if (!isMounted()) return;
        FichaModuleService.getTableFicha(params as QueryParams).then((result) => {
            opt.setLoading(false);
            if (!result.success) return;
            const newTableParams: UpdateParams<FichaDetalleTableModel> = {
                ...params,
                rows: result.rows || [],
                count: result.count || 0
            };
            if (isMounted()) {
                setTableParams(newTableParams);
            }
        });
    };

    function renderColumnStatus(data: FichaDetalleTableModel): ReactElement {
        const color = 'white';
        const background = ESTADO_B[data.estado];
        return <StatusColumn status={data.estado} color={color} background={background} />;
    }

    const handleCenterPosicion = () => {
        navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            const fecha = new Date(position.timestamp);
            const newCenter = { lat: latitude, lng: longitude };
        }, function (e) {
            console.log(e);
        }, {
            maximumAge: 60000,
            timeout: 5000,
            enableHighAccuracy: true,
        });
    };

    useEffect(() => {
        if (isMounted()) handleCenterPosicion();
        tableRef.current?.refresh();
    }, [tableRef]);

    const tableRefHandler = () => ({
        refresh: () => {
            const newTableParams = {
                ...tableParams,
            };
            tableRef.current?.refresh(newTableParams);
        },
        getQueryParams: () => {
            const newParams = {
                ...tableParams,
                filters: {
                    ...tableParams.filters
                },
            };
            return newParams;
        }
    });

    useImperativeHandle(ref, tableRefHandler, [tableParams]);

    function renderMobileComponent(data: FichaDetalleTableModel) {
        const center= data.posicion && JSON.parse(data?.posicion)  || null;
        const nombre = data?.nombre +" | " +  data?.fecha_ficha || "";
        if(!center) return <></>;
        return (
            <FichaTableItem
                data={data}
                onViewClick={() => onViewClick(center, nombre)}
                refresh={() => tableRef.current?.refresh()}
            />
        );
    }

    function renderColumnActions(data: FichaDetalleTableModel): ReactElement {
        const center= JSON.parse(data?.posicion)  || null;
        const nombre = data?.nombre + ", Marco aqui" || "";
        if(!center) return <></>;
        return (
            <ActionColumn
                onViewClick={() => onViewClick(center as unknown as MapPosition, nombre)}
            />
        );
    }

    const handleClickRefresh = (id: string, index: number) => {
        setIndexClick(index);
        tableParams.filters = { fichaId: id };
        tableRef.current?.refresh(tableParams);
    };

    return (
        <Box>
            <Box display="flex" mb={1} justifyContent="space-between">
                <Box>
                {
                    ficha.map((data: FichaModel, index) =>  {
                        return (
                            <IconButton
                                key={index}
                                aria-label="flag"
                                onClick={() => handleClickRefresh(data.id, index)}
                            >
                                <Typography key={index} variant="body2" style={{fontSize: '10px', display: 'flex', alignItems: 'center'}} color="text.primary" noWrap>
                                    <FlagIcon color={index===indexClick?'success':'disabled'} />
                                    {data.nombre}
                                </Typography>
                            </IconButton>
                        )
                    })
                }
                </Box>
            </Box>
            <DataTable
                ref={tableRef}
                headers={tableHeaders}
                updateParams={tableParams}
                onUpdate={handleUpdateTable}
                mobileComponent={renderMobileComponent}
                vScroll
            />
        </Box>
    );
};

export const FichaTableGeneral = forwardRef(FichaTableGeneralComponent);
