import React, { ReactElement, useState } from 'react';
import { Box, Card, CardActions, CardContent, Chip, IconButton, Typography } from '@mui/material';
import { DeclaracionTableModel } from './DeclaracionTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from 'components/core/ConfirmDialog';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { FileItem } from 'components/core/FormDialog';
import { truncateText } from 'utils/formatText';
import { STORAGE_URL } from 'config/app-config';


type Props = {
    data: DeclaracionTableModel;
    refresh: () => void;
    onViewClick?: () => void;
    onEditClick?: () => void;
    onDeleteClick?: () => void;
};

export const DeclaracionTableItem = ({ data, refresh, onEditClick, onViewClick, onDeleteClick }: Props): ReactElement => {
    const new_adjuntos = data.adjuntos as string;
    const renderEditButton = () => {
        return (
            <IconButton color="primary" component="span" size="small" onClick={() => (onEditClick ? onEditClick() : false)}>
                <EditIcon />
            </IconButton>
        );
    };

    const [open, setOpen] = useState<boolean>(false);

    const renderDeleteButton = () => {
        return (
            <>
                <ConfirmDialog
                    title={'Confirmar'}
                    message={
                        <div>
                            <div>¿Quiere eliminar el registro?</div>
                            <br />
                            <div>
                                <strong>Tipo: </strong> {data.nombre}
                            </div>
                        </div>
                    }
                    open={open}
                    onAccept={async () => {
                        setOpen(false);
                        if (onDeleteClick) return onDeleteClick();
                    }}
                    onCancel={() => setOpen(false)}
                />
                <IconButton color="primary" component="span" size="small" onClick={() => setOpen(true)}>
                    <DeleteIcon />
                </IconButton>
            </>
        );
    };

    return (
        <Card variant="outlined" sx={{ mb: 1 }}>
            <CardContent sx={{ p: 1, '&:last-child': { pb: 0 } }}>
                <Box>
                    <Typography variant="body2" display="block" sx={{ lineHeight: 1, textAlign: 'justify', mb: 1 }}>
                        <b>Nombre:&nbsp;</b>{data.nombre}
                    </Typography>
                    <Typography variant="body2" display="block" sx={{ lineHeight: 1 }}>
                        <b>N° Declaracion:&nbsp;</b>{data.numero_declaracion}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mt: 1 }}>
                        <b>Fecha Declaracion:&nbsp;</b>{data?.fecha_declaracion}
                    </Typography>
                    <Typography variant="caption" display="block" sx={{ lineHeight: 1, mt: 1 }}>
                        <b>Fecha Presentacion:&nbsp;</b>{data?.fecha_presentacion}
                    </Typography>
                    { new_adjuntos && new_adjuntos.length > 0 && new_adjuntos !== '[]' && (
                        <Box display="flex" alignItems="left" flexDirection="column" mt={3}>
                            <Typography variant="caption" sx={{ alignContent: 'center'}}>                                
                                Descargar el adjunto:{' '}
                            </Typography>
                            <div>
                                {JSON.parse(new_adjuntos).map((item: FileItem) => {
                                    return (
                                        <Chip
                                            key={item.id}
                                            size="small"
                                            icon={<AttachFileIcon />}
                                            label={truncateText(item.fileName.replace(`${item.id}-`, ''))}
                                            component="a"
                                            onClick={() => {
                                                window.open(`${STORAGE_URL}/${item.fileName}`);
                                            }}
                                            sx={{ maxWidth: 280 }}
                                            variant="outlined"
                                        />
                                    );
                                })}
                            </div>
                        </Box>
                    )}
                </Box>
            </CardContent>
            <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                {renderEditButton()}
                {renderDeleteButton()}
            </CardActions>
        </Card>
    );
};
