// Para la interacción con el servicio (backend). colocar 'http://localhost:8080/api' para entornos de desarrollo.
export const API_URL = 'https://api.oruro.gob.bo/api';
//export const API_URL = 'http://localhost:8080/api';

// Para la autenticación con Firebase
// 1ro - Asegurarse de crear un Realtime Database desde:
// Consola de Firebase > Realtime Database > Crear una base de datos (https://console.firebase.google.com/project)
// 2do - Obtener los parámetros de configuración desde:
// Consola de Firebase > Configuración > General > Agregar una app (Aplicación web) (https://console.firebase.google.com/project)
export const firebaseConfig = {
    apiKey: "AIzaSyBzOz8pazf1JLWexdp--HZy0VXtWXmVNYM",
    authDomain: "oruro-app.firebaseapp.com",
    projectId: "oruro-app",
    storageBucket: "oruro-app.appspot.com",
    messagingSenderId: "402524627282",
    databaseURL: "https://oruro-app-default-rtdb.firebaseio.com",
    appId: "1:402524627282:web:f893d5625de87f1bb41a8b"
};

// Para el envío de notificaciones push
// Consola de Firebase > Configuración > Cloud Messaging > Certificados push web > Generate key pair (https://console.firebase.google.com/project)
export const PUBLIC_VAPID_KEY = 'BDWjgSzEE0yM9O2m4PVniIbgtiZjnDbiU6FuCtT-sPVgIckNsOVN4zhEDuP5M5oDKk8v-QDzIgzVR4NKnEDWmP8';

// Para acceder a los archivos almacenados en Google Cloud Storage (la misma que se creó para el server)
// Consola de Firebase > Storage > Comenzar (https://console.firebase.google.com/project)
//      - Paso 1 Utilizar las reglas de seguridad por defecto
//      - Paso 2 Seleccionar la ubicación geográfica deonde se almacenarán los datos
//
//      gs://escuelas-manqa-localhost.appspot.com
//
// NOTA.- Al hacer esto se creará un bucket (escuelas-manqa-localhost.appspot.com)
//        en Google Cloud Storage (https://console.cloud.google.com/storage/browser)
//
// URL pública = https://storage.googleapis.com
// URL privada = https://storage.cloud.google.com
export const BUCKET_NAME = 'oruro-app.appspot.com';
export const STORAGE_URL = `https://storage.googleapis.com/${BUCKET_NAME}`;

// API KEY obtener desde: https://www.tiny.cloud
export const EDITOR_API_KEY = '56lz5udzwgcaeg1opra624rjk1abrnklajycopdqsq5lpors';

// Para el diálogo Acerca de
export const SUPPORT_EMAIL = 'soporte@oruro.gob.bo';
export const WEB_GADOR_URL = 'https://oruro.gob.bo';
export const WEB_CLIENT_URL = 'https://pagador.oruro.gob.bo';
export const WEB_TITLE_APP = ".:: Plataforma de Atención - PAGADOR ::.";
// URL para el envío de logs. Colocar 'http://localhost:8080/api/help/log' para entornos de desarrollo
export const LOG_URL = 'http://localhost:8080/api/help/log';
// Para mostrar el mapa en los reportes
//
//      - Paso 1 Habilitar la API de Google Maps desde https://console.cloud.google.com/apis/library
//
//             Buscar: Maps JavaScript API y hacer click en habilitar.
//
//      - Paso 2 Crear una nueva Clave de API desde https://console.cloud.google.com/apis/credentials
//             Google Cloud Platform > API y servicios > Credenciales > Crear credenciales (Clave de API)
//
//      - Paso 3 Seleccionar la opción RESTRINGIR CLAVE
//
//             Nombre                        : Clave de API para Google Maps
//             Restricciones de aplicaciones : ninguna
//             Restricciones de API          : Restringir clave (Maps JavaScript API)
//
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBzOz8pazf1JLWexdp--HZy0VXtWXmVNYM';
//-17.969624502296277, -67.11449045679467 plaza 10 de febrero
