import React, { useEffect, useState } from 'react';
// @mui
import { Grid, Container, Typography, Box } from '@mui/material';
// components
import Page from '../components/Page';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import { useSession } from 'hooks/session';
import useLocalStorage from 'hooks/localstorage/useLocalStorage';
// hooks
import useResponsive from 'hooks/useResponsive';
import * as routes from 'constants/routes';
import ButtonLink from 'components/ButtonLink';
// ----------------------------------------------------------------------
const FORMAT = 'EEEE, dd MMMM yyyy hh:mm a';

export default function DashboardApp() {
    
    const authUser = useSession();
    const smUp = useResponsive('up', 'sm');

    const generoMasculino = authUser.genero === 'MASCULINO';
    const bienvenidoText = generoMasculino ? 'Bienvenido' : 'Bienvenida';
    const fechaActual = format(new Date(), FORMAT,{ locale: es }).toString();

    const [dashboardGrid] = useLocalStorage<any>('dashboardMenu', []);
    const rutasAuth = dashboardGrid as Array<any>;
    function GridComponent() {
        return (<React.Fragment>    
                {   
                    rutasAuth.filter((r) => (r.name!=='dashboard' && r.is_client==true) || (r.is_client==null && r.name!=='dashboard')).map((item: any,index) => (
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                    <Box>
                                        <ButtonLink 
                                            title={item.title} 
                                            link={item.path} 
                                            color={item.color}
                                            icon={item.icon} 
                                        />
                                    </Box>                       
                            </Grid>
                        )
                    )
                }
        </React.Fragment>);
    }

    return (
        <Page title="Panel">
            <Container maxWidth="xl">
                <Box mb={5} sx={{
                                ...(smUp && { display: 'flex', justifyContent:'space-between' })
                                }}
                >
                    <Typography variant="h4">Hola, {bienvenidoText}</Typography>
                    <Typography variant="caption">{fechaActual}</Typography>
                </Box>  
                <Grid container spacing={3} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <GridComponent />
                </Grid>
            </Container>
        </Page>
    );
}
