import React from 'react';

// @mui
import { Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import { SigapoModule } from 'modules/gabi/sigapo';

// ----------------------------------------------------------------------

export default function Sigapo() {
    return (
        <Page title="Sigapo">
            <Container maxWidth="xl">
                <Typography variant="h4">Sigapo</Typography>
                <SigapoModule />
            </Container>
        </Page>
    );
}
