import React, { useEffect, useState } from 'react';

// @mui
import { Box, IconButton, Container, Typography } from '@mui/material';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import NoPhotographyIcon from '@mui/icons-material/NoPhotography';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocationOffIcon from '@mui/icons-material/LocationOff';
// components
import Page2 from '../components/Page2';
import { FichaModule } from 'modules/rrhh/ficha';
import { useIsMounted } from 'hooks/useIsMounted';
// ----------------------------------------------------------------------

export default function Ficha() {

    const isMounted = useIsMounted();
    const [permissions, setPermissions] = useState<boolean[]>([]);

    const checkPermissions = async (): Promise<boolean[]> => {
        try {
            // Verificar permisos de la cámara
            const cameraPermissionStatus = await navigator.permissions.query({ name: 'camera' as PermissionName });
            const cameraPermission = cameraPermissionStatus.state === 'granted';

            // Verificar permisos de geolocalización
            const locationPermissionStatus = await navigator.permissions.query({ name: 'geolocation' as PermissionName });
            const locationPermission = locationPermissionStatus.state === 'granted';

            // Verificar permisos de notificaciones
            const notificationPermissionStatus = await navigator.permissions.query({ name: 'notifications' as PermissionName });
            const notificationPermission = notificationPermissionStatus.state === 'granted';

            return [cameraPermission, locationPermission, notificationPermission];
        } catch (error) {
            console.error('Error al verificar permisos:', error);
            return [false, false, false];
        }
    };

    const requestGeolocationPermission = () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            console.log('Permiso de geolocalización concedido:', position);
          },
          (error) => {
            console.error('Error al solicitar permisos de geolocalización:', error);
          }
        );
    };

    const requestCameraPermission = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });

          // La cámara está habilitada, puedes hacer algo con el stream, por ejemplo, mostrarlo en un elemento de video
          const videoElement = document.getElementById('video') as HTMLVideoElement;
          if (videoElement) {
            videoElement.srcObject = stream;
          }
        } catch (error) {
          console.error('Error al solicitar permisos de cámara:', error);
        }
    };

    const requestNotificationPermission = async () => {
        try {
          const permissionStatus = await Notification.requestPermission();
          console.log('Permiso de notificación:', permissionStatus);
        } catch (error) {
          console.error('Error al solicitar permisos de notificación:', error);
        }
    };

    useEffect(() => {
        const fetchPermissions = async () => {
            const result = await checkPermissions();
            setPermissions(result);
        };
        if (isMounted()){
            fetchPermissions();
        }
    }, []);

    return (
        <Page2 title="Fichas">
            <Container maxWidth="xl">
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant="h4">Fichas</Typography>
                    <Typography variant="caption" sx={{ display: 'flex', alignItems: 'center' }}>
                        Permisos habilitados:&nbsp;
                        {
                            permissions[0] ?
                            <PhotoCameraIcon fontSize='small' color='primary' />
                            :<IconButton size="small" color="success" onClick={requestCameraPermission}>
                                <NoPhotographyIcon fontSize='small' color='error' />
                            </IconButton>
                        }
                        {
                            permissions[1] ?
                            <LocationOnIcon fontSize='small' color='primary' />
                            :<IconButton size="small" color="success" onClick={requestGeolocationPermission}>
                                <LocationOffIcon fontSize='small' color='error' />
                            </IconButton>
                        }
                        {
                            permissions[2] ?
                            <NotificationsIcon fontSize='small' color='primary' />
                            :<IconButton size="small" color="success" onClick={requestNotificationPermission}>
                                <NotificationsOffIcon fontSize='small' color='error' />
                            </IconButton>
                        }
                    </Typography>
                </Box>
                <FichaModule />
            </Container>
        </Page2>
    );
}
