import React, { ReactElement } from 'react';

import { useTheme, Box } from '@mui/material';

type Props = {
    status: string;
    color?: string;
    background?: string;
};

export const StatusColumn = ({ status, color = '#000', background = '#fff' }: Props): ReactElement => {
    const theme = useTheme();
    return (
        <Box component="div" sx={{ color, background, fontSize: '0.725rem', py: 0.5, px: 0.5, borderRadius: '1.5rem', textAlign: 'center' }}>
            {status}
        </Box>
    );
};
