import { BaseService } from 'services/base/BaseService';
import { BaseResponse, QueryParams } from 'services/base/Types';
import { PermisoTableModel } from './components/PermisoPropiaTable';
import { PermisoFormModel } from './components/PermisoFormDialog';

const getTablePermisoPropia = async (queryParams?: QueryParams): Promise<BaseResponse<PermisoTableModel>> => {
    return BaseService.findAll<PermisoTableModel>('/designacion_permiso/permiso_table_propia', queryParams);
};

const getTablePermisoSolicitada = async (queryParams?: QueryParams): Promise<BaseResponse<PermisoTableModel>> => {
    return BaseService.findAll<PermisoTableModel>('/designacion_permiso/permiso_table_solicitada', queryParams);
};

const createOrUpdatePermiso = async (data: PermisoFormModel): Promise<BaseResponse<unknown>> => {
    return BaseService.request('post', `/designacion_permiso/designacion_permiso_form`, data);
};

const getPermisoFormData = async (id: string): Promise<BaseResponse<PermisoFormModel>> => {
    return BaseService.request('get', `/designacion_permiso/designacion_permiso_form/${id}`);
};

const destroyPermiso = async (id: string): Promise<BaseResponse<unknown>> => {
    return BaseService.request('delete', `/designacion_permiso/designacion_permiso_item/${id}`);
};

const setActivePermiso = async (id_permiso: string, estado: boolean): Promise<BaseResponse<PermisoFormModel>> => {
    const data = { estado };
    return BaseService.request('post', `/designacion_permiso/designacion_permiso_table/${id_permiso}`, data);
};

const getAllArea = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/area/areas', queryParams);
};

const getAllUsuarios = async (queryParams?: QueryParams): Promise<BaseResponse<{ id: string; nombre: string; }>> => {
    return BaseService.findAll('/users/usuarios', queryParams);
};

export const PermisoModuleService = {
    getTablePermisoPropia,
    getTablePermisoSolicitada,
    createOrUpdatePermiso,
    getPermisoFormData,
    setActivePermiso,
    getAllArea,
    getAllUsuarios,
    destroyPermiso
};
