import React, { ReactElement, CSSProperties } from 'react';
// material-ui
import { TableCell, TableRow, TableBody, Collapse, Box, Typography, SxProps, useTheme, Theme } from '@mui/material';

// types
import { TableHeader, DataTableBodyProps, MyTableModel } from './Types';

import { DEFAULT_COLUMN_WIDTH, DEFAULT_COLUMN_MIN_WIDTH, DEFAULT_COLUMN_MAX_WIDTH, DEFAULT_COLUMN_ALIGN } from './DataTableHeader';

export const DataTableBody = ({ headers, updateParams, rowStyles, onRowClick, collapsableComponent, loading }: DataTableBodyProps): ReactElement => {
    const { rows } = updateParams;
    const theme = useTheme();

    const [collapseOpen, setCollapseOpen] = React.useState<{ [key: string]: boolean }>({});
    const [collapseComp, setCollapseComp] = React.useState<{ [key: string]: ReactElement }>({});

    const handleCollapse = (row: MyTableModel, rowId: string) => {
        if (!collapsableComponent) return;
        const newCollapseOpen = !collapseOpen[rowId];
        if (newCollapseOpen) setCollapseComp({ ...collapseComp, [rowId]: collapsableComponent(row) });
        setCollapseOpen({ ...collapseOpen, [rowId]: newCollapseOpen });
    };

    const renderValue = (row: MyTableModel, column: TableHeader<MyTableModel>): ReactElement => {
        const value = row[column.id];
        if (column.render) {
            const renderOptions = {
                handleClickCollapse: () => handleCollapse(row, `${row.id}`),
                collapseOpen: !collapseOpen[`${row.id}`]
            };
            return column.render(row, renderOptions);
        }
        if (typeof value === 'boolean') return <Box>{`${value}`}</Box>;
        if (typeof value === 'number') return <Box>{`${value}`}</Box>;
        if (typeof value === 'string') return <Box>{value}</Box>;
        return <Box />;
    };

    const buildCellStyle = (column: TableHeader<MyTableModel>): CSSProperties => {
        return {
            textAlign: column.align || DEFAULT_COLUMN_ALIGN,
            width: column.width || DEFAULT_COLUMN_WIDTH,
            minWidth: column.minWidth || (column.width ? column.width : DEFAULT_COLUMN_MIN_WIDTH),
            maxWidth: column.maxWidth || (column.width ? column.width : DEFAULT_COLUMN_MAX_WIDTH)
        };
    };

    const buildCellClassName = (column: TableHeader<MyTableModel>, rowId: string): SxProps<Theme> => {
        return [
            {
                padding: theme.spacing(0, 1.5),
                overflow: 'hidden',
                fontSize: '0.825rem',
                lineHeight: '1.2',
                [theme.breakpoints.down('sm')]: {
                    fontSize: '0.725rem'
                },
                '&:last-child': {
                    padding: theme.spacing(0, 2)
                }
            },
            column.onCellClick ? { cursor: 'pointer' } : {},
            column.truncate ? { whiteSpace: 'nowrap', textOverflow: 'ellipsis' } : {},
            typeof collapsableComponent !== 'undefined' && collapseOpen[rowId]
                ? {
                      borderTop: `solid #878787 1px`,
                      background: '#f2f2f2',
                      '&:first-child': {
                          borderLeft: `solid #878787 1px`
                      },
                      '&:last-child': {
                          borderRight: `solid #878787 1px`
                      }
                  }
                : {}
        ];
    };

    const renderTableCell = (row: MyTableModel, rowId: string, column: TableHeader<MyTableModel>, columnIndex: string): ReactElement => {
        return (
            <TableCell
                sx={buildCellClassName(column, rowId)}
                style={buildCellStyle(column)}
                key={columnIndex}
                onClick={() => {
                    if (column.onCellClick) column.onCellClick(row, column);
                }}
            >
                {renderValue(row, column)}
            </TableCell>
        );
    };

    const renderCollapsibleRow = (rowId: string): ReactElement => {
        return (
            <TableRow>
                <TableCell
                    sx={[
                        typeof collapsableComponent !== 'undefined' &&
                            collapseOpen[rowId] && {
                                borderBottom: `solid #878787 1px`,
                                borderLeft: `solid #878787 1px`,
                                borderRight: `solid #878787 1px`
                            },
                        { padding: 0, borderBottom: collapseOpen[rowId] ? '' : 'unset' }
                    ]}
                    colSpan={Array.from(headers).length}
                >
                    <Collapse in={collapseOpen[rowId]} timeout="auto" unmountOnExit>
                        {collapseComp && collapseComp[rowId]}
                    </Collapse>
                </TableCell>
            </TableRow>
        );
    };

    const renderEmptyTable = () => {
        return (
            <TableRow>
                <TableCell colSpan={9999} sx={{ height: theme.spacing(6) }}>
                    <Typography align="center">{loading ? 'Cargando...' : 'Sin registros'}</Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <TableBody>
            {rows.map((row, index) => {
                const rowId = `${row.id || index}`;
                return (
                    <React.Fragment key={`${rowId}_${index}`}>
                        <TableRow
                            sx={[rowStyles ? rowStyles(row) : {}, { height: theme.spacing(6) }, onRowClick ? { cursor: 'pointer' } : {}]}
                            hover
                            onClick={() => {
                                if (onRowClick) onRowClick(row);
                            }}
                        >
                            {headers.map((column, columnIndex) => renderTableCell(row, rowId, column, `${columnIndex}`))}
                        </TableRow>
                        {typeof collapsableComponent !== 'undefined' && renderCollapsibleRow(rowId)}
                    </React.Fragment>
                );
            })}
            {rows.length === 0 && renderEmptyTable()}
        </TableBody>
    );
};
